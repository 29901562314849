export const applicationViews = [
  { id: 'new_application', name: 'Choose product' },
  { id: 'upload_documents', name: 'Upload documents' },
  { id: 'offer_selection', name: 'Select an offer' },
  { id: 'kyc_documents', name: 'KYC Collect' },
  { id: 'banking_details', name: 'Banking details' },
  { id: 'contract_signing', name: 'Signing the contract' },
  { id: 'complete', name: 'Receive funds' },
] as const;

export const KYC_COLLECT = 'kyc_documents';
