import { styled } from '@mui/material';
import Button from '../Button/Button';

export const SelectedButton = styled(Button, { shouldForwardProp: (prop) => prop !== 'selected' })<{
  selected: boolean;
}>(({ theme, selected }) => ({
  backgroundColor: selected ? theme.palette.common.white : undefined,
  color: selected ? theme.palette.primary.main : undefined,

  '&:hover': {
    backgroundColor: selected ? theme.palette.common.white : undefined,
  },

  '& svg': {
    fill: theme.palette.primary.main,
  },
}));
