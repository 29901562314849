export const incorporationCountryOptions = [
  { name: 'UAE', value: 'AE' },
  // disabled until documents workflow are ready
  { name: 'South Africa', value: 'ZA' },
];

export const countryCurrencies = {
  AE: 'USD',
  ZA: 'ZAR',
};
