import { useEffect } from 'react';
import { FinancialPlatform } from 'components';
import { useDispatch, useSelector } from 'hooks';
import { selectedFinancialPaymentPlatformsSelector } from 'store/selectors/financialPlatformsSelector';
import {
  deleteFinancialPlatform,
  removeRequestedFinancialPlatformConnection,
} from 'store/thunks/financialPlatformSliceThunk';
import { companyDetailsSelector } from 'store/selectors/companyDetailsSelector';
import { appendCustomFinancialPlatform } from 'store/slices/financialPlatformsSlice';
import { FinancialPlatformConnectionType } from 'types';

export const PaymentPlatforms = () => {
  const dispatch = useDispatch();
  const company = useSelector(companyDetailsSelector);
  const { available, onDemand } = useSelector(selectedFinancialPaymentPlatformsSelector);

  const handleCancelRequestedConnection = async (platformName: string) => {
    await dispatch(
      removeRequestedFinancialPlatformConnection({
        platformName,
        connectionType: FinancialPlatformConnectionType.PAYMENT,
      }),
    );
  };

  const handleDisconnect = async (connectionId: string) => {
    await dispatch(deleteFinancialPlatform(connectionId));
  };

  useEffect(() => {
    if (!company?.requestedFinancialPlatforms) return;
    const paymentPlatformsArr = company?.requestedFinancialPlatforms.trim().split(';') ?? [];

    if (paymentPlatformsArr?.length > onDemand.length) {
      const onDemandTitles = onDemand.map((platform) => platform.platformName);

      paymentPlatformsArr.forEach((platform) => {
        if (!onDemandTitles.includes(platform)) {
          dispatch(appendCustomFinancialPlatform({ platformName: platform }));
        }
      });
    }
  }, [company?.requestedFinancialPlatforms]);

  return (
    <>
      {available.map((platform) => (
        <FinancialPlatform
          {...platform}
          key={platform.platformName}
          onDisconnect={handleDisconnect}
          onCancelRequestedConnection={handleCancelRequestedConnection}
        />
      ))}
      {onDemand.map((platform) => (
        <FinancialPlatform
          {...platform}
          key={platform.platformName}
          onDisconnect={handleDisconnect}
          onCancelRequestedConnection={handleCancelRequestedConnection}
        />
      ))}
    </>
  );
};
