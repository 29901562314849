import { forwardRef } from 'react';
import { Typography as MuiTypography, TypographyProps } from '@mui/material';

type Props = TypographyProps;

const Typography = forwardRef<HTMLSpanElement, Props>(({ children, ...props }, ref) => {
  return (
    <MuiTypography {...props} ref={ref}>
      {children}
    </MuiTypography>
  );
});

Typography.displayName = 'Typography';

export default Typography;
