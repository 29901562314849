import { useState, useRef } from 'react';
import { useNavigate } from 'react-router';
import { IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import styles from './Navbar.module.scss';
import { PopperMenu } from 'components';
import { useAuth } from 'hooks';
import { clsx } from 'clsx';
import useMediaQuery from '@mui/material/useMediaQuery';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';

const navbarMenuOps = [
  { id: 'account-info', name: 'Account ', icon: <PersonOutlineOutlinedIcon /> },
  { id: 'sign-out', name: 'Sign out', icon: <LogoutOutlinedIcon /> },
];

const Navbar = () => {
  const matches = useMediaQuery('(max-width:750px)');
  const navigate = useNavigate();
  const menuAnchor = useRef<HTMLButtonElement | null>(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const { signOut } = useAuth();

  const handleOpenMenu = () => {
    setMenuOpen(true);
  };

  const handleCloseMenu = () => {
    setMenuOpen(false);
  };

  const handleSelect = (id: string) => {
    switch (id) {
      case 'sign-out':
        signOut();
        handleCloseMenu();
        break;
      case 'account-info':
        navigate('/account');
        handleCloseMenu();
        break;
      default:
        break;
    }
  };

  return (
    <div
      className={clsx(styles.wrapper, {
        [styles.mobile]: matches,
      })}
    >
      <Box display='flex' alignItems='center' gap='1.5rem'>
        {matches && (
          <IconButton>
            <MenuIcon className={styles.menuIcon} />
          </IconButton>
        )}
        <Link to='/'>
          <img src={'/logo.svg'} alt='logo' style={{ height: '1.5rem' }} />
        </Link>
      </Box>
      <IconButton
        ref={menuAnchor}
        onClick={handleOpenMenu}
        className={clsx(styles.iconButton, {
          [styles.active]: menuOpen,
        })}
      >
        <AccountCircleOutlinedIcon />
      </IconButton>
      <PopperMenu
        onClose={handleCloseMenu}
        open={menuOpen}
        anchorEl={menuAnchor.current}
        placement='bottom-end'
        options={navbarMenuOps}
        onSelect={handleSelect}
      />
    </div>
  );
};

export default Navbar;
