import { Box } from '@mui/material';
import { Typography } from 'components';
import { useSelector } from 'hooks';
import { connectedLeanTechBanksSelector } from 'store/selectors/financialPlatformsSelector';

export const BankingPlatforms = () => {
  const connectedBanks = useSelector(connectedLeanTechBanksSelector);

  return (
    <>
      {connectedBanks.map((bank) => (
        <>
          <Box
            display='flex'
            justifyContent='space-between'
            alignItems='center'
            border={'2px solid'}
            borderColor={'darkBlue.light'}
            padding='.5rem'
            borderRadius='.5rem'
            width='100%'
          >
            <Box display='flex' alignItems='center' gap='2rem'>
              <Box
                minHeight='3rem'
                minWidth='9rem'
                display='flex'
                justifyContent='center'
                alignItems='center'
                bgcolor={'darkBlue.main'}
                padding='1.2rem 1.9rem'
                borderRadius={'.5rem'}
              >
                <img style={{ width: '3rem' }} src={bank.logo} alt={bank.name} />
              </Box>
              <Typography variant='h4'>{bank.name}</Typography>
            </Box>
          </Box>
        </>
      ))}
    </>
  );
};
