import React, { useState, useRef } from 'react';
import { TextField, Button, PasswordValidator } from 'components';
import { useAuth } from 'hooks';
import { PasswordLength } from 'Constants';

const initialFormData = {
  oldPassword: '',
  newPassword: '',
  confirmPassword: '',
};

type InputNames = keyof typeof initialFormData;

const inputFields = [
  { id: 'oldPassword', name: 'Current Password' },
  { id: 'newPassword', name: 'New Password' },
  { id: 'confirmPassword', name: 'Confirm Password' },
] as const;

const ChangePasswordForm = () => {
  const passwordInputRef = useRef<HTMLDivElement | null>(null);
  const { changePassword } = useAuth();
  const [openMenu, setOpenMenu] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  const [fieldHelpers, setFieldHelpers] = useState(initialFormData);

  const setFieldHelperContent = (name: InputNames, content: string) => {
    setFieldHelpers((fieldHelpers) => ({ ...fieldHelpers, [name]: content }));
  };

  const handleValidateInput = (name: InputNames, value: string) => () => {
    handleMenuClose();
    switch (name) {
      case 'oldPassword':
        if (value.length === 0) {
          setFieldHelperContent(name, 'Password is mandatory!');
        }
        break;
      case 'confirmPassword':
        if (formData.newPassword.length > 0 && value !== formData.newPassword) {
          setFieldHelperContent(name, 'Passwords must match!');
        }
        break;

      default:
        break;
    }
  };

  const handleInputChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = evt.target;
    if (fieldHelpers[name as InputNames]) {
      setFieldHelperContent(name as InputNames, '');
    }
    setFormData((formData) => ({ ...formData, [name]: value }));
  };

  const isFormDataValid = () => {
    const isOldPasswordValid = formData.oldPassword.length >= PasswordLength;
    const isNewPasswordValid = formData.newPassword.length >= PasswordLength;
    const isNewPassworConfirmed = formData.newPassword === formData.confirmPassword;

    return isOldPasswordValid && isNewPasswordValid && isNewPassworConfirmed;
  };

  const handleSubmit = async (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    setIsLoading(true);
    const { newPassword, oldPassword } = formData;

    await changePassword(oldPassword, newPassword);
    setFormData(initialFormData);
    setIsLoading(false);
  };

  const handleMenuOpen = () => {
    setOpenMenu(true);
  };

  const handleMenuClose = () => {
    if (!openMenu) return;
    setOpenMenu(false);
  };

  return (
    <form onSubmit={handleSubmit} style={{ position: 'relative' }}>
      {inputFields.map((input) => {
        const isNewPassword = input.id === 'newPassword';
        const inputValue = formData[input.id];
        return (
          <TextField
            size='small'
            key={input.id}
            name={input.id}
            label={input.name}
            fullWidth
            value={inputValue}
            onChange={handleInputChange}
            type='password'
            onFocus={isNewPassword ? handleMenuOpen : undefined}
            onBlur={handleValidateInput(input.id, inputValue)}
            error={Boolean(fieldHelpers[input.id])}
            helperText={fieldHelpers[input.id]}
            ref={isNewPassword ? passwordInputRef : undefined}
          />
        );
      })}

      <Button variant='contained' type='submit' disabled={!isFormDataValid()} isLoading={isLoading}>
        Save
      </Button>

      <PasswordValidator
        open={openMenu}
        anchorEl={passwordInputRef.current}
        value={formData.newPassword}
      />
    </form>
  );
};

export default ChangePasswordForm;
