import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store';

const userRootState = (state: RootState) => state.userSlice;

export const appUserSelector = createSelector(userRootState, (userState) => userState.user);

export const appUserAttributesSelector = createSelector(
  userRootState,
  (userState) => userState.user?.attributes,
);

export const userNamesArraySelector = createSelector(
  appUserAttributesSelector,
  (attributesState) => [
    attributesState?.given_name,
    attributesState?.family_name,
    attributesState?.['custom:middle_name'],
  ],
);

export const appUserAccessTokenSelector = createSelector(
  appUserSelector,
  (appUser) => appUser?.signInUserSession.accessToken.jwtToken,
);

export const appUserIdSelector = createSelector(
  appUserAttributesSelector,
  (userAttributes) => userAttributes?.sub,
);
