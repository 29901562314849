import { Box, CircularProgress } from '@mui/material';

type Props = {
  size?: number;
};
export const Loader = ({ size }: Props) => {
  return (
    <Box width='100%' height='100%' display='flex' justifyContent='center' alignItems='center'>
      <CircularProgress size={size} />
    </Box>
  );
};
