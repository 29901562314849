import React, { useState } from 'react';
import { TextField, Button, DividerWithWord } from 'components';
import { useAuth } from 'hooks';
import { GoogleIcon } from 'assets';
import { Link } from 'react-router-dom';
import { Box } from '@mui/material';
import { useTranslate } from 'react-polyglot';

const initialFormData = {
  username: '',
  password: '',
};

type Props = {
  allowGoogleLogin: boolean;
};

const SignInForm = ({ allowGoogleLogin }: Props) => {
  const t = useTranslate();
  const [formData, setFormData] = useState(initialFormData);
  const { signIn, federatedSignIn } = useAuth();

  const handleFormSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    const { username, password } = formData;
    signIn(username, password);
  };

  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((formData) => ({ ...formData, [evt.target.name]: evt.target.value }));
  };

  return (
    <form onSubmit={handleFormSubmit}>
      {allowGoogleLogin && (
        <>
          <Button onClick={federatedSignIn} startIcon={<GoogleIcon />} variant='outlined'>
            {t('signin.googleSignIn')}
          </Button>
          <DividerWithWord name={t('global.or')} sx={{ my: 1 }} />
        </>
      )}
      <TextField
        label={t('global.username')}
        name='username'
        fullWidth
        value={formData.username}
        onChange={handleChange}
      />
      <TextField
        label={t('global.password')}
        name='password'
        type='password'
        fullWidth
        value={formData.password}
        onChange={handleChange}
      />
      <Box alignSelf='start'>
        <Link to={'/forgot-password'}>{t('signin.forgotPassword')}</Link>
      </Box>
      <Button type='submit' variant='contained'>
        {t('signin.signin')}
      </Button>
    </form>
  );
};

export default SignInForm;
