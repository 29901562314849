import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material';

export const Wrapper = styled(Box)(() => ({
  display: 'grid',
}));

export const ListItem = styled(Grid, { shouldForwardProp: (prop) => prop !== 'provided' })<{
  provided: boolean;
}>(({ provided, theme }) => ({
  display: 'flex',
  gap: '.5rem',
  cursor: 'default',

  '& svg': {
    fill: provided ? theme.palette.success.main : theme.palette.lightBlue.main,
    width: '1.3rem',
    height: '1.3rem',
  },
}));
