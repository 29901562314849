import React, { useEffect } from 'react';
import { FinancialPlatform } from 'components';
import { useDispatch, useSelector } from 'hooks';
import { selectedFinancialAccountingPlatformsSelector } from 'store/selectors/financialPlatformsSelector';
import {
  deleteFinancialPlatform,
  removeRequestedFinancialPlatformConnection,
} from 'store/thunks/financialPlatformSliceThunk';
import { companyDetailsSelector } from 'store/selectors/companyDetailsSelector';
import { appendCustomAccountingPlatform } from 'store/slices/financialPlatformsSlice';
import { FinancialPlatformConnectionType } from 'types';

export const AccountingPlatforms = () => {
  const dispatch = useDispatch();
  const company = useSelector(companyDetailsSelector);
  const { available, onDemand } = useSelector(selectedFinancialAccountingPlatformsSelector);
  const handleCancelRequestedConnection = async (platformName: string) => {
    await dispatch(
      removeRequestedFinancialPlatformConnection({
        platformName,
        connectionType: FinancialPlatformConnectionType.ACCOUNTING,
      }),
    );
  };

  const handleDisconnect = async (connectionId: string) => {
    await dispatch(deleteFinancialPlatform(connectionId));
  };

  useEffect(() => {
    if (onDemand.length === 0 && Boolean(company?.requestedAccountingPlatform)) {
      dispatch(appendCustomAccountingPlatform(company?.requestedAccountingPlatform ?? ''));
    }
  }, []);

  return (
    <>
      {available.map((platform) => (
        <FinancialPlatform
          {...platform}
          key={platform.platformName}
          onDisconnect={handleDisconnect}
          onCancelRequestedConnection={handleCancelRequestedConnection}
        />
      ))}
      {onDemand.map((platform) => (
        <FinancialPlatform
          {...platform}
          key={platform.platformName}
          onDisconnect={handleDisconnect}
          onCancelRequestedConnection={handleCancelRequestedConnection}
        />
      ))}
    </>
  );
};
