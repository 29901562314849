import React, { useState } from 'react';
import { Button, Select, Typography, SelectableCard } from 'components';
import { Box } from '@mui/material';
import {
  postApplicationChosenProduct,
  updateApplicationChosenProduct,
} from 'store/thunks/detailedApplicationSliceThunk';
import { ApplicationChosenProductBody } from 'types';
import { useDispatch, useSelector } from 'hooks';
import { ApplicationProduct } from 'Portal Types';
import { appDataProductAmountSelector } from 'store/selectors/appDataSelector';
import { useTranslate } from 'react-polyglot';
import { Submenu } from './Submenu';
import { detailedApplicationIdSelector } from 'store/selectors/detailedApplicationSelector';

type Props = {
  viewOnly?: boolean;
  product?: ApplicationProduct;
  isFullWidth?: boolean;
};

export const ChooseProduct = ({ viewOnly = false, product, isFullWidth }: Props) => {
  const t = useTranslate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [loanValue, setLoanValue] = useState(product?.amount ? product.amount : 0);
  const [selectedMethod, setSelectedMethod] = useState<'ID' | 'RBF' | null>(
    product?.type ? product?.type ?? null : null,
  );
  const [invoicePeriod, setInvoicePeriod] = useState<'30' | '60' | '90'>(
    product?.tenor ? (String(product.tenor) as '30' | '60' | '90') : '30',
  );

  const productAmountOptions = useSelector(appDataProductAmountSelector);
  const applicationId = useSelector(detailedApplicationIdSelector);

  const handleMethodChange = (evt: React.MouseEvent<HTMLDivElement>) => {
    if (viewOnly) return;

    const targetId = evt.currentTarget.id as typeof selectedMethod;
    setSelectedMethod(targetId);
  };

  const handlePeriodChange = (evt: React.MouseEvent<HTMLDivElement>) => {
    if (viewOnly) return;
    const targetId = evt.currentTarget.id as typeof invoicePeriod;
    setInvoicePeriod(targetId);
  };

  const isFormValid = () => {
    return Boolean(loanValue) && Boolean(selectedMethod);
  };

  const handleSubmit = async () => {
    if (!selectedMethod || viewOnly) return;
    setIsLoading(true);

    const body: ApplicationChosenProductBody = {
      type: selectedMethod,
      amount: loanValue as number,
    };

    if (selectedMethod === 'ID') {
      body.tenor = parseInt(invoicePeriod, 10);
    }

    if (applicationId) {
      await dispatch(updateApplicationChosenProduct(body));
    } else {
      await dispatch(postApplicationChosenProduct(body));
    }

    setIsLoading(false);
  };

  return (
    <Box
      maxWidth={isFullWidth ? undefined : '30rem'}
      paddingTop='2rem'
      paddingLeft='2.5rem'
      display='flex'
      flexDirection='column'
    >
      <Typography variant='h1' fontWeight={600} sx={{ mb: '1.5rem' }}>
        {t('chooseProduct.title')}
      </Typography>
      <Select
        options={productAmountOptions}
        size='small'
        label={t('chooseProduct.funding')}
        onChange={(e) => setLoanValue(e.target.value as number)}
        value={loanValue}
        readOnly={viewOnly}
        sx={{ mb: '1.5rem' }}
      />
      <Box sx={{ mb: '1rem' }}>
        <SelectableCard
          id='ID'
          checked={selectedMethod === 'ID'}
          hasBelow={selectedMethod === 'ID'}
          onClick={handleMethodChange}
        >
          <Typography variant='h4' sx={{ mb: 2 }}>
            {t('chooseProduct.ibf')}
          </Typography>
          <Typography variant='body2' lineHeight={1.2} sx={{ gridColumn: '1/2', gridRow: '2/3' }}>
            {t('chooseProduct.idDescription')}
          </Typography>
        </SelectableCard>
        <Submenu
          expand={selectedMethod === 'ID'}
          handlePeriodChange={handlePeriodChange}
          invoicePeriod={invoicePeriod}
        />
      </Box>
      <SelectableCard checked={selectedMethod === 'RBF'} id={'RBF'} onClick={handleMethodChange}>
        <Typography variant='h4' sx={{ mb: 2 }}>
          {t('chooseProduct.rbf')}
        </Typography>
        <Typography variant='body2' lineHeight={1.2} sx={{ gridColumn: '1/2', gridRow: '2/3' }}>
          {t('chooseProduct.rbfDescription')}
        </Typography>
      </SelectableCard>
      <Button
        variant='contained'
        disabled={!isFormValid() || viewOnly}
        onClick={handleSubmit}
        isLoading={isLoading}
        sx={{ mt: '2rem' }}
      >
        {t('global.continue')}
      </Button>
    </Box>
  );
};
