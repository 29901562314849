export type FinancialPlatformType = {
  platformName: string;
  status: FinancialPlatformStatus;
  connectionId: string | null;
  connection_availability?: FinancialPlatformAvailability;
  connection_type?: FinancialPlatformConnectionType;
};

export type LeanTechData = {
  customerId: string;
  appToken: string;
};

export type LeanTechBankData = {
  name: string;
  logo: string;
};

export enum FinancialPlatformAvailability {
  AVAILABLE = 'AVAILABLE',
  ON_DEMAND = 'ON_DEMAND',
}

export enum FinancialPlatformConnectionType {
  ACCOUNTING = 'ACCOUNTING',
  PAYMENT = 'PAYMENT',
}

export enum FinancialPlatformStatus {
  NOT_CONNECTED = 'NotConnected',
  PENDING_AUTH = 'PendingAuth',
  LINKED = 'Linked',
  REQUESTED = 'Requested',
}

export type FinancialPlatformNameTypes = 'Stripe' | 'Sandbox' | '';
