import { envToBool } from 'utils/envToBool';
type PossibleEnvironmentsType = 'production' | 'local' | 'staging' | 'development' | undefined;

export const AppEnv = process.env.REACT_APP_ENV as PossibleEnvironmentsType;

export const isDevOrTest = AppEnv === 'local' || AppEnv === 'development' || AppEnv === 'staging';

export const baseAppUrl = process.env.REACT_APP_BASE_URL;
export const baseAPIUrl = process.env.REACT_APP_BASE_API_URL;

export const showMimicButtons = envToBool(process.env.REACT_APP_SHOW_MIMIC_BUTTONS ?? '');

export const codatDynamicHost = process.env.REACT_APP_CODAT_DYNAMIC_HOST ?? '';
export const showCodatIntegration = envToBool(process.env.REACT_APP_SHOW_CODAT_INTEGRATION ?? '');

export const allowGoogleLogin = envToBool(process.env.REACT_APP_ALLOW_GOOGLE_LOGIN ?? '');
export const authDomain = process.env.REACT_APP_COGNITO_DOMAIN;
export const requirementsTreshlold = parseInt(process.env.REACT_APP_REQUIREMENTS_TRESHOLD ?? '20');

export const enableIntercom = envToBool(process.env.REACT_APP_ENABLE_INTERCOM ?? '');
export const intercomAppId = process.env.REACT_APP_INTERCOM_APP_ID ?? '';
export const intercomHost = process.env.REACT_APP_INTERCOM_HOST;

export const nrLicenseKey = process.env.REACT_APP_NR_LICENSE_KEY ?? '';
export const nrAppId = process.env.REACT_APP_NR_APP_ID ?? '';
export const nrAgentId = process.env.REACT_APP_NR_AGENT_ID ?? '';

export const websiteUrl = process.env.REACT_APP_WEBSITE_URL ?? '';
