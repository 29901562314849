import { Modal as MuiModal, ModalProps, Paper, Box } from '@mui/material';

type Props = Omit<ModalProps, 'onClose'> & {
  onClose(): void;
};

export const Modal = ({ open, onClose, children, ...props }: Props) => {
  return (
    <MuiModal open={open} onClose={onClose} {...props}>
      <Box
        width='100%'
        height='100%'
        display='flex'
        justifyContent='center'
        alignItems='center'
        onClick={onClose}
      >
        <Paper onClick={(e) => e.stopPropagation()} sx={{ borderRadius: '10px' }}>
          {children}
        </Paper>
      </Box>
    </MuiModal>
  );
};
