/* eslint-disable camelcase */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  FinancialPlatformAvailability,
  FinancialPlatformConnectionType,
  FinancialPlatformStatus,
  FinancialPlatformType,
  LeanTechBankData,
} from 'types';
import {
  deleteFinancialPlatform,
  getCompanyFinancialPlatforms,
  getConnectedLeanTechBanks,
  removeRequestedFinancialPlatformConnection,
  requestFinancialPlatformConnection,
} from 'store/thunks/financialPlatformSliceThunk';

interface State {
  data: {
    accountingConnections: {
      available: FinancialPlatformType[];
      onDemand: FinancialPlatformType[];
    };
    paymentConnections: {
      available: FinancialPlatformType[];
      onDemand: FinancialPlatformType[];
    };
  };
  connectedBanks: Array<LeanTechBankData>;
  isLoading: boolean;
  isModalOpen: boolean;
  isModalLoading: boolean;
  initialTab?: string;
}

const initialState: State = {
  data: {
    accountingConnections: {
      available: [],
      onDemand: [],
    },
    paymentConnections: {
      available: [],
      onDemand: [],
    },
  },
  connectedBanks: [],
  isLoading: true,
  isModalOpen: false,
  initialTab: '',
  isModalLoading: false,
};

const financialPlatformsSlice = createSlice({
  initialState,
  name: 'financialPlatforms',
  reducers: {
    openFinancialPlatformModal(state, action: PayloadAction<string | undefined>) {
      state.initialTab = action.payload;
      state.isModalOpen = true;
    },
    closeFinancialPlatformModal(state) {
      state.isModalOpen = false;
      state.isModalLoading = false;
    },
    dropFinancialPlatformModalState(state) {
      state.initialTab = initialState.initialTab;
    },
    setIsFinancialPlatformModalLoading(state, action: PayloadAction<boolean>) {
      state.isModalLoading = action.payload;
    },
    appendCustomAccountingPlatform(state, action: PayloadAction<string>) {
      state.data.accountingConnections.onDemand = [
        ...state.data.accountingConnections.onDemand,
        {
          platformName: action.payload,
          connectionId: null,
          status: FinancialPlatformStatus.REQUESTED,
          connection_type: FinancialPlatformConnectionType.ACCOUNTING,
        },
      ];
    },
    appendCustomFinancialPlatform(
      state,
      action: PayloadAction<Partial<FinancialPlatformType> & { platformName: string }>,
    ) {
      const updatedPlatform = {
        connectionId: null,
        connection_type: FinancialPlatformConnectionType.PAYMENT,
        status: FinancialPlatformStatus.REQUESTED,
        ...action.payload,
      };

      state.data.paymentConnections.onDemand = [
        ...state.data.paymentConnections.onDemand,
        updatedPlatform,
      ];
    },
    updateAccountingPlatform(state, action: PayloadAction<FinancialPlatformType>) {
      state.data.accountingConnections.onDemand = state.data.accountingConnections.onDemand.map(
        (platform) => {
          return platform.platformName === action.payload.platformName
            ? { ...platform, status: action.payload.status }
            : { ...platform, status: FinancialPlatformStatus.NOT_CONNECTED };
        },
      );
    },
    updateCustomAccountingPlatform(state, action: PayloadAction<FinancialPlatformType>) {
      state.data.accountingConnections.onDemand = [
        ...state.data.accountingConnections.onDemand.map((platform) => ({
          ...platform,
          status: FinancialPlatformStatus.NOT_CONNECTED,
        })),
        action.payload,
      ];
    },
    updateFinancialPlatform(state, action: PayloadAction<FinancialPlatformType>) {
      state.data.paymentConnections.onDemand = state.data.paymentConnections.onDemand.map(
        (platform) => {
          return platform.platformName === action.payload.platformName ? action.payload : platform;
        },
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getConnectedLeanTechBanks.fulfilled, (state, action) => {
      state.connectedBanks = action.payload;
    });
    builder.addCase(getCompanyFinancialPlatforms.fulfilled, (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getCompanyFinancialPlatforms.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(deleteFinancialPlatform.fulfilled, (state, action) => {
      if (action.payload.status > 205) return;
      state.data.accountingConnections.available = state.data.accountingConnections.available.map(
        (platform) =>
          platform.connectionId === action.payload.connectionId
            ? { ...platform, connectionId: null, status: FinancialPlatformStatus.NOT_CONNECTED }
            : platform,
      );
    });
    builder.addCase(requestFinancialPlatformConnection.fulfilled, (state, action) => {
      const updatedPlatform = action.payload;

      if (updatedPlatform.platformName === 'None') return state;

      const {
        updateCustomAccountingPlatform,
        appendCustomFinancialPlatform,
        updateAccountingPlatform,
        updateFinancialPlatform,
      } = financialPlatformsSlice.caseReducers;

      const isAccountingPlatform =
        updatedPlatform.connection_type === FinancialPlatformConnectionType.ACCOUNTING;

      const updatedPlatformPlatformType = isAccountingPlatform
        ? 'accountingConnections'
        : 'paymentConnections';

      const updatedPlatformAvailability =
        updatedPlatform.connection_availability === FinancialPlatformAvailability.AVAILABLE
          ? 'available'
          : 'onDemand';

      const isCustomPlatform =
        state.data[updatedPlatformPlatformType][updatedPlatformAvailability].findIndex(
          (platform) => platform.platformName === updatedPlatform.platformName,
        ) < 0;

      if (isCustomPlatform) {
        if (isAccountingPlatform) {
          updateCustomAccountingPlatform(state, action);
        } else {
          appendCustomFinancialPlatform(state, action);
        }
      } else {
        if (isAccountingPlatform) {
          updateAccountingPlatform(state, action);
        } else {
          updateFinancialPlatform(state, action);
        }
      }
    });

    builder.addCase(removeRequestedFinancialPlatformConnection.fulfilled, (state, action) => {
      const updatedPlatform = action.payload;

      const updatedPlatformPlatformType =
        updatedPlatform.connection_type === FinancialPlatformConnectionType.ACCOUNTING
          ? 'accountingConnections'
          : 'paymentConnections';

      const updatedPlatformAvailability =
        updatedPlatform.connection_availability === FinancialPlatformAvailability.AVAILABLE
          ? 'available'
          : 'onDemand';

      const isCustomPlatform =
        state.data[updatedPlatformPlatformType][updatedPlatformAvailability].findIndex(
          (platform) => platform.platformName === updatedPlatform.platformName,
        ) < 0;

      if (isCustomPlatform) {
        state.data[updatedPlatformPlatformType][updatedPlatformAvailability] = state.data[
          updatedPlatformPlatformType
        ][updatedPlatformAvailability].filter(
          (platform) => platform.platformName !== updatedPlatform.platformName,
        );
      } else {
        state.data[updatedPlatformPlatformType][updatedPlatformAvailability] = state.data[
          updatedPlatformPlatformType
        ][updatedPlatformAvailability].map((platform) => {
          return platform.platformName === updatedPlatform.platformName
            ? { ...platform, status: updatedPlatform.status }
            : platform;
        });
      }
    });
  },
});

export const {
  closeFinancialPlatformModal,
  openFinancialPlatformModal,
  setIsFinancialPlatformModalLoading,
  appendCustomAccountingPlatform,
  appendCustomFinancialPlatform,
  dropFinancialPlatformModalState,
} = financialPlatformsSlice.actions;

export default financialPlatformsSlice.reducer;
