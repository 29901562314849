import { Typography, Box } from '@mui/material';
import { green } from '@mui/material/colors';
import { Center } from 'components';

const ThankYouPage = () => {
  return (
    <Center>
      <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
        <Typography variant='h3' sx={{ mb: '2rem', color: green[500] }}>
          Success !
        </Typography>
        <Typography variant='h5'>Thank You for submitting the form!</Typography>
        <Typography variant='h5'>We will get back to you shortly.</Typography>
      </Box>
    </Center>
  );
};

export default ThankYouPage;
