import { Popper, ClickAwayListener, MenuList, MenuItem, ListItemIcon, Paper } from '@mui/material';
import { Props } from './PopperMenu.types';

const PopperMenu = ({ options, open, onClose, onSelect, ...props }: Props) => {
  const handleClose = () => {
    onClose?.();
  };

  const handleSelect = (id: string) => {
    onSelect?.(id);
  };

  return (
    <Popper open={open} {...props}>
      <ClickAwayListener onClickAway={handleClose}>
        <Paper sx={{ bgcolor: 'darkBlue.main' }}>
          <MenuList>
            {options.map((option) => (
              <MenuItem key={option.id} onClick={() => handleSelect(option.id)}>
                {option.icon && <ListItemIcon>{option.icon}</ListItemIcon>}
                {option.name}
              </MenuItem>
            ))}
          </MenuList>
        </Paper>
      </ClickAwayListener>
    </Popper>
  );
};

export default PopperMenu;
