import { Select, outlinedInputClasses } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledSelect = styled(Select)`
  border-radius: 0.5rem;
  & .${outlinedInputClasses.notchedOutline} {
    border-color: #404859;
    border-width: 1px;
  }
  &:hover .${outlinedInputClasses.notchedOutline} {
    border-color: #404859;
    border-width: 1px;
  }

  &.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline} {
    border-color: #fff !important;
    border-width: 1px;
  }
`;
