import { styled } from '@mui/material';
import Box from '@mui/material/Box';

export const Wrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'hasBelow' && prop !== 'checked',
})<{
  hasBelow: boolean;
  checked: boolean;
}>(({ theme, hasBelow, checked }) => ({
  borderRadius: hasBelow
    ? `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`
    : theme.shape.borderRadius,
  border: '1px solid',
  borderColor: checked ? theme.palette.common.white : '#404859',
  padding: '1.5rem',
  display: 'grid',
  gridTemplateColumns: '1fr max-content',
  cursor: 'pointer',
  gap: '0.5rem',

  '& svg': {
    fill: theme.palette.common.white,
    alignSelf: 'center',
  },
}));
