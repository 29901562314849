import { DocumentRequirement } from 'Portal Types';
import { Typography } from 'components';
import Grid from '@mui/material/Grid';
import { ListItem } from './DocumentList.styles';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { Theme, useTheme, Tooltip, Box } from '@mui/material';

type Props = {
  requirements: DocumentRequirement[];
};

type ItemProps = {
  requirement: DocumentRequirement;
  theme: Theme;
};

const DocumentItem = ({ requirement, theme }: ItemProps) => {
  return (
    <Tooltip title={requirement.helper_text || requirement.name} arrow>
      <ListItem
        item
        xs={12}
        sm={5}
        md={4}
        provided={requirement.provided}
        data-testid='requirement'
      >
        <TaskAltIcon />
        <Box sx={{ maxWidth: 'calc(100% - 35px)' }} display='flex' gap='5px'>
          <Typography
            variant='body1'
            noWrap
            color={requirement.provided ? theme.palette.common.white : theme.palette.lightBlue.main}
          >
            {requirement.name}
          </Typography>
          {requirement.isOptional ? <span>*</span> : null}
        </Box>
      </ListItem>
    </Tooltip>
  );
};

export const DocumentsList = ({ requirements }: Props) => {
  const theme = useTheme();
  return (
    <Grid container spacing={1} justifyContent='flex-start' data-testid='container'>
      {requirements.map((requirement) => (
        <DocumentItem requirement={requirement} theme={theme} key={requirement.name} />
      ))}
    </Grid>
  );
};
