import { Box } from '@mui/material';
import { AccountInfoContainer, Typography, ChangePasswordForm } from 'components';

const SecuritySettingsPage = () => {
  return (
    <AccountInfoContainer>
      <Box>
        <Typography variant='h1' sx={{ mb: '10px' }}>
          Security Settings
        </Typography>
        <Typography variant='h2'>Change Password</Typography>
      </Box>
      <ChangePasswordForm />
    </AccountInfoContainer>
  );
};

export default SecuritySettingsPage;
