import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  BankData,
  CompanyDetails,
  DocumentProvided,
  DocumentRequirement,
  RequestStatus,
} from 'Portal Types';
import {
  fetchCompanyDetails,
  postCompanyDetails,
  updateCompanyDetails,
  getAccountDocuments,
  uploadDocument,
  updateUploadedDocument,
  deleteUploadedDocument,
  submitAccountBankingDetails,
} from 'store/thunks/companySliceThunk';
import {
  submitDocuments,
  submitApplicationBankingDetails,
} from 'store/thunks/detailedApplicationSliceThunk';
import { CompanyDetailsWithLinks, DocumentProvidedWithLinks } from 'types/CompanyDetails.types';

interface State {
  data: CompanyDetailsWithLinks | null;
  documents: {
    isLoading: boolean;
    isUploading: boolean;
    documents: DocumentProvided[];
    requirements: DocumentRequirement[];
  };
  error: { status: number } | null;
  status?: RequestStatus;
}

const initialState: State = {
  data: null,
  documents: {
    isLoading: true,
    isUploading: false,
    documents: [],
    requirements: [],
  },
  error: null,
  status: 'idle',
};

const companySlice = createSlice({
  name: 'companySlice',
  initialState,
  reducers: {
    setCompanyDetails(state, action: PayloadAction<CompanyDetails>) {
      state.data = action.payload;
    },
    dropCompanyDetails(state) {
      state.data = null;
    },
    setAreDocumentsUploading(state, action: PayloadAction<boolean>) {
      state.documents.isUploading = action.payload;
    },
    resetCompanyState(state) {
      state.data = initialState.data;
      state.documents = initialState.documents;
      state.error = initialState.error;
      state.status = initialState.status;
    },
    updateStateDocument(state, action: PayloadAction<DocumentProvidedWithLinks>) {
      state.documents.documents = state.documents.documents.map((document) =>
        document.id === action.payload.id ? { ...document, ...action.payload } : document,
      );
      state.documents.requirements = state.documents.requirements.map((requirement) =>
        requirement.requirement_id === action.payload.requirementId
          ? { ...requirement, provided: true }
          : requirement,
      );
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchCompanyDetails.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(fetchCompanyDetails.fulfilled, (state, action) => {
      state.data = action.payload;
      state.status = 'finished';
    });
    builder.addCase(fetchCompanyDetails.rejected, (state, action) => {
      state.error = action.payload ?? null;
      state.status = 'finished';
    });
    builder.addCase(postCompanyDetails.fulfilled, (state, action) => {
      state.data = action.payload;
    });
    builder.addCase(postCompanyDetails.rejected, (state, action) => {
      state.error = action.payload ?? null;
    });
    builder.addCase(updateCompanyDetails.fulfilled, (state, action) => {
      state.data = action.payload;
    });
    builder.addCase(getAccountDocuments.fulfilled, (state, action) => {
      state.documents.documents = action.payload.documents;
      state.documents.requirements = action.payload.requirements;
      state.documents.isLoading = false;
    });
    builder.addCase(getAccountDocuments.rejected, (state) => {
      state.documents.isLoading = false;
    });
    builder.addCase(uploadDocument.fulfilled, (state, action) => {
      state.documents.documents.push(action.payload);
    });
    builder.addCase(uploadDocument.rejected, (state, action) => {
      if (!action.payload) return;
      state.documents.documents.push(action.payload);
    });
    builder.addCase(updateUploadedDocument.fulfilled, (state, action) => {
      state.documents.documents = state.documents.documents.map((document) =>
        document.id === action.payload.id ? { ...document, ...action.payload } : document,
      );
      state.documents.requirements =
        action.payload._embedded?.requirements ?? state.documents.requirements;
    });
    builder.addCase(deleteUploadedDocument.fulfilled, (state, action) => {
      state.documents.documents = state.documents.documents.filter(
        (document) => document.id !== action.payload,
      );
    });
    builder.addCase(submitAccountBankingDetails.fulfilled, (state, action) => {
      if (!state.data) return;
      state.data.bank = action.payload;
    });
    builder.addCase(submitDocuments.fulfilled, (state, action) => {
      if (action.payload._embedded) {
        state.documents.documents = action.payload._embedded.documents;
        state.documents.requirements = action.payload._embedded.requirements;
      }
    });
    builder.addCase(submitApplicationBankingDetails.fulfilled, (state, action) => {
      if (!state.data?.bank) return;
      state.data = { ...state.data, bank: action.payload._embedded?.bankDetails as BankData };
    });
  },
});

export const {
  setCompanyDetails,
  dropCompanyDetails,
  updateStateDocument,
  setAreDocumentsUploading,
  resetCompanyState,
} = companySlice.actions;

export default companySlice.reducer;
