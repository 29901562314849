import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/store';

export const companyDetailsRootSelector = (state: RootState) => state.companySlice;

export const companyDetailsSelector = createSelector(
  companyDetailsRootSelector,
  (companyDetails) => companyDetails.data,
);

export const companyDetailsErrorSelector = createSelector(
  companyDetailsRootSelector,
  (companyRoot) => companyRoot.error,
);

export const companyBankDetailsSelector = createSelector(
  companyDetailsSelector,
  (companyDetails) => companyDetails?.bank,
);

export const companyDetailsStatusSelector = createSelector(
  companyDetailsRootSelector,
  (companyDetails) => companyDetails.status,
);

export const companyDocumentsRootSelector = createSelector(
  companyDetailsRootSelector,
  (companyDetails) => companyDetails.documents,
);

export const isCompanyDocumentsLoadingSelector = createSelector(
  companyDocumentsRootSelector,
  (companyDocuments) => companyDocuments.isLoading,
);

export const isCompanyDocumentsUploadingSelector = createSelector(
  companyDocumentsRootSelector,
  (companyDocuments) => companyDocuments.isUploading,
);

export const companyDocumetsSelector = createSelector(
  companyDocumentsRootSelector,
  (companyDocuments) => companyDocuments.documents,
);

export const companyRequirementsCountSelector = createSelector(
  companyDocumentsRootSelector,
  ({ requirements }) => requirements.length,
);

export const companyDocumentsFinancialsSelector = createSelector(
  companyDocumentsRootSelector,
  ({ documents, requirements }) => {
    const riskDocuments = requirements
      .filter((requirement) => requirement.category === 'RISK')
      .map((requirement) => requirement.requirement_id);

    return documents.filter(
      (document) => !document.requirementId || riskDocuments.includes(document.requirementId),
    );
  },
);

export const companyDocumentsKycSelector = createSelector(
  companyDocumentsRootSelector,
  ({ documents, requirements }) => {
    const kycRequirementIds = requirements
      .filter((requirement) => requirement.category === 'KYC')
      .map((requirement) => requirement.requirement_id);

    return documents.filter(
      (document) => !document.requirementId || kycRequirementIds.includes(document.requirementId),
    );
  },
);

export const companyDocumentsRequirementsSelector = createSelector(
  companyDocumentsRootSelector,
  (companyDocuments) => companyDocuments.requirements,
);

export const companyDocumentsKycRequirementsSelector = createSelector(
  companyDocumentsRequirementsSelector,
  (requirements) => requirements.filter((requirement) => requirement.category === 'KYC'),
);

export const companyDocumentsFinancialRequirementsSelector = createSelector(
  companyDocumentsRequirementsSelector,
  (requirements) => requirements.filter((requirement) => requirement.category === 'RISK'),
);

export const areAllFinancialRequirementsMarkedSelector = createSelector(
  companyDocumentsFinancialRequirementsSelector,
  (financialRequirements) => financialRequirements.every((requirement) => requirement.provided),
);

export const areAllKYCRequirementsMarkedSelector = createSelector(
  companyDocumentsKycRequirementsSelector,
  (kycRequirements) => kycRequirements.every((requirement) => requirement.provided),
);
