import { useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import { Typography, ApplicationStatusIndicator, Loader } from 'components';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { IconButton, Step } from './HorizontalApplicationSteps.styles';
import { useSelector } from 'hooks';
import { detailedApplicationDataSelector } from 'store/selectors/detailedApplicationSelector';
import { useTranslate } from 'react-polyglot';
import { formatNumber } from 'utils/formatNumber';
import { applicationViews } from 'pages/Application/Application.config';
import { ApplicationStatus } from 'Portal Types';

type Props = {
  applicationStatusIndex?: number;
  viewMode: ApplicationStatus;
  isLoading?: boolean;
  changeViewMode(to: ApplicationStatus): void;
};

export const HorizontalApplicationSteps = ({
  applicationStatusIndex = -1,
  viewMode,
  isLoading,
  changeViewMode,
}: Props) => {
  const t = useTranslate();
  const [open, setOpen] = useState(false);
  const application = useSelector(detailedApplicationDataSelector);

  const currentPathIndex = useMemo(
    () => applicationViews.findIndex((route) => viewMode === route.id),
    [applicationStatusIndex, viewMode],
  );

  const toggleCollapse = () => setOpen((open) => !open);

  return (
    <Box sx={{ borderBottom: '1px solid rgba(255, 255, 255, 0.15)' }} padding='2rem'>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Box display='flex' justifyContent='space-between' marginBottom={2} alignItems='center'>
            <Typography variant='h2'>New Application</Typography>
            <IconButton onClick={toggleCollapse}>
              {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </Box>
          <Collapse in={open}>
            <Box display='flex' justifyContent='space-between'>
              <Box>
                {application?.product?.amount && (
                  <>
                    <Typography variant='body2'>You have requested</Typography>
                    <Typography variant='h2'>
                      ${formatNumber(parseInt(String(application?.product?.amount)) ?? 0)}
                    </Typography>
                  </>
                )}
              </Box>
              <ApplicationStatusIndicator
                status={t(`application.${application.status ?? 'new_application'}`)}
              />
            </Box>
            <Box display='flex' gap={'.5rem'} marginTop='1.5rem' justifyContent='space-evenly'>
              {applicationViews.map((view, idx) => {
                const disabled = applicationStatusIndex < idx;
                return (
                  <Step
                    key={view.id}
                    marked={idx <= currentPathIndex}
                    disabled={disabled}
                    onClick={disabled ? undefined : () => changeViewMode(view.id)}
                  />
                );
              })}
            </Box>
          </Collapse>
        </>
      )}
    </Box>
  );
};
