import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  Application,
  WithLinks,
  RequestStatus,
  WithEmbedded,
  BankData,
  DocumentProvided,
  DocumentRequirement,
} from 'Portal Types';
import {
  fetchDetailedApplication,
  postAcceptedOffer,
  postApplicationChosenProduct,
  submitDocuments,
  submitFinancialDocuments,
  refetchDetailedApplication,
  submitApplicationBankingDetails,
  submitKycDocuments,
  requestAdditionalOffer,
  updateApplicationChosenProduct,
} from 'store/thunks/detailedApplicationSliceThunk';
import {
  uploadDocument,
  updateUploadedDocument,
  deleteUploadedDocument,
  retryDocumentUpload,
} from 'store/thunks/companySliceThunk';
import { SelfLink } from 'types';

type DetailedApplicationState = WithEmbedded<
  WithLinks<
    Application,
    {
      submitDocuments?: SelfLink;
      submitFinancials?: SelfLink;
      submitKyc?: SelfLink;
      submitBanking?: SelfLink;
      acceptOffer?: SelfLink;
      requestOffer?: SelfLink;
      updateApplication?: SelfLink;
      uploadFinancial?: SelfLink;
    }
  >,
  { bankDetails?: BankData; documents: DocumentProvided[]; requirements: DocumentRequirement[] }
>;

interface State {
  data: DetailedApplicationState;
  status: RequestStatus;
  error: {
    status: number;
  } | null;
  isLoading: boolean;
}

const initialState: State = {
  isLoading: true,
  error: null,
  data: {
    _links: {},
    _embedded: undefined,
    id: '',
    offers: [],
    product: {
      amount: '',
      type: '',
      tenor: '',
    },
    status: 'new_application',
    name: 'New application',
    currency: '',
  },
  status: 'idle',
};

const detailedApplicationSlice = createSlice({
  name: 'detailedApplicationSlice',
  initialState,
  reducers: {
    setDetailedApplicationId(state, action: PayloadAction<string>) {
      state.data.id = action.payload;
    },
    setIsDetailedApplicationLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    resetDetailedApplicationState(state) {
      state.data = initialState.data;
      state.status = initialState.status;
      state.isLoading = initialState.isLoading;
      state.error = initialState.error;
    },
    dropDocument(state, action: PayloadAction<string>) {
      if (state.data._embedded) {
        state.data._embedded.documents = state.data._embedded?.documents.filter(
          (document) => document.id !== action.payload,
        );
      }
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchDetailedApplication.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchDetailedApplication.fulfilled, (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    });
    builder.addCase(fetchDetailedApplication.rejected, (state, action) => {
      state.isLoading = false;
      state.status = 'failed';
      state.error = action.payload ?? null;
    });
    builder.addCase(postApplicationChosenProduct.fulfilled, (state, action) => {
      state.data.id = action.payload.id;
    });
    builder.addCase(updateApplicationChosenProduct.fulfilled, (state, action) => {
      state.data = action.payload;
    });
    builder.addCase(postAcceptedOffer.fulfilled, (state, action) => {
      state.data = action.payload;
    });
    builder.addCase(submitDocuments.fulfilled, (state, action) => {
      state.data = action.payload;
    });
    builder.addCase(submitFinancialDocuments.fulfilled, (state, action) => {
      state.data = action.payload;
    });
    builder.addCase(refetchDetailedApplication.fulfilled, (state, action) => {
      state.data = action.payload;
    });
    builder.addCase(refetchDetailedApplication.rejected, (state, action) => {
      state.error = action.payload ?? null;
    });
    builder.addCase(submitApplicationBankingDetails.fulfilled, (state, action) => {
      state.data = action.payload;
    });
    builder.addCase(submitKycDocuments.fulfilled, (state, action) => {
      state.data = action.payload;
    });
    builder.addCase(requestAdditionalOffer.fulfilled, (state, action) => {
      state.data = action.payload;
    });

    builder.addCase(uploadDocument.fulfilled, (state, action) => {
      const { _embedded } = state.data;

      if (!_embedded) return;

      _embedded.documents.push(action.payload);
    });

    builder.addCase(uploadDocument.rejected, (state, action) => {
      const { _embedded } = state.data;
      if (!action.payload || !_embedded) return;
      _embedded.documents.push(action.payload);
    });

    builder.addCase(deleteUploadedDocument.fulfilled, (state, action) => {
      if (!state.data._embedded) return;

      let deletedDocument: DocumentProvided | undefined = undefined;

      const filteredDocuments = state.data._embedded.documents.filter((document) => {
        const shouldFilter = document.id !== action.payload;
        if (!shouldFilter) {
          deletedDocument = document;
        }

        return shouldFilter;
      });

      const isSomeDocumentWithTheSameTypeLeft = filteredDocuments.some(
        (document) =>
          document.id !== action.payload &&
          document.requirementId === deletedDocument?.requirementId,
      );

      if (!isSomeDocumentWithTheSameTypeLeft) {
        state.data._embedded.requirements = state.data._embedded.requirements.map((requirement) => {
          if (requirement.requirement_id === deletedDocument?.requirementId) {
            return { ...requirement, provided: false };
          }
          return requirement;
        });
      }

      state.data._embedded.documents = filteredDocuments;
    });

    builder.addCase(updateUploadedDocument.fulfilled, (state, action) => {
      const { _embedded, ...document } = action.payload;
      if (!state.data._embedded || !_embedded) return;

      state.data._embedded.requirements = _embedded.requirements;

      state.data._embedded.documents = state.data._embedded.documents.map((stateDocument) =>
        stateDocument.id === document.id ? { ...stateDocument, ...document } : stateDocument,
      );
    });

    builder.addCase(retryDocumentUpload.fulfilled, (state, action) => {
      const { _embedded } = state.data;
      if (!_embedded) return;

      _embedded.documents = _embedded.documents.map((document) =>
        document.id === action.payload.oldDocumentId ? action.payload : document,
      );
    });
  },
});

export const {
  resetDetailedApplicationState,
  setDetailedApplicationId,
  setIsDetailedApplicationLoading,
  dropDocument,
} = detailedApplicationSlice.actions;

export default detailedApplicationSlice.reducer;
