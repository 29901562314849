import React from 'react';
import { Wrapper } from './SelectableCard.styles';
import DoneIcon from '@mui/icons-material/Done';
import { Box } from '@mui/material';

type Props = {
  children: React.ReactNode;
  checked?: boolean;
  hasBelow?: boolean;
  id?: string;
  onClick?(e: React.MouseEvent<HTMLDivElement>): void;
};

export const SelectableCard = ({
  children,
  id,
  checked = false,
  hasBelow = false,
  onClick,
}: Props) => {
  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    onClick?.(e);
  };

  return (
    <Wrapper hasBelow={hasBelow} checked={checked} onClick={handleClick} id={id}>
      <Box>{children}</Box>
      {checked ? <DoneIcon /> : <Box width='1.5rem' />}
    </Wrapper>
  );
};
