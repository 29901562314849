import { useState } from 'react';
import { useLocation } from 'react-router';
import { TextField, Button, Typography, DividerWithWord, HorizontalProgress } from 'components';
import { useAuth } from 'hooks';
import { useTranslate } from 'react-polyglot';
import { authSteps } from 'pages/Authentication/Authentication.config';

type LocationState = {
  username: string;
};

const ConfirmEmailPage = () => {
  const t = useTranslate();
  const [code, setCode] = useState('');
  const { confirmSignUp, resendSignUpConfirm } = useAuth();
  const { username } = useLocation().state as LocationState;

  const handleConfirmEmail = () => {
    if (!username) return;
    confirmSignUp(username, code);
  };

  const handleResendConfirmation = () => {
    resendSignUpConfirm(username);
  };

  const isFormValid = () => {
    const isCodeValid = code.length === 6;

    return isCodeValid;
  };

  return (
    <div>
      <Typography variant='h1'>{t('signup.signup')}</Typography>
      <HorizontalProgress options={authSteps} highlightOptions={1} />
      <Typography sx={{ alignSelf: 'start' }} fontSize='1rem'>
        {t('confirmEmail.guideA')}
      </Typography>

      <TextField
        fullWidth
        label={t('confirmEmail.confirmation')}
        size='small'
        value={code}
        onChange={(e) => setCode(e.target.value)}
      />
      <Button variant='contained' onClick={handleConfirmEmail} disabled={!isFormValid()}>
        {t('global.confirm')}
      </Button>
      <DividerWithWord name={t('global.or')} />
      <Button onClick={handleResendConfirmation} variant='outlined'>
        {t('confirmEmail.resendCode')}
      </Button>
    </div>
  );
};

export default ConfirmEmailPage;
