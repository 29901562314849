import { Box, Divider, Modal, useMediaQuery, useTheme, IconButton, Backdrop } from '@mui/material';
import { Button, Typography, TextField } from 'components';
import { OfferList } from './OffersList';
import { useState } from 'react';
import { useDispatch, useSelector } from 'hooks';
import {
  postAcceptedOffer,
  requestAdditionalOffer,
} from 'store/thunks/detailedApplicationSliceThunk';
import { detailedApplicationDataSelector } from 'store/selectors/detailedApplicationSelector';
import { WaitingForOffers } from '../WaitingForOffers';
import { ApplicationStatus } from 'Portal Types';
import CloseIcon from '@mui/icons-material/Close';

type Props = {
  viewOnly?: boolean;
  propsSelectedOffer?: string;
  disableRequestOffer?: boolean;
  changeViewMode?(viewMode: ApplicationStatus): void;
};

export const SelectOffer = ({
  viewOnly,
  propsSelectedOffer = '',
  disableRequestOffer,
  changeViewMode,
}: Props) => {
  const detailedApplication = useSelector(detailedApplicationDataSelector);
  const dispatch = useDispatch();
  const [selectedOffer, setSelectedOffer] = useState(propsSelectedOffer ? propsSelectedOffer : '');
  const [modalOpen, setModalOpen] = useState(false);
  const [offerReason, setOfferReason] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  const handleSelectOffer = (id: string) => {
    if (viewOnly) return;
    setSelectedOffer(id);
  };

  const submitSelectedOffer = async () => {
    setIsLoading(true);

    await dispatch(postAcceptedOffer(selectedOffer));
    setIsLoading(false);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleRequestNewOffers = async () => {
    await dispatch(requestAdditionalOffer({ reason: offerReason }));
    handleCloseModal();
  };

  return detailedApplication.status === 'waiting_offer' ? (
    <WaitingForOffers changeViewMode={changeViewMode} />
  ) : (
    <>
      <Box margin={4}>
        <Typography variant='h1' fontWeight={600}>
          Select Offer
        </Typography>
        <Typography variant='body1' fontWeight={600} sx={{ mt: '1rem', color: 'common.white' }}>
          Please select an offer that fits the best for you, or request a new offer
        </Typography>
        <OfferList
          selectedOffer={selectedOffer}
          disableCta={viewOnly}
          onSelect={handleSelectOffer}
        />
        <Box width={matches ? undefined : '30rem'}>
          <Typography variant='body1' fontWeight={600} sx={{ my: '1rem', color: 'common.white' }}>
            Offers didn’t fit to your company?
          </Typography>
          <Button
            variant='outlined'
            disabled={viewOnly || disableRequestOffer}
            sx={{ mb: '1rem' }}
            onClick={handleOpenModal}
          >
            Request new offers
          </Button>

          <Divider />
          <Button
            variant='contained'
            disabled={!selectedOffer || viewOnly}
            sx={{ mt: 2 }}
            onClick={submitSelectedOffer}
            isLoading={isLoading}
          >
            Accept selected offer and continue
          </Button>
        </Box>
      </Box>
      <Modal open={modalOpen} onClose={handleCloseModal}>
        <Backdrop
          open={modalOpen}
          onClick={handleCloseModal}
          sx={{ backgroundColor: 'rgba(0, 0, 0, 0.25)', backdropFilter: 'blur(5px)' }}
        >
          <Box
            gap='1rem'
            padding='1rem'
            position='absolute'
            top='50%'
            left='50%'
            display='flex'
            flexDirection='column'
            width='100%'
            maxWidth='28rem'
            borderRadius='.5rem'
            sx={{ transform: 'translate(-50%, -50%)', bgcolor: 'darkBlue.light' }}
            onClick={(e) => e.stopPropagation()}
          >
            <Box display='flex' alignItems='center' justifyContent='space-between'>
              <Typography variant='h2' fontWeight={600}>
                Request new offer
              </Typography>
              <IconButton onClick={handleCloseModal}>
                <CloseIcon />
              </IconButton>
            </Box>
            <TextField
              multiline
              minRows={4}
              value={offerReason}
              onChange={(e) => setOfferReason(e.target.value)}
              size='small'
              placeholder='Please specify of what you are looking for?'
              fullWidth
            />
            <Button
              variant='contained'
              disabled={!offerReason || !detailedApplication._links?.requestOffer}
              onClick={handleRequestNewOffers}
            >
              Request new offer
            </Button>
          </Box>
        </Backdrop>
      </Modal>
    </>
  );
};
