import { useDispatch } from 'hooks';
import { useState } from 'react';
import { closeFinancialPlatformModal } from 'store/slices/financialPlatformsSlice';
import { getSelfData } from 'store/thunks/appDataSliceThunk';

export const useLeanTechCallback = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const leanTechCallback = async (response: LeanTechCallbackResponse) => {
    if (response.status === 'SUCCESS') {
      try {
        setIsLoading(true);
        await dispatch(getSelfData());
        dispatch(closeFinancialPlatformModal());
      } finally {
        setIsLoading(false);
      }
    }
  };

  return { isLoading, leanTechCallback };
};
