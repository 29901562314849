import { Box } from '@mui/material';
import { Typography } from 'components';
import palette from 'theme/palette/palette';

type Props = {
  status: string;
  statusColor?: string;
};

export const ApplicationStatusIndicator = (props: Props) => {
  return (
    <Box
      padding='.4rem .75rem'
      borderRadius={'200px'}
      display='flex'
      alignItems='center'
      bgcolor={'rgba(255, 255, 255, 0.1)'}
      alignSelf='flex-start'
      width='max-content'
    >
      <Box
        bgcolor={props.statusColor ?? '#D9D9D9'}
        width='.5rem'
        height={'.5rem'}
        borderRadius={'50%'}
        marginRight='.5rem'
      />
      <Typography
        fontSize='0.9rem'
        sx={{ color: palette.common.white, transform: 'translateY(1px)' }}
      >
        {props.status}
      </Typography>
    </Box>
  );
};
