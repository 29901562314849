import { Link } from 'react-router-dom';
import { Typography, SignInForm } from 'components';
import { Box } from '@mui/material';
import { useTranslate } from 'react-polyglot';
import { allowGoogleLogin } from 'portal.config';

type Props = {
  allowGoogleLogin?: boolean;
};

const LogInPage = ({ allowGoogleLogin: propsAllowGoogleLogin }: Props) => {
  const t = useTranslate();
  return (
    <div>
      <Typography variant='h1'>{t('signin.signin')}</Typography>
      <SignInForm allowGoogleLogin={propsAllowGoogleLogin ?? allowGoogleLogin} />
      <Box display='flex' gap='.3rem'>
        <Typography fontSize='0.875rem'>{t('signin.noAccountNotice')}</Typography>
        <Link to={'/signup'}>{t('signup.signup')}</Link>
      </Box>
    </div>
  );
};

export default LogInPage;
