import { AccountInfoContainer, Typography, TextField, Button } from 'components';
import { IBANLength } from 'Constants';
import { useDispatch, useSelector } from 'hooks';
import { BankData } from 'Portal Types';
import React, { useEffect, useMemo, useState } from 'react';
import { companyDetailsSelector } from 'store/selectors/companyDetailsSelector';
import { submitAccountBankingDetails } from 'store/thunks/companySliceThunk';
import { fieldDifference } from 'utils/formValidation';
import {
  BankingDetailsForm,
  commonInputFields,
  initialFormData,
  rowFields,
  zaFields,
} from './BankingDetails.config';

export const BankingDetails = () => {
  const dispatch = useDispatch();
  const companyDetails = useSelector(companyDetailsSelector);
  const [formData, setFormData] = useState<BankingDetailsForm>(initialFormData);
  const [isLoading, setIsLoading] = useState(false);
  const [fieldHelpers, setFieldHelpers] = useState(initialFormData);

  type InputNames = keyof typeof formData;

  useEffect(() => {
    if (!companyDetails) return;

    Object.keys(companyDetails.bank).forEach((bankDetail) => {
      const bankDetailsValue = companyDetails.bank[bankDetail as keyof BankData];

      if (bankDetailsValue) {
        setFormData((formData) => ({ ...formData, [bankDetail]: bankDetailsValue }));
      }
    });
  }, [companyDetails]);

  const inputFields = useMemo(() => {
    if (companyDetails?.incorporationCountry === 'ZA') {
      return [...commonInputFields, ...zaFields];
    } else {
      return [...commonInputFields, ...rowFields];
    }
  }, [companyDetails]);

  const hasUpdateLink = useMemo(
    () => Boolean(companyDetails?._links?.updateBank),
    [companyDetails?._links?.updateBank?.href],
  );

  const setFieldHelperContent = (name: InputNames, content: string) => {
    setFieldHelpers((fieldHelpers) => ({ ...fieldHelpers, [name]: content }));
  };

  const handleValidateInput = (name: InputNames, value: string) => () => {
    switch (name) {
      case 'swift':
        if (value.length === 0) {
          setFieldHelperContent(name, 'SWIFT is mandatory!');
        }
        break;
      case 'iban':
        if (value.length === 0) {
          setFieldHelperContent(name, 'IBAN is mandatory!');
        }
        break;
      case 'bankAddress':
        if (value.length === 0) {
          setFieldHelperContent(name, 'The bank address is mandatory!');
        }
        break;
      case 'bankName':
        if (value.length === 0) {
          setFieldHelperContent(name, 'The bank name is mandatory!');
        }
        break;

      default:
        break;
    }
  };

  const handleInputChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const inputName = evt.target.name as InputNames;

    if (fieldHelpers[inputName]) {
      setFieldHelperContent(inputName, '');
    }

    setFormData((formData) => ({ ...formData, [inputName]: evt.target.value }));
  };

  const handleSubmit = async (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    setIsLoading(true);

    await dispatch(submitAccountBankingDetails(formData));
    setIsLoading(false);
  };

  const isFormValid = () => {
    const {
      bankAddress: inputAddress,
      bankName: inputBankName,
      iban: inputIban,
      swift: inputSwift,
      accountNumber: inputAccountNumber,
      branchCode: inputBranchCode,
    } = formData;
    const { bankAddress, bankName, iban, swift, accountNumber, branchCode } =
      companyDetails?.bank as BankData;

    const isAddressChanged = fieldDifference(inputAddress, bankAddress);
    const isBankNameChanged = fieldDifference(inputBankName, bankName);
    const isIbanChanged = fieldDifference(inputIban, iban);
    const isSwiftChanged = fieldDifference(inputSwift, swift);
    const isAccountNumberChanged = fieldDifference(inputAccountNumber, accountNumber);
    const isBranchCodeChanged = fieldDifference(inputBranchCode, branchCode);

    const isIbanValid = inputIban.length > 0 && inputIban.length <= IBANLength;
    const isSwiftValid = inputSwift.length > 0;
    const areRequiredFieldsValid = isIbanValid && isSwiftValid;

    if (companyDetails?.incorporationCountry === 'ZA') {
      const isAnyFieldDifferent =
        isAddressChanged || isBankNameChanged || isAccountNumberChanged || isBranchCodeChanged;

      return isAnyFieldDifferent && hasUpdateLink;
    }

    const isAnyFieldDifferent =
      isAddressChanged || isBankNameChanged || isIbanChanged || isSwiftChanged;

    return isAnyFieldDifferent && areRequiredFieldsValid && hasUpdateLink;
  };

  const getComputedPlaceholder = (inputName: keyof typeof formData) => {
    switch (inputName) {
      case 'iban':
        return 'EG380019011500000000263180002';
      case 'swift':
        return 'UNBEEGCXCAI';
      default:
        return '';
    }
  };

  return (
    <AccountInfoContainer>
      <Typography variant='h1'>Banking details</Typography>
      <form onSubmit={handleSubmit}>
        {inputFields.map((field) => (
          <TextField
            key={field.id}
            fullWidth
            name={field.id}
            value={formData[field.id]}
            onChange={handleInputChange}
            label={field.label}
            placeholder={getComputedPlaceholder(field.id)}
            error={Boolean(fieldHelpers[field.id])}
            helperText={fieldHelpers[field.id]}
            onBlur={handleValidateInput(field.id, formData[field.id])}
            disabled={!hasUpdateLink}
          />
        ))}
        {hasUpdateLink && (
          <Button variant='contained' type='submit' disabled={!isFormValid()} isLoading={isLoading}>
            Save
          </Button>
        )}
      </form>
    </AccountInfoContainer>
  );
};
