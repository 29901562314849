import { Components } from '@mui/material';
import palette from 'theme/palette/palette';

export const MuiButton: Components['MuiButton'] = {
  defaultProps: {
    disableRipple: true,
    disableElevation: true,
    fullWidth: true,
  },

  styleOverrides: {
    root: {
      textTransform: 'none',
      lineHeight: 1.3,
      fontSize: '.85rem',
      padding: '.6875rem 1rem',
      color: palette.common.white,

      '&.Mui-disabled': {
        color: '#4a4a4a',

        '& svg': {
          opacity: '.5 !important',
        },
      },

      '&: hover': {
        backgroundColor: '#2B3142',
      },
    },
    contained: {
      backgroundColor: palette.primary.main,

      '&.MuiLoadingButton-loading, &.Mui-disabled': {
        backgroundColor: '#151922',
        '& .MuiLoadingButton-loadingIndicatorCenter': {
          color: 'white',
        },
      },
    },
    outlined: {
      backgroundColor: 'transparent',
      border: `1px solid ${palette.common.white}`,
      padding: '.625rem 0',

      '&.MuiLoadingButton-loading, &.Mui-disabled': {
        border: '1px solid #151922',

        '& .MuiLoadingButton-loadingIndicatorCenter': {
          color: 'white',
        },
      },

      '&:hover': {
        border: '1px solid transparent',
      },
    },
    iconSizeMedium: {
      '& svg': {
        width: '1rem',
        height: '1rem',
      },
    },
  },
};
