import { styled } from 'theme';
import { Tabs as MuiTabs, tabsClasses } from '@mui/material';

export const Wrapper = styled('div')`
  padding-bottom: 0;
  border-radius: 4px;
`;

export const StyledTabs = styled(MuiTabs)`
  & .Mui-selected {
    color: ${(props) => props.theme.palette.common.white} !important;
  }

  & .${tabsClasses.fixed} {
    padding-bottom: 5px;
  }
`;
