import { styled } from 'theme';
import { WrapperProps } from './Checkbox.types';

export const Wrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isDisabled' && prop !== 'hasLabel',
})<WrapperProps>`
  display: flex;
  gap: 0.7rem;
  justify-content: start;
  align-items: center;
  width: max-content;
  min-height: ${(props) => (props.hasLabel ? '41px' : '0')};
  cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')};
`;
