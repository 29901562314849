import { configureStore } from '@reduxjs/toolkit';
import { rootReducer } from './rootReducer';
import { isDevOrTest } from 'portal.config';

export const makeStore = () =>
  configureStore({
    reducer: rootReducer,
    devTools: isDevOrTest,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
  });

export const store = makeStore();

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
