import React, { useMemo, useState } from 'react';
import { SelectChangeEvent } from '@mui/material';
import { CompanyDetailsForm } from '../CompanyDetailsForm/CompanyDetailsForm';
import { incorporationCountryOptions } from '../CompanyDetailsForm/CompanyDetailsForm.config';
import { TextField, Button, Select } from 'components';
import { useDispatch, useSelector } from 'hooks';
import { updateCompanyDetails } from 'store/thunks/companySliceThunk';
import { fieldDifference } from 'utils/formValidation';
import { companyDetailsSelector } from 'store/selectors/companyDetailsSelector';
import { appDataIndustryOptionsSelector } from 'store/selectors/appDataSelector';
import { useTranslate } from 'react-polyglot';
import { currencySignOrIsoCode } from 'utils/currencySignOrIsoCode';

const initialFormData = {
  companyName: '',
  lastYearRevenue: '',
  phoneNumber: '',
  incorporationCountry: '',
  industry: '',
};

type InputNames = keyof typeof initialFormData;

type Props = {
  propsFormData?: CompanyDetailsForm;
};

export const UpdateCompanyDetailsForm = ({ propsFormData }: Props) => {
  const dispatch = useDispatch();
  const t = useTranslate();
  const companyDetails = useSelector(companyDetailsSelector);
  const industryOpts = useSelector(appDataIndustryOptionsSelector);
  const [formData, setFormData] = useState({ ...initialFormData, ...propsFormData });
  const [fieldHelpers, setFieldHelpers] = useState(initialFormData);
  const [isLoading, setIsLoading] = useState(false);

  const currencyStringBasedOnCountry = useMemo(() => {
    return currencySignOrIsoCode(companyDetails?.currency ?? 'USD');
  }, [formData.incorporationCountry]);

  const setFieldHelperContent = (name: InputNames, content: string) => {
    setFieldHelpers((fieldHelpers) => ({ ...fieldHelpers, [name]: content }));
  };

  const handleValidateInput = (name: InputNames, value: string) => () => {
    switch (name) {
      case 'industry':
        if (value.length === 0) {
          setFieldHelperContent(name, 'Industry is mandatory!');
        }

        break;
      case 'companyName':
        if (value.length === 0) {
          setFieldHelperContent(name, 'Company name is mandatory!');
        }
        break;
      case 'lastYearRevenue':
        if (value.length === 0) {
          setFieldHelperContent(name, 'MRR is mandatory!');
        }
        break;
      case 'incorporationCountry':
        if (value.length === 0) {
          setFieldHelperContent(name, 'Your country of incorporation must be selected!');
        }
        break;
      case 'phoneNumber':
        if (value.length === 0) {
          setFieldHelperContent(name, 'You must provide a phone number!');
        }
        break;

      default:
        break;
    }
  };

  const formReadOnly = useMemo(() => {
    return Boolean(companyDetails?.kycLock);
  }, [companyDetails?.kycLock]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputName = e.target.name as InputNames;

    if (fieldHelpers[inputName]) {
      setFieldHelperContent(inputName, '');
    }
    setFormData((formData) => ({ ...formData, [inputName]: e.target.value }));
  };

  const handleSelectChange = (e: SelectChangeEvent<string | number>) => {
    const inputName = e.target.name as InputNames;

    if (fieldHelpers[inputName]) {
      setFieldHelperContent(inputName, '');
    }

    setFormData((formData) => ({ ...formData, [inputName]: e.target.value }));
  };

  const isFormValid = () => {
    const { companyName, incorporationCountry, lastYearRevenue, phoneNumber, industry } = formData;

    if (!propsFormData) return false;

    const {
      companyName: propsCompanyName,
      incorporationCountry: propsIncorporationCountry,
      lastYearRevenue: propsLastYearRevenue,
      phoneNumber: propsPhoneNumber,
      industry: propsIndustry,
    } = propsFormData;

    const isCompanyNameChanged = fieldDifference(companyName, propsCompanyName);
    const isCountryChanged = fieldDifference(incorporationCountry, propsIncorporationCountry);
    const isPhoneNumberChanged = fieldDifference(phoneNumber, propsPhoneNumber);
    const isLastYearRevenueChanged = fieldDifference(
      lastYearRevenue as string,
      propsLastYearRevenue as string,
    );
    const isIndustryChanged = fieldDifference(industry, propsIndustry);

    return (
      isCompanyNameChanged ||
      isCountryChanged ||
      isLastYearRevenueChanged ||
      isPhoneNumberChanged ||
      isIndustryChanged
    );
  };

  const handleFormSubmit = async (evt: React.FormEvent<HTMLFormElement>) => {
    setIsLoading(true);
    evt.preventDefault();

    await dispatch(updateCompanyDetails(formData));
    setIsLoading(false);
  };
  return (
    <form onSubmit={handleFormSubmit}>
      <TextField
        label='Company legal name'
        name='companyName'
        id='company'
        size='small'
        value={formData.companyName}
        onChange={handleInputChange}
        fullWidth
        disabled={formReadOnly}
        error={Boolean(fieldHelpers.companyName)}
        helperText={fieldHelpers.companyName}
        onBlur={handleValidateInput('companyName', formData.companyName)}
      />
      <Select
        id='incorporationCountry'
        name='incorporationCountry'
        label={'Incorporation country'}
        size='small'
        fullWidth
        options={incorporationCountryOptions}
        value={formData.incorporationCountry}
        onChange={handleSelectChange}
        disabled={formReadOnly}
        error={Boolean(fieldHelpers.incorporationCountry)}
        helperText={fieldHelpers.incorporationCountry}
        onBlur={handleValidateInput('incorporationCountry', formData.incorporationCountry)}
      />

      <Select
        label={'Industry'}
        size='small'
        id='industry'
        name='industry'
        disabled={formReadOnly}
        fullWidth
        options={industryOpts}
        value={formData.industry}
        onChange={handleSelectChange}
        error={Boolean(fieldHelpers.industry)}
        helperText={fieldHelpers.industry}
        onBlur={handleValidateInput('industry', formData.industry)}
      />

      <TextField
        id='lastYearRevenue'
        name='lastYearRevenue'
        type='number'
        label={`${t('companyDetails.lyr')} (${currencyStringBasedOnCountry})`}
        size={'small'}
        value={formData.lastYearRevenue}
        onChange={handleInputChange}
        fullWidth
        disabled={formReadOnly}
        error={Boolean(fieldHelpers.lastYearRevenue)}
        helperText={fieldHelpers.lastYearRevenue}
        onBlur={handleValidateInput('lastYearRevenue', `${formData.lastYearRevenue}`)}
      />
      <TextField
        label='Phone Number'
        name='phoneNumber'
        id='phoneNumber'
        size='small'
        type='number'
        fullWidth
        value={formData.phoneNumber}
        onChange={handleInputChange}
        disabled={formReadOnly}
        error={Boolean(fieldHelpers.phoneNumber)}
        helperText={fieldHelpers.phoneNumber}
        onBlur={handleValidateInput('phoneNumber', formData.phoneNumber)}
      />
      <TextField
        name='accountCurrency'
        label='Currency'
        value={companyDetails?.currency}
        disabled
        fullWidth
      />

      {formReadOnly ? null : (
        <Button
          type='submit'
          variant='contained'
          size='medium'
          disabled={!isFormValid()}
          isLoading={isLoading}
        >
          Submit
        </Button>
      )}
    </form>
  );
};
