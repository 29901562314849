import { Typography } from 'components';
import styles from './ApplicationStep.module.scss';
import { clsx } from 'clsx';
import { ApplicationStatus } from 'Portal Types';
import palette from 'theme/palette/palette';

type Props = {
  id: ApplicationStatus;
  name: string;
  marked: boolean;
  disabled?: boolean;
  index: number;
  changeViewMode?(view: ApplicationStatus): void;
};

export const ApplicationStep = ({
  name,
  marked,
  disabled = false,
  id,
  index,
  changeViewMode,
}: Props) => {
  const handleClick = () => {
    if (disabled) return;

    changeViewMode?.(id);
  };

  return (
    <div
      onClick={handleClick}
      className={clsx(styles.wrapper, {
        [styles.bold]: marked,
      })}
    >
      <div
        className={clsx(styles.marker, {
          [styles.marked]: marked,
        })}
      />
      <div className={styles.center}>
        <Typography
          className={clsx(styles.label, {
            [styles.bold]: marked,
            [styles.disabled]: disabled,
          })}
          variant='body1'
          sx={{ color: palette.common.white }}
        >
          {index}. {name}
        </Typography>
      </div>
    </div>
  );
};
