import { useEffect } from 'react';
import { Hub } from 'aws-amplify';
import { useAuth, useDispatch } from 'hooks';
import { getSelfData } from 'store/thunks/appDataSliceThunk';
import { CognitoUser } from 'Portal Types';
import { setUser } from 'store/slices/appUserSlice';
import { useNavigate } from 'react-router';

const useAuthHub = () => {
  const dispatch = useDispatch();
  const { checkUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    Hub.listen('auth', async ({ payload }) => {
      switch (payload.event) {
        case 'cognitoHostedUI':
          checkUser();
          break;
        case 'autoSignIn':
          await dispatch(
            getSelfData((payload.data as CognitoUser).signInUserSession.accessToken.jwtToken),
          );
          dispatch(setUser(payload.data as CognitoUser));
          navigate('/company-details', { replace: true });
          break;
        case 'autoSignIn_failure':
          navigate('/');
          break;
        default:
          break;
      }
    });
  }, []);
};

export default useAuthHub;
