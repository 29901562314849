/* eslint-disable */
export default {
  global: {
    firstName: 'First name',
    lastName: 'Last name',
    middleName: 'Middle name',
    username: 'Username',
    password: 'Password',
    newPassword: 'New password',
    confirmPassword: 'Confirm password',
    or: 'or',
    email: 'Email',
    confirm: 'Confirm',
    submit: 'Submit',
    continue: 'Continue',
  },
  application: {
    new_application: 'New application',
    financial_documents: 'Financial documents',
    upload_documents: 'Upload documents',
    waiting_offer: 'Waiting for offers',
    offer_selection: 'Select offer',
    contract_signing: 'Signing the contract',
    complete: 'Receive funds',
    banking_details: 'Banking Details',
    kyc_documents: 'KYC Documents',
    requested_offer: 'New offers requested',
    rejected: 'Application rejected',
  },
  signin: {
    signin: 'Log in',
    googleSignIn: 'Log in with google',
    forgotPassword: 'Forgot password?',
    noAccountNotice: "Don't have an account?",
  },
  signup: {
    signup: 'Sign up',
    googleSignUp: 'Sign up with Google',
    alreadyRegistered: 'Already have an account?',
    acceptMessage: 'By continue you agree to the',
    acceptCookies: 'Accept statistics cookies',
    requiredCookies: 'technical and functional cookies',
    termsAndConditions: 'Terms and conditions',
    paymentPlatforms: "Let's Get Your Cash Flowing",
  },
  forgotPassword: {
    forgotPassword: 'Forgot password',
    resetPassword: 'Reset password',
    instruction:
      'Please enter the email address that you have used for creating the account, we will send a confirmation code to reset the password',
  },
  newPassword: {
    title: 'Set your new password',
    confirmationCode: 'Confirmation code',
    confirmPassword: 'Confirm password',
  },
  confirmEmail: {
    title: 'Confirm email',
    guideA: 'Please check your inbox for the confirmation code',
    guideB: 'Did not receive a confirmation code?',
    resendCode: 'Resend confirmation code',
    confirmation: 'Confirmation code',
  },
  companyDetails: {
    legalName: 'Company legal name',
    incorpCountry: 'Incorporation country',
    phone: 'Phone number',
    industry: 'Industry',
    lyr: 'Last year revenue',
  },
  dashboard: {
    title: 'Applications',
    create: 'Create new application',
  },
  account: {
    title: 'Account',
    personalInfo: 'Personal info',
    security: 'Security settings',
    company: 'Company details',
    paymentPlatform: 'Payment platforms',
    bankingDetails: 'Banking details',
    financialPlatforms: 'Financial platforms',
    documents: 'Account documents',
  },
  chooseProduct: {
    title: 'Choose product',
    funding: 'How much funding do you need?',
    ibf: 'Invoice Based Financing',
    idDescription:
      'Client Sells an invoice with a discount and once they receive payment from customer they pay us back.',
    rbf: 'Revenue Based Financing',
    rbfDescription:
      'Client sells ARR at a discount for a certain tenor and pays back monthly fixed or variable payments',
  },
  waitingOffers: {
    notice: 'We have recieved your application and we will review it.',
    description:
      'Meanwhile, you can connect your payments or upload additional documents that will speed up our review process',
    connectPaymentsCta: 'Connect payments',
    uploadDocsCta: 'Upload additional documents',
  },
};
