import Box from '@mui/material/Box';
import { Typography, Button } from 'components';
import { WaitingScreen } from '../WaitingScreen';
import { useDispatch } from 'hooks';
import { showMimicButtons } from 'portal.config';
import { progressApplicationStep } from 'store/thunks/detailedApplicationSliceThunk';
import { SelectOfferIcon } from 'assets';
import { useTheme } from '@mui/material';
import { useTranslate } from 'react-polyglot';
import { ApplicationStatus } from 'Portal Types';
import { useState } from 'react';

type Props = {
  changeViewMode?(viewMode: ApplicationStatus): void;
};

export const WaitingForOffers = ({ changeViewMode }: Props) => {
  const t = useTranslate();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const provideOffers = async () => {
    setIsLoading(true);

    await dispatch(progressApplicationStep());

    setIsLoading(false);
  };

  const handleChangeViewMode = () => {
    changeViewMode?.('upload_documents');
  };

  return (
    <WaitingScreen title='Waiting for an offer!'>
      <SelectOfferIcon />
      <Typography variant='h2' fontWeight={600}>
        {t('waitingOffers.notice')}
      </Typography>

      <Typography
        variant='body1'
        fontWeight={18}
        sx={{ color: theme.palette.lightGrey.main, width: '80%', margin: '0 auto' }}
      >
        {t('waitingOffers.description')}
      </Typography>
      <Box width='70%' margin='0 auto' display='flex' flexDirection='column' gap='1rem'>
        <Button variant='outlined' disabled>
          {t('waitingOffers.connectPaymentsCta')}
        </Button>
        <Button variant='outlined' onClick={handleChangeViewMode}>
          {t('waitingOffers.uploadDocsCta')}
        </Button>
        {showMimicButtons && (
          <Button variant='contained' onClick={provideOffers} isLoading={isLoading}>
            Mimic next step(Development only)
          </Button>
        )}
      </Box>
    </WaitingScreen>
  );
};
