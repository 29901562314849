/* eslint-disable camelcase */
import { useEffect, useMemo, useState } from 'react';
import { Modal, PageTabs, FinancialPlatform, IconButton, Button } from 'components';
import { useDispatch, useSelector } from 'hooks';
import {
  closeFinancialPlatformModal,
  dropFinancialPlatformModalState,
} from 'store/slices/financialPlatformsSlice';
import { Wrapper, Typography, FlexWrapper } from './FinancialPlatformModal.styles';
import {
  financialPlatformsSelector,
  financialPlatformsTabSelector,
} from 'store/selectors/financialPlatformsSelector';
import {
  FinancialPlatformAvailability,
  FinancialPlatformConnectionType,
  LeanTechData,
} from 'types';
import {
  getConnectedLeanTechBanks,
  removeRequestedFinancialPlatformConnection,
  requestFinancialPlatformConnection,
  requestLeanTechConnection,
} from 'store/thunks/financialPlatformSliceThunk';
import { Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { CustomPlatform } from './components/CustomPlatform';
import { appDataSelfSelector } from 'store/selectors/appDataSelector';
import { bankingTab, navVals, navigationTabs } from './FinancialPlatformModal.config';
import { DefaultLeanTechPermissions } from 'Constants';
import { isDevOrTest } from 'portal.config';
import { useLeanTechCallback } from 'hooks/useLeanTechCallback';
import { useLocation } from 'react-router';
import { getIsLeanTechEnabled } from 'utils/getIsLeanTechEnabled';

type Props = {
  isOpen: boolean;
};

export const FinancialPlatformsModal = ({ isOpen }: Props) => {
  const dispatch = useDispatch();

  const { accountingConnections, paymentConnections } = useSelector(financialPlatformsSelector);
  const initialTab = useSelector(financialPlatformsTabSelector);
  const self = useSelector(appDataSelfSelector);
  const location = useLocation();

  const [tabsValue, setTabsValue] = useState(initialTab ?? 'payments');
  const [isLoadingCustomAccounting, setIsLoadingCustomAccounting] = useState(false);
  const [isLoadingCustomPayments, setIsLoadingCustomPayments] = useState(false);
  const [leanTechEnabled, setLeanTechEnabled] = useState(false);

  const { leanTechCallback, isLoading: isLeanTechLoading } = useLeanTechCallback();

  useEffect(() => {
    setLeanTechEnabled(getIsLeanTechEnabled());

    return () => {
      dispatch(dropFinancialPlatformModalState());
    };
  }, []);

  const handleRefetchFinancialPlatforms = (response: LeanTechCallbackResponse) => {
    if (response.status === 'SUCCESS') {
      handleCloseModal();
      setTimeout(() => {
        dispatch(getConnectedLeanTechBanks());
      }, 5000);
    }
  };

  const isOnboarding = useMemo(() => {
    return location.pathname === '/payment-platforms';
  }, [location.pathname]);

  const showLeanTechIntegration = useMemo(() => {
    return Boolean(self._links?.connectLeanTech) && leanTechEnabled;
  }, [self._links?.connectLeanTech, leanTechEnabled]);

  const tabOpts = useMemo(() => {
    if (showLeanTechIntegration) return [bankingTab, ...navigationTabs];

    return navigationTabs;
  }, [showLeanTechIntegration]);

  const handleCloseModal = () => {
    dispatch(closeFinancialPlatformModal());
  };

  const handleRequestConnection =
    (connectionType: FinancialPlatformConnectionType) => async (platformName: string) => {
      await dispatch(requestFinancialPlatformConnection({ platformName, connectionType }));
    };

  const handleCancelRequestedConnection =
    (connectionType: FinancialPlatformConnectionType) => async (platformName: string) => {
      await dispatch(removeRequestedFinancialPlatformConnection({ platformName, connectionType }));
    };

  const handleRequestCustomAccountingPlatform = async (platformName: string) => {
    setIsLoadingCustomAccounting(true);
    await dispatch(
      requestFinancialPlatformConnection({
        platformName,
        connectionType: FinancialPlatformConnectionType.ACCOUNTING,
      }),
    );
    setIsLoadingCustomAccounting(false);
  };

  const handleRequestCustomFinancialPlatform = async (platformName: string) => {
    setIsLoadingCustomPayments(true);
    await dispatch(
      requestFinancialPlatformConnection({
        platformName,
        connectionType: FinancialPlatformConnectionType.PAYMENT,
      }),
    );
    setIsLoadingCustomPayments(false);
  };

  const handleConnectLeanTech = async () => {
    const { payload } = await dispatch(requestLeanTechConnection());

    if (payload) {
      const { appToken, customerId } = payload as LeanTechData;
      window.Lean.connect({
        app_token: appToken,
        customer_id: customerId,
        permissions: DefaultLeanTechPermissions,
        sandbox: isDevOrTest,
        callback: isOnboarding ? leanTechCallback : handleRefetchFinancialPlatforms,
      });
    }
  };

  return (
    <Modal open={isOpen} onClose={handleCloseModal}>
      <Wrapper>
        <Box display='flex' justifyContent='space-between'>
          <Typography variant='h2'>Connect platforms</Typography>
          <IconButton onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>
        </Box>
        <PageTabs options={tabOpts} value={tabsValue} onChange={setTabsValue} />
        {tabsValue === navVals.accounting ? (
          <>
            {accountingConnections.available.length > 0 ? (
              <Typography variant='h4'>Available</Typography>
            ) : null}
            <FlexWrapper>
              {accountingConnections.available.map((platform) => (
                <FinancialPlatform
                  {...platform}
                  key={platform.platformName}
                  availability={FinancialPlatformAvailability.AVAILABLE}
                  border='light'
                />
              ))}
            </FlexWrapper>

            {accountingConnections.onDemand.length > 0 ? (
              <Typography variant='h4'>On demand</Typography>
            ) : null}
            <FlexWrapper>
              {accountingConnections.onDemand.map((platform) => (
                <FinancialPlatform
                  {...platform}
                  key={platform.platformName}
                  availability={FinancialPlatformAvailability.ON_DEMAND}
                  border='light'
                  onRequestConnection={handleRequestConnection(
                    FinancialPlatformConnectionType.ACCOUNTING,
                  )}
                  onCancelRequestedConnection={handleCancelRequestedConnection(
                    FinancialPlatformConnectionType.ACCOUNTING,
                  )}
                />
              ))}
              <CustomPlatform
                isLoading={isLoadingCustomAccounting}
                onSubmit={handleRequestCustomAccountingPlatform}
              />
            </FlexWrapper>
          </>
        ) : null}

        {tabsValue === navVals.payments ? (
          <>
            {paymentConnections.available.length > 0 ? (
              <Typography variant='h4'>Available</Typography>
            ) : null}
            <FlexWrapper>
              {paymentConnections.available.map((platform) => (
                <FinancialPlatform
                  {...platform}
                  key={platform.platformName}
                  border='light'
                  availability={FinancialPlatformAvailability.AVAILABLE}
                />
              ))}
            </FlexWrapper>

            {paymentConnections.onDemand.length > 0 ? (
              <Typography variant='h4'>On demand</Typography>
            ) : null}
            <FlexWrapper>
              {paymentConnections.onDemand.map((platform) => (
                <FinancialPlatform
                  {...platform}
                  key={platform.platformName}
                  border='light'
                  availability={FinancialPlatformAvailability.ON_DEMAND}
                  onRequestConnection={handleRequestConnection(
                    FinancialPlatformConnectionType.PAYMENT,
                  )}
                  onCancelRequestedConnection={handleCancelRequestedConnection(
                    FinancialPlatformConnectionType.PAYMENT,
                  )}
                />
              ))}
              <CustomPlatform
                isLoading={isLoadingCustomPayments}
                onSubmit={handleRequestCustomFinancialPlatform}
              />
            </FlexWrapper>
          </>
        ) : null}

        {tabsValue === navVals.banks ? (
          <Box height='calc(85vh - 173px)' display='flex' flexDirection='column'>
            <Typography variant='h4'>Instant bank verification</Typography>

            <Typography variant='body1'>
              No need to type in financial details or submit statements. We will fetch these from
              you.
            </Typography>
            <FlexWrapper>
              <CustomPlatform hideActions>
                <Typography variant='h4'>1. Choose your bank from the list</Typography>
              </CustomPlatform>

              <CustomPlatform hideActions>
                <Typography variant='h4'> 2. Sign in with your credentials</Typography>
              </CustomPlatform>

              <CustomPlatform hideActions>
                <Typography variant='h4'>
                  3. Authorize this application with the one time password sent to your device
                </Typography>
              </CustomPlatform>
            </FlexWrapper>
            <Box display='flex' justifyContent='center' marginTop='auto'>
              <Button
                variant='contained'
                sx={{ width: '100%' }}
                onClick={handleConnectLeanTech}
                isLoading={isLeanTechLoading}
              >
                Connect
              </Button>
            </Box>
          </Box>
        ) : null}
      </Wrapper>
    </Modal>
  );
};
