import { Components, Theme } from '@mui/material';

export const MuiList: Components<Theme>['MuiList'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      '& .MuiMenuItem-root': {
        color: theme.palette.common.white,
        minWidth: '12rem',
        padding: '10px 12px',

        '&.Mui-selected': {
          backgroundColor: '#171d2c !important',
        },

        '&:hover': {
          backgroundColor: theme.palette.darkBlue.light,
        },
        '&.Mui-selected:hover': {
          backgroundColor: '#2B3142 !important',
        },
      },
    }),
  },
};

export const MuiListSubheader: Components<Theme>['MuiListSubheader'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      backgroundColor: 'transparent',
      color: theme.palette.lightBlue.main,
      fontFamily: 'inherit',
      fontSize: '0.95rem',
    }),
  },
};

export const MuiMenuItem: Components['MuiMenuItem'] = {};

export const MuiListItemIcon: Components<Theme>['MuiListItemIcon'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      color: `${theme.palette.common.white} !important`,

      '& svg': {
        fill: `${theme.palette.common.white} !important`,
      },
    }),
  },
};
