import { Components } from '@mui/material';
import palette from 'theme/palette/palette';

export const MuiTabs: Components['MuiTabs'] = {
  styleOverrides: {
    root: {
      minHeight: 0,
    },
    indicator: {
      backgroundColor: palette.common.white,
    },
    fixed: {
      paddingBottom: 0,
    },
  },
};

export const MuiTab: Components['MuiTab'] = {
  defaultProps: {
    disableRipple: true,
  },
  styleOverrides: {
    root: {
      textTransform: 'capitalize',
      fontFamily: 'IBM Plex Sans',
      fontWeight: 500,
      fontSize: '14px',
      color: palette.lightBlue.main,
      lineHeight: 1,
      minHeight: 0,
    },
  },
};
