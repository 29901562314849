import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppErrorState, Application, RequestStatus } from 'Portal Types';
import { fetchCurrentApplications } from 'store/thunks/applicationsSliceThunk';
import { postApplicationChosenProduct } from 'store/thunks/detailedApplicationSliceThunk';

interface State {
  data: Application[];
  status?: RequestStatus;
  error: AppErrorState | null;
}

const initialState: State = {
  data: [],
  status: 'idle',
  error: null,
};

const applicationsSlice = createSlice({
  name: 'applicationsSlice',
  initialState,
  reducers: {
    setApplicationsError(state, action: PayloadAction<AppErrorState>) {
      state.error = action.payload;
    },
    updateApplications(state, action: PayloadAction<Application>) {
      state.data = state.data.map((application) =>
        application.id === action.payload.id
          ? { ...application, status: action.payload.status }
          : application,
      );
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchCurrentApplications.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(fetchCurrentApplications.fulfilled, (state, action) => {
      state.data = action.payload;
      state.status = 'finished';
    });
    builder.addCase(fetchCurrentApplications.rejected, (state, action) => {
      state.error = action.payload ?? null;
      state.status = 'finished';
    });
    builder.addCase(postApplicationChosenProduct.fulfilled, (state, action) => {
      state.data.push(action.payload);
    });
  },
});

export const { setApplicationsError, updateApplications } = applicationsSlice.actions;

export default applicationsSlice.reducer;
