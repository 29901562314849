/* eslint-disable camelcase */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppErrorState, BankData, DocumentRequirement, WithEmbedded } from 'Portal Types';
import { AxiosError } from 'axios';
import { appUserIdSelector, appUserSelector } from 'store/selectors/appUserSelector';
import axios from 'axiosConfig';
import { ServerResponse, ServerError } from 'types';
import { RootState } from 'store/store';
import { getRequestHeaders } from 'utils/getRequestHeaders';
import { CompanyDetailsForm } from 'components/forms/CompanyDetailsForm/CompanyDetailsForm';
import { addNotification } from 'store/slices/alertSlice';
import {
  CompanyDocumentsResponse,
  DocumentProvidedWithLinks,
  UploadDocumentPayload,
  CompanyDetailsWithLinks,
} from 'types/CompanyDetails.types';
import { setAreDocumentsUploading } from 'store/slices/companySlice';
import {
  companyDetailsSelector,
  isCompanyDocumentsUploadingSelector,
} from 'store/selectors/companyDetailsSelector';
import { v4 as uuid } from 'uuid';
import { baseAPIUrl } from 'portal.config';

export const fetchCompanyDetails = createAsyncThunk<
  CompanyDetailsWithLinks,
  undefined,
  {
    state: RootState;
    rejectValue: AppErrorState;
  }
>('companySlice/getCompanyDetails', async (_, { getState, rejectWithValue }) => {
  try {
    const store = getState();
    const userId = appUserIdSelector(store);

    const headers = getRequestHeaders(store);

    const response = (await axios.get(`/accounts/${userId}`, {
      headers,
    })) as ServerResponse<CompanyDetailsWithLinks>;

    return response.data;
  } catch (error) {
    const serverError = error as AxiosError<ServerError>;

    return rejectWithValue({ status: serverError?.response?.status ?? 1 });
  }
});

export const postCompanyDetails = createAsyncThunk<
  CompanyDetailsWithLinks,
  CompanyDetailsForm,
  {
    state: RootState;
    rejectValue: null;
  }
>('companySlice/setCompanyDetails', async (data, { rejectWithValue, getState, dispatch }) => {
  try {
    const store = getState();
    const headers = getRequestHeaders(store);

    const body = { ...data, lastYearRevenue: parseInt(data.lastYearRevenue as string) };

    const response = (await axios.post('/accounts', body, {
      headers,
    })) as ServerResponse<CompanyDetailsWithLinks>;

    return response.data;
  } catch (err) {
    const error = err as AxiosError<ServerError>;

    const accountDuplicate = error.response?.data.error.type === 'ACCOUNT_DUPLICATE';

    dispatch(
      addNotification({
        severity: 'error',
        message: accountDuplicate
          ? 'Company with this name is already registered for your country'
          : undefined,
      }),
    );
    return rejectWithValue(null);
  }
});

export const updateCompanyDetails = createAsyncThunk<
  CompanyDetailsWithLinks,
  CompanyDetailsForm,
  { state: RootState }
>('companySlice/updateCompanyDetails', async (data, { getState, dispatch, rejectWithValue }) => {
  try {
    const store = getState();

    const userId = appUserIdSelector(store);
    const headers = getRequestHeaders(store);
    const response = (await axios.patch(`/accounts/${userId}`, data, {
      headers,
    })) as ServerResponse<CompanyDetailsWithLinks>;

    dispatch(
      addNotification({
        severity: 'success',
        message: 'Company details saved successfully',
      }),
    );

    return response.data;
  } catch (error) {
    dispatch(
      addNotification({
        severity: 'error',
      }),
    );
    return rejectWithValue(null);
  }
});

export const getAccountDocuments = createAsyncThunk<
  CompanyDocumentsResponse,
  undefined,
  { state: RootState }
>('companySlice/getCompanyDocuments', async (_, { dispatch, rejectWithValue, getState }) => {
  try {
    const store = getState();
    const userId = appUserIdSelector(store);
    const headers = getRequestHeaders(store);

    const response = (await axios.get(`/accounts/${userId}/documents`, {
      headers,
    })) as ServerResponse<CompanyDocumentsResponse>;

    return response.data;
  } catch (error) {
    const serverError = error as AxiosError<ServerError>;
    dispatch(
      addNotification({
        severity: 'error',
        message: serverError.response?.data.error.description ?? '',
      }),
    );
    return rejectWithValue(null);
  }
});

export const uploadDocument = createAsyncThunk<
  DocumentProvidedWithLinks,
  UploadDocumentPayload,
  { state: RootState; rejectValue: DocumentProvidedWithLinks }
>('companySlice/uploadDocument', async (data, { dispatch, getState, rejectWithValue }) => {
  const store = getState();
  const user = appUserSelector(store);
  const headers = getRequestHeaders(store);
  const areDocumentsUploading = isCompanyDocumentsUploadingSelector(store);

  if ((data.startLoading && !areDocumentsUploading) || data.isSingleItem) {
    dispatch(setAreDocumentsUploading(true));
  }

  try {
    const formData = new FormData();

    formData.append('file', data.file);

    const response = (await axios.post('/documents/upload', formData, {
      headers,
    })) as ServerResponse<DocumentProvidedWithLinks>;

    if ((data.stopLoading && areDocumentsUploading) || data.isSingleItem) {
      dispatch(setAreDocumentsUploading(false));
    }

    return response.data;
  } catch (error) {
    const serverError = error as AxiosError<ServerError>;

    const message =
      serverError.response?.data.error.type === 'EMPTY_DOCUMENT'
        ? 'Cannot upload empty documents'
        : undefined;

    dispatch(
      addNotification({
        severity: 'error',
        message,
      }),
    );

    return rejectWithValue({
      id: uuid(),
      accountId: user?.username,
      bytes: data.file.size,
      client_filename: data.file.name,
      filename: data.file.name,
      failed: true,
      editable: false,
      type: 'other',
      file: data.file,
      requirementId: null,
      uploaded_at: '',
    } as DocumentProvidedWithLinks);
  } finally {
    if ((data.stopLoading && areDocumentsUploading) || data.isSingleItem) {
      dispatch(setAreDocumentsUploading(false));
    }
  }
});

export const retryDocumentUpload = createAsyncThunk<
  DocumentProvidedWithLinks & {
    oldDocumentId: string;
  },
  DocumentProvidedWithLinks,
  { state: RootState }
>('companySlice/retryUploadDocument', async (data, { dispatch, getState, rejectWithValue }) => {
  try {
    const store = getState();
    const headers = getRequestHeaders(store);

    const formData = new FormData();

    formData.append('file', data.file as File);

    const response = (await axios.post('/documents/upload', formData, {
      headers,
    })) as ServerResponse<DocumentProvidedWithLinks>;

    return { ...response.data, oldDocumentId: data.id };
  } catch (err) {
    dispatch(addNotification({ severity: 'error' }));
    return rejectWithValue(null);
  }
});

type DocumentProvidedWithRequirements = WithEmbedded<
  DocumentProvidedWithLinks,
  {
    requirements: Array<DocumentRequirement>;
  }
>;

export const updateUploadedDocument = createAsyncThunk<
  DocumentProvidedWithRequirements,
  { documentId: string; requirementId: string },
  { state: RootState }
>('companySlice/updateDocument', async (data, { dispatch, rejectWithValue, getState }) => {
  try {
    const store = getState();
    const headers = getRequestHeaders(store);

    const body: {
      requirementId: string;
    } = {
      requirementId: data.requirementId,
    };

    const response = (await axios.patch(`/documents/${data.documentId}`, body, {
      headers,
    })) as ServerResponse<DocumentProvidedWithLinks>;

    return response.data;
  } catch (error) {
    dispatch(
      addNotification({
        severity: 'error',
      }),
    );
    return rejectWithValue(null);
  }
});

export const deleteUploadedDocument = createAsyncThunk<string, string, { state: RootState }>(
  'companySlice/deleteDocument',
  async (id, { dispatch, rejectWithValue, getState }) => {
    try {
      const store = getState();
      const headers = getRequestHeaders(store);

      await axios.delete(`/documents/${id}`, { headers });

      dispatch(
        addNotification({
          severity: 'success',
          message: 'Document removed.',
        }),
      );

      return id;
    } catch (error) {
      dispatch(
        addNotification({
          severity: 'error',
        }),
      );
      return rejectWithValue(null);
    }
  },
);

export const downloadDocument = createAsyncThunk<
  unknown,
  DocumentProvidedWithLinks,
  { state: RootState }
>('companySlice/downloadDocument', async (selectedDoc, { getState, dispatch, rejectWithValue }) => {
  try {
    const store = getState();
    const headers = getRequestHeaders(store);

    fetch(`${baseAPIUrl}/documents/${selectedDoc.id}/content`, { headers })
      .then((res) => {
        return res.blob();
      })
      .then((blob) => {
        const documentUrl = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = documentUrl;
        anchor.download = selectedDoc.client_filename;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);

        URL.revokeObjectURL(documentUrl);
      });
  } catch (err) {
    dispatch(
      addNotification({
        severity: 'error',
      }),
    );
    return rejectWithValue(null);
  }
});

export const submitAccountBankingDetails = createAsyncThunk<
  BankData,
  BankData,
  { state: RootState }
>('companySlice/submitBankingDetails', async (data, { dispatch, rejectWithValue, getState }) => {
  try {
    const state = getState();
    const headers = getRequestHeaders(state);
    const accountId = companyDetailsSelector(state);

    const response = (await axios.put(`/accounts/${accountId?.id}/bank`, data, {
      headers,
    })) as ServerResponse<BankData>;

    dispatch(
      addNotification({
        severity: 'success',
        message: 'Bank details updated successfully',
      }),
    );

    return response.data;
  } catch (error) {
    dispatch(
      addNotification({
        severity: 'error',
      }),
    );
    return rejectWithValue(null);
  }
});
