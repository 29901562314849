import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/store';

export const applicationsRootStateSelector = (store: RootState) => store.applicationsSlice;

export const applicationsSelector = createSelector(
  applicationsRootStateSelector,
  (applications) => applications.data,
);

export const applicationsErrorSelector = createSelector(
  applicationsRootStateSelector,
  (applicationsRoot) => applicationsRoot.error,
);

export const applicationsStatusSelector = createSelector(
  applicationsRootStateSelector,
  (applications) => applications.status,
);
