import { Typography, Button } from 'components';
import { showMimicButtons } from 'portal.config';
import { WaitingScreen } from '../WaitingScreen';
import { ContractSignIcon } from 'assets';
import { useDispatch } from 'hooks';
import { progressApplicationStep } from 'store/thunks/detailedApplicationSliceThunk';
import { useState } from 'react';

type Props = {
  viewOnly: boolean;
};

export const ContractSigning = ({ viewOnly }: Props) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const progressStep = async () => {
    setIsLoading(true);

    await dispatch(progressApplicationStep());
    setIsLoading(false);
  };

  return (
    <WaitingScreen title='Sign contract'>
      <ContractSignIcon />
      <Typography variant='h2' fontWeight={600}>
        Your account manager will be in contact with you for contract sign
      </Typography>
      {showMimicButtons && !viewOnly && (
        <Button variant='contained' onClick={progressStep} isLoading={isLoading}>
          Mimic next step(Development only)
        </Button>
      )}
    </WaitingScreen>
  );
};
