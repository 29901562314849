import { Components } from '@mui/material';
import palette from 'theme/palette/palette';
import ReadexProPath from 'assets/fonts/ReadexPro-Medium.ttf';
import IBMPlexSansRegularPath from 'assets/fonts/IBMPlexSans-Regular.ttf';

export const MuiCssBaseline: Components['MuiCssBaseline'] = {
  styleOverrides: `
  @font-face {
    font-family: 'Readex Pro';
    font-weight: 400;
    font-style: normal;
    src: url(${ReadexProPath})
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-weight: 400;
    font-style: normal;
    src: url(${IBMPlexSansRegularPath})
  }

  a {
    text-decoration: none;
    color: ${palette.secondary.main};
  }
`,
};
