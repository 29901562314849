import { Typography } from 'components';
import { WaitingScreen } from '../WaitingScreen';
import { useSelector } from 'hooks';
import {
  detailedApplicationDataSelector,
  selectedOfferSelector,
} from 'store/selectors/detailedApplicationSelector';
import { formatNumber } from 'utils/formatNumber';
import { ReceiveFundsIcon } from 'assets';
import { currencySignOrIsoCode } from 'utils/currencySignOrIsoCode';

export const ReceiveFunds = () => {
  const selectedOffer = useSelector(selectedOfferSelector);
  const application = useSelector(detailedApplicationDataSelector);
  return (
    <WaitingScreen title='Receive Funds'>
      <ReceiveFundsIcon />
      <Typography variant='h2' fontWeight={600}>
        Congrats! Your application was approved and {formatNumber(selectedOffer?.loanAmount ?? 0)}{' '}
        {currencySignOrIsoCode(application.currency)} was transfered to your account
      </Typography>
    </WaitingScreen>
  );
};
