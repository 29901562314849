import { useState, useMemo } from 'react';
import { Box } from '@mui/material';
import { Typography, SelectableButton, Button } from 'components';
import { largeImages, mappedIcons } from './FinancialPlatform.config';
import { Props } from './FinancialPlatform.types';
import { FinancialPlatformAvailability } from 'types';
import CheckIcon from '@mui/icons-material/Check';

export const FinancialPlatform = ({
  border,
  status,
  isCompact = false,
  availability,
  connectionId,
  platformName,
  onConnect,
  onDisconnect,
  onRequestConnection,
  onCancelRequestedConnection,
}: Props) => {
  const [isConnecting, setIsConnecting] = useState(false);
  const [isDisconecting, setIsDisconecting] = useState(false);

  const isAvailable = useMemo(() => {
    return availability === FinancialPlatformAvailability.AVAILABLE;
  }, [availability]);

  const isActive = useMemo(() => {
    return status === 'Linked' || status === 'Requested';
  }, [status]);

  const handleConnect = async () => {
    if (isActive) return;
    setIsConnecting(true);
    await onConnect?.(platformName);
    setIsConnecting(false);
  };

  const handleDisconnect = async () => {
    if (!connectionId) return;
    setIsDisconecting(true);
    await onDisconnect?.(connectionId);
    setIsDisconecting(false);
  };

  const handleRequestConnection = async () => {
    if (isActive) return;
    setIsConnecting(true);
    await onRequestConnection?.(platformName);
    setIsConnecting(false);
  };

  const handleCancelRequestedConnection = async () => {
    setIsDisconecting(true);
    await onCancelRequestedConnection?.(platformName);
    setIsDisconecting(false);
  };

  const imageWidth = useMemo(() => {
    const isLarge = largeImages.includes(platformName);

    return isLarge ? '3rem' : '2rem';
  }, [platformName]);

  return (
    <Box
      display='flex'
      justifyContent='space-between'
      alignItems='center'
      border={'2px solid'}
      borderColor={border === 'light' ? 'lightBlue.light' : 'darkBlue.light'}
      padding='.5rem'
      borderRadius='.5rem'
      width='100%'
    >
      <Box display='flex' alignItems='center' gap='2rem'>
        <Box
          minHeight='3rem'
          minWidth='9rem'
          display='flex'
          justifyContent='center'
          alignItems='center'
          bgcolor={'darkBlue.main'}
          padding='1.2rem 1.9rem'
          borderRadius={'.5rem'}
        >
          {mappedIcons[platformName as keyof typeof mappedIcons] ? (
            <img
              style={{ width: imageWidth }}
              src={mappedIcons[platformName as keyof typeof mappedIcons]}
              alt={platformName}
            />
          ) : (
            <Typography variant='h4'>Custom</Typography>
          )}
        </Box>
        <Typography variant='h4'>{platformName}</Typography>
      </Box>
      <Box display='flex' alignItems='center' gap='2rem'>
        {isActive ? (
          isCompact ? (
            <CheckIcon sx={{ fill: 'white', width: '2rem' }} />
          ) : (
            <Button
              onClick={isAvailable ? handleDisconnect : handleCancelRequestedConnection}
              variant='text'
              sx={{ color: 'primary.main', maxWidth: '12rem' }}
              isLoading={isDisconecting}
            >
              {isAvailable ? 'Disconnect' : 'Cancel'}
            </Button>
          )
        ) : null}
        {isCompact ? null : (
          <SelectableButton
            selected={isActive}
            label={isAvailable ? 'Connect' : 'Request'}
            selectedLabel={isAvailable ? 'Connected' : 'Requested'}
            sx={{ width: '12rem', minWidth: '12rem' }}
            onClick={isAvailable ? handleConnect : handleRequestConnection}
            isLoading={isConnecting}
          />
        )}
      </Box>
    </Box>
  );
};
