import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CognitoUser } from 'Portal Types';

interface UserState {
  user: null | CognitoUser;
}

const initialState: UserState = {
  user: null,
};

export const UserSlice = createSlice({
  name: 'userSlice',
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<CognitoUser | null>) {
      state.user = action.payload;
    },
    dropUser(state) {
      state.user = null;
    },
  },
});

export const { setUser, dropUser } = UserSlice.actions;

export default UserSlice.reducer;
