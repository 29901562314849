import { Components, Theme } from '@mui/material';
import palette from 'theme/palette/palette';

export const MuiSelect: Components<Theme>['MuiSelect'] = {
  defaultProps: { size: 'small' },
  styleOverrides: {
    select: {
      color: palette.common.white,
      backgroundColor: '#171D2C',
      borderRadius: '.5rem !important',
      fontSize: '.9rem',
      padding: '.625rem .75rem',

      '&:hover': {
        backgroundColor: '#2b3142',
      },

      '&.Mui-disabled': {
        color: '#4D4D4D',
        textFillColor: '#4D4D4D',
        backgroundColor: '#151922',

        '&:hover': {
          backgroundColor: '#2b3142',
        },
      },
    },

    icon: {
      fill: '#aaa',

      '&.Mui-disabled': {
        fill: '#4D4D4D',
      },
    },
  },
};
