import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import axios from 'axiosConfig';
import { AppErrorState, Application } from 'Portal Types';
import { RootState } from 'store/store';
import { ServerError } from 'types';
import { getRequestHeaders } from 'utils/getRequestHeaders';

export const fetchCurrentApplications = createAsyncThunk<
  Application[],
  undefined,
  {
    state: RootState;
    rejectValue: AppErrorState;
  }
>('applicationSlice/getCurrentApplications', async (_, { getState, rejectWithValue }) => {
  try {
    const store = getState();
    const headers = getRequestHeaders(store);

    const response = await axios.get('/applications', {
      headers,
    });

    return response.data.items as Application[];
  } catch (error) {
    const serverError = error as AxiosError<ServerError>;

    return rejectWithValue({ status: serverError.response?.status ?? 1 });
  }
});
