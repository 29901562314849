import { useMemo } from 'react';
import { Loader, Sidebar, Typography, ApplicationStatusIndicator } from 'components';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { ApplicationStep } from './ApplicationStep/ApplicationStep';
import { detailedApplicationDataSelector } from 'store/selectors/detailedApplicationSelector';
import { ApplicationStatus } from 'Portal Types';
import { useTranslate } from 'react-polyglot';

type Props = {
  applicationStatusIndex?: number;
  viewMode: ApplicationStatus;
  isLoading?: boolean;
  filteredApplicationViews: readonly { id: ApplicationStatus; name: string }[];
  changeViewMode(to: ApplicationStatus): void;
};

export const ApplicationSteps = ({
  applicationStatusIndex = -1,
  viewMode,
  isLoading,
  filteredApplicationViews,
  changeViewMode,
}: Props) => {
  const t = useTranslate();
  const application = useSelector(detailedApplicationDataSelector);

  const currentPathIndex = useMemo(
    () => filteredApplicationViews.findIndex((route) => viewMode === route.id),
    [applicationStatusIndex, viewMode],
  );

  return (
    <Sidebar>
      <Box
        width='100%'
        height='100%'
        borderRight='1px solid rgba(255,255,255, 0.15)'
        display='flex'
        flexDirection='column'
        paddingLeft={5}
        paddingTop={4}
        gap={2}
      >
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <Typography variant='h6'>{application.name}</Typography>
            <Box>
              <ApplicationStatusIndicator status={t(`application.${application.status}`)} />
            </Box>
            <Box>
              <Typography variant='caption'>Application progress</Typography>
              {filteredApplicationViews.map((route, idx) => (
                <ApplicationStep
                  index={idx + 1}
                  id={route.id}
                  name={route.name}
                  key={route.id}
                  marked={idx <= currentPathIndex}
                  disabled={applicationStatusIndex < idx}
                  changeViewMode={changeViewMode}
                />
              ))}
            </Box>
          </>
        )}
      </Box>
    </Sidebar>
  );
};
