import { createSelector } from '@reduxjs/toolkit';
import { DocumentProvided, DocumentRequirement } from 'Portal Types';
import { RootState } from 'store/store';

export const detailedApplicationRootSelector = (state: RootState) =>
  state.detailedApplicationsSlice;

export const detailedApplicationStatusSelector = createSelector(
  detailedApplicationRootSelector,
  (detailedApplication) => detailedApplication.status,
);

export const detailedApplicationRequestStatusSelector = createSelector(
  detailedApplicationRootSelector,
  (applicationRoot) => applicationRoot.status,
);

export const detailedApplicationErrorSelector = createSelector(
  detailedApplicationRootSelector,
  (applicationRoot) => applicationRoot.error,
);

export const detailedApplicationDataSelector = createSelector(
  detailedApplicationRootSelector,
  (detailedApplication) => detailedApplication.data,
);

export const detailedApplicationIdSelector = createSelector(
  detailedApplicationRootSelector,
  (detailedApplication) => detailedApplication.data.id,
);

export const isDetailedApplicationLoadingSelector = createSelector(
  detailedApplicationRootSelector,
  (detailedApplication) => detailedApplication.isLoading,
);

export const selectedOfferSelector = createSelector(
  detailedApplicationDataSelector,
  (applicationData) => {
    const selectedOfferId = applicationData.offerId;

    return applicationData.offers.find((offer) => offer.id === selectedOfferId);
  },
);

export const detailedapplicationEmbeddedSelector = createSelector(
  detailedApplicationDataSelector,
  (applicationData) => applicationData._embedded,
);

export const applicationDocumetsSelector = createSelector(
  detailedapplicationEmbeddedSelector,
  (embedded) => embedded?.documents ?? [],
);

export const detailedApplicationDocumentsSelector = createSelector(
  detailedapplicationEmbeddedSelector,
  (embedded) => embedded?.documents ?? ([] as Array<DocumentProvided>),
);

export const detailedApplicationRequirementsSelector = createSelector(
  detailedapplicationEmbeddedSelector,
  (embedded) => embedded?.requirements ?? ([] as Array<DocumentRequirement>),
);

export const applicationFinancialDocumentsSelector = createSelector(
  detailedapplicationEmbeddedSelector,
  (embedded) => {
    const { documents, requirements } = embedded ?? { documents: [], requirements: [] };

    const riskDocuments = requirements
      .filter((requirement) => requirement.category === 'RISK')
      .map((requirement) => requirement.requirement_id);

    return documents.filter(
      (document) => !document.requirementId || riskDocuments.includes(document.requirementId),
    );
  },
);

export const applicationKycDocumentsSelector = createSelector(
  detailedapplicationEmbeddedSelector,
  (embedded) => {
    const { documents, requirements } = embedded ?? { documents: [], requirements: [] };

    const kycRequirementIds = requirements
      .filter((requirement) => requirement.category === 'KYC')
      .map((requirement) => requirement.requirement_id);

    return documents.filter(
      (document) => !document.requirementId || kycRequirementIds.includes(document.requirementId),
    );
  },
);

export const applicationDocumentRequirementsSelector = createSelector(
  detailedapplicationEmbeddedSelector,
  (companyDocuments) => companyDocuments?.requirements ?? [],
);

export const applicationKycRequirementsSelector = createSelector(
  applicationDocumentRequirementsSelector,
  (requirements) => requirements.filter((requirement) => requirement.category === 'KYC'),
);

export const applicationFinancialRequirementsSelector = createSelector(
  applicationDocumentRequirementsSelector,
  (requirements) => requirements.filter((requirement) => requirement.category === 'RISK'),
);

export const areAllFinancialRequirementsMarkedSelector = createSelector(
  applicationFinancialRequirementsSelector,
  (financialRequirements) =>
    financialRequirements.every((requirement) => requirement.provided || requirement.isOptional),
);

export const areAllKYCRequirementsMarkedSelector = createSelector(
  applicationKycRequirementsSelector,
  (kycRequirements) =>
    kycRequirements.every((requirement) => requirement.provided || requirement.isOptional),
);

export const areAllDocumentsProvidedSelector = createSelector(
  detailedApplicationRequirementsSelector,
  (documents) => documents.every((document) => document.provided || document.isOptional),
);
