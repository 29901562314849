import { MuiTextField } from './MuiTextField';
import { MuiTypography } from './MuiTypography';
import { MuiSelect } from './MuiSelect';
import { MuiList, MuiMenuItem, MuiListItemIcon, MuiListSubheader } from './MuiMenu';
import { MuiButton } from './MuiButton';
import { MuiIconButton } from './MuiIconButton';
import { MuiCssBaseline } from './MuiCssBaseline';
import { MuiRadio } from './MuiRadio';
import { MuiTooltip } from './MuiTooltip';
import { MuiTab, MuiTabs } from './MuiTabs';
import { MuiCheckbox } from './MuiCheckbox';

const components = {
  MuiTab,
  MuiCheckbox,
  MuiTabs,
  MuiIconButton,
  MuiTextField,
  MuiTypography,
  MuiSelect,
  MuiList,
  MuiMenuItem,
  MuiButton,
  MuiRadio,
  MuiCssBaseline,
  MuiListItemIcon,
  MuiTooltip,
  MuiListSubheader,
};

export default components;
