import { Box } from '@mui/material';
import { Button, SelectableButton, TextField, Typography } from 'components';
import { useState } from 'react';

type Props = {
  children?: React.ReactNode;
  hideActions?: boolean;
  isLoading?: boolean;
  onSubmit?(inputValue: string): void | Promise<void>;
};

export const CustomPlatform = ({ isLoading, hideActions, children, onSubmit }: Props) => {
  const [isRequesting, setIsRequesting] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const handleClose = () => {
    setIsRequesting(false);
  };

  const handleOpen = () => {
    setIsRequesting(true);
  };

  const handleRequest = async () => {
    await onSubmit?.(inputValue);
    handleClose();
  };

  return (
    <Box
      display='flex'
      justifyContent='space-between'
      alignItems='center'
      border={'2px solid'}
      borderColor={'lightBlue.light'}
      padding='.5rem'
      paddingLeft='1rem'
      borderRadius='.5rem'
      width='100%'
      minHeight='5rem'
    >
      {hideActions ? (
        children
      ) : isRequesting ? (
        <TextField
          placeholder='Type your preferred platform'
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
        />
      ) : (
        <Typography variant='h4'>Haven’t found your preferred payment platform?</Typography>
      )}
      {hideActions ? null : (
        <Box display='flex' gap='1rem'>
          {isRequesting ? (
            <Button
              variant='text'
              sx={{ color: 'primary.main', maxWidth: '12rem' }}
              onClick={handleClose}
              isLoading={isLoading}
            >
              Cancel
            </Button>
          ) : null}
          <SelectableButton
            variant='outlined'
            sx={{ width: '12rem', minWidth: '12rem' }}
            onClick={isRequesting ? handleRequest : handleOpen}
            isLoading={isLoading}
            disabled={isRequesting && inputValue.length < 1}
          >
            Request
          </SelectableButton>
        </Box>
      )}
    </Box>
  );
};
