import { Auth } from 'aws-amplify';
import axios from 'axios';
import axiosRetry from 'axios-retry';
import { baseAPIUrl } from 'portal.config';
import { setGlobalLoading } from 'store/slices/appDataSlice';
import { setUser } from 'store/slices/appUserSlice';

const axiosClient = axios.create({
  baseURL: baseAPIUrl,
});

axiosRetry(axiosClient, { retries: 3 });

axiosClient.interceptors.response.use(
  async (response) => {
    return response;
  },
  async (error) => {
    if (error.response.status === 401) {
      await Auth.signOut();
      const { store } = await import('store/store');
      store.dispatch(setUser(null));
      store.dispatch({ type: 'logout/clear-state' });
      store.dispatch(setGlobalLoading(false));
      window.location.assign('/');
    } else {
      return Promise.reject(error);
    }
  },
);

export default axiosClient;
