import { useEffect, useMemo } from 'react';
import { CircularProgress, Box } from '@mui/material';
import { useAuth, useSelector } from 'hooks';
import { Authentication, Content, ErrorPage } from 'pages';
import { appUserSelector } from 'store/selectors/appUserSelector';
import { Button, NotificationContainer, FinancialPlatformsModal } from 'components';
import {
  appDataErrorSelector,
  appDataLinksSelector,
  globalLoadingSelector,
} from 'store/selectors/appDataSelector';
import { companyDetailsErrorSelector } from 'store/selectors/companyDetailsSelector';
import { applicationsErrorSelector } from 'store/selectors/applicationSelector';
import { showCodatIntegration, enableIntercom } from 'portal.config';
import useAuthHub from 'hooks/useAuthHub';
import { isFinancialPlatformModalOpenSelector } from 'store/selectors/financialPlatformsSelector';
import { useIntercom } from 'react-use-intercom';


function App() {
  useAuthHub();
  const { checkUser } = useAuth();
  const user = useSelector(appUserSelector);
  const appLinks = useSelector(appDataLinksSelector);
  const isLoading = useSelector(globalLoadingSelector);
  const appDataError = useSelector(appDataErrorSelector);
  const applicationsError = useSelector(applicationsErrorSelector);
  const companyDetailsError = useSelector(companyDetailsErrorSelector);
  const isFinancialPlatformModalOpen = useSelector(isFinancialPlatformModalOpenSelector);
  const { boot, shutdown } = useIntercom();

  const isOnboardingPassed = useMemo(() => {
    if (showCodatIntegration) {
      return user && !appLinks?.createAccount && !appLinks?.createFinancialPlatforms;
    } else {
      return Boolean(user) && !appLinks?.createAccount;
    }
  }, [user, appLinks, showCodatIntegration]);

  useEffect(() => {
    checkUser();
    localStorage.setItem('leantech', 'true');
  }, []);

  useEffect(() => {
    if (enableIntercom) {
      boot();
    }

    return () => shutdown();
  }, [enableIntercom]);

  if (appDataError || applicationsError || companyDetailsError) {
    const handleRetry = () => {
      window.location.assign('/');
    };

    return (
      <ErrorPage>
        <Button variant='outlined' sx={{ maxWidth: '12rem' }} onClick={handleRetry}>
          Try Again
        </Button>
      </ErrorPage>
    );
  }

  if (isLoading) {
    return (
      <Box width='100vw' height='100vh' display='flex' justifyContent='center' alignItems='center'>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <NotificationContainer />
      {isOnboardingPassed ? <Content /> : <Authentication />}
      {isFinancialPlatformModalOpen ? (
        <FinancialPlatformsModal isOpen={isFinancialPlatformModalOpen} />
      ) : null}
    </>
  );
}

export default App;
