import { Components } from '@mui/material';
import palette from 'theme/palette/palette';

export const MuiCheckbox: Components['MuiCheckbox'] = {
  defaultProps: {
    disableRipple: true,
    disableFocusRipple: true,
    size: 'medium',
  },
  styleOverrides: {
    root: {
      padding: 0,
      fill: palette.common.white,
      color: `${palette.common.white} !important`,
      '&.Mui-checked': {
        color: `${palette.common.white} !important`,
        '& .MuiSvgIcon-root': {
          fill: `${palette.common.white} !important`,
          color: `${palette.common.white} !important`,
        },
      },
    },
  },
};
