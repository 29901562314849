import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import styles from './AuthLayout.module.scss';
import { useDispatch, useSelector } from 'hooks';
import { defaultRoute } from 'Constants';
import { setPersistedRoute } from 'store/slices/appDataSlice';
import { appUserSelector } from 'store/selectors/appUserSelector';
import { clsx } from 'clsx';
import { useMediaQuery, useTheme } from '@mui/material';
import { appDataLinksSelector } from 'store/selectors/appDataSelector';
import { showCodatIntegration } from 'portal.config';

type Props = {
  children?: React.ReactNode;
};

const AuthLayout = (props: Props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const user = useSelector(appUserSelector);
  const appLinks = useSelector(appDataLinksSelector);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  useEffect(() => {
    if (user) {
      navigate('/company-details');
    }
    if (appLinks?.createFinancialPlatforms && showCodatIntegration) {
      navigate('/payment-platforms');
    }
  }, [
    appLinks?.createAccount?.href,
    appLinks?.createFinancialPlatforms?.href,
    showCodatIntegration,
  ]);

  useEffect(() => {
    if (location.pathname !== defaultRoute) {
      dispatch(setPersistedRoute(location.pathname));
    }
  }, []);

  return (
    <div className={styles.wrapper}>
      {<img src={'/logo.svg'} alt='logo image' className={styles.image} />}
      <div className={clsx(styles.half, styles.leftHalf)} />
      <div className={clsx(styles.half, styles.rightHalf)} />
      <div
        className={clsx(styles.contentBox, {
          [styles.mobile]: !matches,
        })}
      >
        {props.children ? props.children : <Outlet />}
      </div>
    </div>
  );
};

export default AuthLayout;
