import { Typography, CompanyDetailsForm, HorizontalProgress } from 'components';
import { useTranslate } from 'react-polyglot';
import { authSteps } from '../Authentication.config';

const CompanyDetailsFormPage = () => {
  const t = useTranslate();

  return (
    <div>
      <Typography variant='h1'>{t('signup.signup')}</Typography>
      <HorizontalProgress options={authSteps} highlightOptions={2} />
      <CompanyDetailsForm />
    </div>
  );
};

export default CompanyDetailsFormPage;
