import { styled } from '@mui/material';
import { Button } from 'components';

export const Wrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '.5rem',
  marginTop: '1rem',
});

const gridRowStyles = {
  display: 'grid',
  gap: '1rem',
  alignItems: 'center',
};

interface GridListProps {
  isRbf?: boolean;
}

export const ListHeader = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isRbf',
})<GridListProps>(({ theme, isRbf }) => ({
  padding: '.7rem',
  backgroundColor: theme.palette.darkBlue.main,
  fontSize: '0.875rem',
  color: theme.palette.lightGrey.main,
  gridTemplateColumns: `repeat(${isRbf ? 5 : 5}, 1fr)`,
  ...gridRowStyles,
  '& p': {
    color: theme.palette.lightGrey.main,
  },
}));

export const ListItem = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isRbf',
})<GridListProps>(({ theme, isRbf }) => ({
  padding: '.7rem',
  backgroundColor: theme.palette.darkBlue.main,
  gridTemplateColumns: `repeat(${isRbf ? 5 : 5}, 1fr)`,
  ...gridRowStyles,

  '& p': {
    color: theme.palette.common.white,
  },
}));

export const CardItem = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.darkBlue.main,
  borderRadius: `${theme.shape.borderRadius}px`,
  padding: '1.5rem',
  display: 'grid',
  gridTemplateColumns: '1fr max-content',
  gridAutoRows: '1fr',
  gap: '1rem',
  alignItems: 'center',

  '& > .justifyRight': {
    justifySelf: 'right',
  },
}));

export const SelectedButton = styled(Button, { shouldForwardProp: (prop) => prop !== 'selected' })<{
  selected: boolean;
  flat?: boolean;
}>(({ theme, selected, flat }) => ({
  backgroundColor: selected ? theme.palette.common.white : undefined,
  color: selected ? theme.palette.primary.main : undefined,
  gridColumn: flat ? '1/-1' : undefined,

  '&:hover': {
    backgroundColor: selected ? theme.palette.common.white : undefined,
  },

  '& svg': {
    fill: theme.palette.primary.main,
  },
}));
