import React from 'react';
import { FormControl, MenuItem, SelectChangeEvent, Box, ListSubheader } from '@mui/material';
import type { SelectProps } from '.';
import { StyledSelect } from './Select.styles';
import { Typography } from 'components';
import styles from './Select.module.scss';
import DoneIcon from '@mui/icons-material/Done';
import { clsx } from 'clsx';
import { OptionType } from './Select.types';

const Select: React.FC<SelectProps> = ({
  options,
  groupedOptions,
  grouped,
  label,
  id,
  value,
  helperText,
  onChange,
  ...props
}) => {
  const renderOptions = () => {
    return grouped
      ? groupedOptions?.map((grouped) => [
          <ListSubheader key={grouped.name}>{grouped.name}</ListSubheader>,
          ...grouped.options.map((option) => (
            <MenuItem
              key={option.value}
              disabled={option.disabled}
              value={`${grouped.name}:${option.value}`}
              disableRipple
              className={clsx(styles.listItem, styles.indented)}
            >
              {option.name}
              {option.value === value && <DoneIcon />}
            </MenuItem>
          )),
        ])
      : options?.map((option) => (
          <MenuItem
            key={option.value}
            disabled={option.disabled}
            value={option.value}
            disableRipple
            className={styles.listItem}
          >
            {option.name}
            {option.value === value && <DoneIcon />}
          </MenuItem>
        ));
  };

  const renderValue = (selectedValue: unknown) => {
    if (grouped) {
      let options: OptionType[] = [];
      groupedOptions?.forEach((group) => (options = [...options, ...group.options]));

      const selectedOption = options.find((option) => option.value === selectedValue);

      return selectedOption?.name;
    }

    const selectedOption = options?.find((option) => option.value === selectedValue);

    return selectedOption?.name;
  };

  const delegateHandleChange = (evt: SelectChangeEvent<unknown>) => {
    onChange?.(evt as SelectChangeEvent<string | number>);
  };

  return (
    <FormControl fullWidth={props.fullWidth} size={props.size}>
      {label && (
        <Box marginBottom={'.4rem'}>
          <Typography fontSize='14px' lineHeight={1} sx={{ color: '#aaa' }}>
            {label}
          </Typography>
        </Box>
      )}
      <StyledSelect
        {...props}
        id={id}
        value={value}
        renderValue={renderValue}
        onChange={delegateHandleChange}
        MenuProps={{
          PaperProps: {
            className: styles.paper,
          },
        }}
      >
        {renderOptions()}
      </StyledSelect>
      {helperText && (
        <Box mt={'.3rem'}>
          <Typography fontSize='13px' lineHeight={1} className={styles.error}>
            {helperText}
          </Typography>
        </Box>
      )}
    </FormControl>
  );
};

export default Select;
