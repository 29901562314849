import { Components, Theme } from '@mui/material';

const MuiRadio: Components<Theme>['MuiRadio'] = {
  styleOverrides: {
    colorPrimary: ({ theme }) => ({
      '& svg': {
        fill: theme.palette.common.white,
      },
    }),
  },
};

export { MuiRadio };
