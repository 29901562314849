export const navigationTabs = [
  { label: 'Payments', value: 'payments' },
  { label: 'Accounting', value: 'accounting' },
];

export const bankingTab = { label: 'Banks', value: 'banks' };

export const navVals = {
  payments: 'payments',
  accounting: 'accounting',
  banks: 'banks',
};
