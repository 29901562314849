import { AnyAction, combineReducers } from '@reduxjs/toolkit';
import userStateReducer from './slices/appUserSlice';
import appDataReducer from './slices/appDataSlice';
import alertSliceReducer from './slices/alertSlice';
import applicationsReducer from './slices/applicationsSlice';
import companyReducer from './slices/companySlice';
import detailedApplicationReducer from './slices/detailedApplicationSlice';
import financialPlatformsReducer from './slices/financialPlatformsSlice';

const appReducer = combineReducers({
  userSlice: userStateReducer,
  appDataSlice: appDataReducer,
  alertSlice: alertSliceReducer,
  applicationsSlice: applicationsReducer,
  companySlice: companyReducer,
  detailedApplicationsSlice: detailedApplicationReducer,
  financialPlatformsSlice: financialPlatformsReducer,
});

export type RootState = ReturnType<typeof appReducer>;

export const rootReducer = (state: RootState | undefined, action: AnyAction) => {
  if (action.type === 'logout/clear-state') {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};
