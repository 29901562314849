import { Typography } from 'components';
import { ApplicationOffer } from 'Portal Types';
import { idFields, rbfFields } from './OfferList.config';
import { useSelector } from 'hooks';
import { detailedApplicationDataSelector } from 'store/selectors/detailedApplicationSelector';
import { useMemo } from 'react';
import DoneIcon from '@mui/icons-material/Done';
import { ListHeader, ListItem, Wrapper, SelectedButton, CardItem } from './OfferList.styles';
import { formatNumber } from 'utils/formatNumber';
import { Theme, useMediaQuery, useTheme } from '@mui/material';
import { formatPercentage } from './OfferList.util';
import { currencySignOrIsoCode } from 'utils/currencySignOrIsoCode';

type OfferItemProps = {
  offer: ApplicationOffer;
  selected: boolean;
  disabled?: boolean;
  theme?: Theme;
  onSelect(id: string): void;
};
const formatCurrency = (amount: number, currency: string) => 
  `${currencySignOrIsoCode(currency)} ${formatNumber(amount)}`;

const ListOfferItem = ({ offer, selected, disabled, onSelect }: OfferItemProps) => {
  const handleSelect = () => {
    onSelect?.(offer.id);
  };

  return (
    <ListItem isRbf={offer.type === 'RBF'}>
      <Typography variant='h2' fontWeight={600}>{formatCurrency(offer.loanAmount, offer.currency)}</Typography>
      <Typography variant='h2' fontWeight={600}>{formatCurrency(offer.repaymentAmount, offer.currency)}</Typography>
      <Typography variant='body1'>{formatPercentage(offer.monthlyRate)}</Typography>
      <Typography variant='body1'>{offer.tenor} {offer.type === 'ID' ? 'Day' : 'Month'}{offer.tenor != 1 ? 's' : ''}</Typography>
      <SelectedButton
        selected={selected}
        variant='outlined'
        endIcon={selected ? <DoneIcon /> : null}
        onClick={handleSelect}
        disableRipple
        disabled={disabled}
      >
        {selected ? 'Selected' : 'Select'}
      </SelectedButton>
    </ListItem>
  );
};

const CardOfferItem = ({ offer, theme, selected, disabled, onSelect }: OfferItemProps) => {
  const handleSelect = () => {
    onSelect?.(offer.id);
  };

  return (
    <CardItem>
      <Typography variant='body2'>Amount</Typography>
      <Typography variant='h2' className='justifyRight'>
        {formatCurrency(offer.loanAmount, offer.currency)}
      </Typography>      <Typography variant='body2'>Amount to return</Typography>
      <Typography variant='h2' className='justifyRight'>
        {formatCurrency(offer.repaymentAmount, offer.currency)}
      </Typography>
      <Typography variant='body2'>Monthly rate</Typography>
      <Typography variant='body1' className='justifyRight' color={theme?.palette.common.white}>
        {formatPercentage(offer.monthlyRate)}
      </Typography>
      <Typography variant='body2'>Tenor</Typography>
      <Typography variant='body1' color={theme?.palette.common.white} className='justifyRight'>
        {offer.tenor} {offer.type === 'ID' ? 'Day' : 'Month'}{offer.tenor != 1 ? 's' : ''}
      </Typography>
      <SelectedButton
        selected={selected}
        variant={'outlined'}
        flat
        endIcon={selected ? <DoneIcon /> : null}
        onClick={handleSelect}
        disableRipple
        disabled={disabled}
      >
        {selected ? 'Selected' : 'Select'}
      </SelectedButton>
    </CardItem>
  );
};

type OfferListProps = {
  onSelect(id: string): void;
  selectedOffer: string;
  disableCta?: boolean;
};

export const OfferList = ({ onSelect, selectedOffer, disableCta }: OfferListProps) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const application = useSelector(detailedApplicationDataSelector);

  const headerFields = useMemo(() => {
    const applicationType = application?.product?.type;

    if (applicationType === 'ID') {
      return idFields;
    } else return rbfFields;
  }, [application?.product?.type]);

  return (
    <Wrapper>
      {matches ? null : (
        <ListHeader isRbf={application.product.type === 'RBF'}>
          {headerFields.map((field) => (
            <Typography variant='body2' key={field}>
              {field}
            </Typography>
          ))}
          <span />
        </ListHeader>
      )}
      {application.offers.map((offer) =>
        matches ? (
          <CardOfferItem
            key={offer.id}
            offer={{ ...offer, currency: application.currency }}
            selected={offer.id === selectedOffer}
            disabled={disableCta}
            theme={theme}
            onSelect={onSelect}
          />
        ) : (
          <ListOfferItem
            key={offer.id}
            offer={{ ...offer, currency: application.currency }}
            selected={offer.id === selectedOffer}
            disabled={disableCta}
            onSelect={onSelect}
          />
        ),
      )}
    </Wrapper>
  );
};
