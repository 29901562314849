import { useMediaQuery, Box, SelectChangeEvent, useTheme } from '@mui/material';
import { AppLayout, AccountSidebar, Select, Typography } from 'components';
import {
  CompanyDetailsSettingsPage,
  BankingDetails,
  PersonalInfoPage,
  SecuritySettingsPage,
  FinancialPlatforms,
  AccountDocuments,
} from 'pages';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router';
import { navigationSelectorOpts } from './AccountSettings.config';

export const AccountSettings = () => {
  const location = useLocation();
  const theme = useTheme();
  const matches = useMediaQuery('(max-width: 750px)');
  const navigate = useNavigate();

  const handleChange = (e: SelectChangeEvent<string | number>) => {
    navigate(e.target.value as string);
  };

  return (
    <AppLayout sidebar={matches ? null : <AccountSidebar />}>
      {matches && (
        <Box
          padding={'1rem 1.5rem 1.5rem 1.5rem'}
          borderBottom={`1px solid ${theme.palette.lightBlue.light}`}
        >
          <Typography variant='h2' sx={{ mb: '1.5rem' }}>
            Account
          </Typography>
          <Select
            options={navigationSelectorOpts}
            onChange={handleChange}
            fullWidth
            value={location.pathname}
          />
        </Box>
      )}
      <Routes>
        <Route index element={<PersonalInfoPage />} />
        <Route path='/security' element={<SecuritySettingsPage />} />
        <Route path='/company' element={<CompanyDetailsSettingsPage />} />
        <Route path='/banking-details' element={<BankingDetails />} />
        <Route path='/financial-platforms' element={<FinancialPlatforms />} />
        <Route path='/account-documents' element={<AccountDocuments />} />
        <Route path='*' element={<Navigate to='/account' />} />
      </Routes>
    </AppLayout>
  );
};
