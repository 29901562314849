/* eslint-disable camelcase */
import { useEffect, useMemo, useState } from 'react';
import { AccountInfoContainer, Typography, Loader, PageTabs } from 'components';
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'hooks';
import {
  getCompanyFinancialPlatforms,
  getConnectedLeanTechBanks,
} from 'store/thunks/financialPlatformSliceThunk';
import { areFinancialPlatformsLoadingSelector } from 'store/selectors/financialPlatformsSelector';
import AddIcon from '@mui/icons-material/Add';
import { openFinancialPlatformModal } from 'store/slices/financialPlatformsSlice';
import { tabsOpts } from './FinancialPlatforms.config';
import { AccountingPlatforms, PaymentPlatforms, BankingPlatforms } from './components';
import { appDataSelfSelector } from 'store/selectors/appDataSelector';
import { getIsLeanTechEnabled } from 'utils/getIsLeanTechEnabled';

const FinancialPlatforms = () => {
  const dispatch = useDispatch();
  const [tabValue, setTabValue] = useState('accounting');
  const areFinancialPlatformsLoading = useSelector(areFinancialPlatformsLoadingSelector);
  const self = useSelector(appDataSelfSelector);
  const [leanTechEnabled, setLeanTechEnabled] = useState(false);

  useEffect(() => {
    dispatch(getCompanyFinancialPlatforms());
    dispatch(getConnectedLeanTechBanks());
    setLeanTechEnabled(getIsLeanTechEnabled());
  }, []);

  const showLeanTechIntegration = useMemo(() => {
    return Boolean(self._links?.connectLeanTech) && leanTechEnabled;
  }, [self._links?.connectLeanTech, leanTechEnabled]);

  const handleOpenFinancialPlatformsModal = (tab: string) => {
    dispatch(openFinancialPlatformModal(tab));
  };

  const filtertedTabsOpts = useMemo(() => {
    if (showLeanTechIntegration) return tabsOpts;

    return tabsOpts.filter((tabOpt) => tabOpt.value !== 'banks');
  }, [showLeanTechIntegration]);

  const handleOnConnect = () => {
    handleOpenFinancialPlatformsModal(tabValue);
  };

  return (
    <AccountInfoContainer sx={{ maxWidth: 'none' }}>
      <Typography variant='h1'>Finance Platforms</Typography>
      <PageTabs options={filtertedTabsOpts} value={tabValue} onChange={setTabValue} />
      <Box
        display='flex'
        flexDirection='column'
        gap='1rem'
        minHeight='10rem'
        justifyContent={areFinancialPlatformsLoading ? 'center' : undefined}
      >
        {areFinancialPlatformsLoading ? (
          <Loader />
        ) : (
          <>
            {tabValue === tabsOpts[0].value ? <BankingPlatforms /> : null}
            {tabValue === tabsOpts[1].value ? <PaymentPlatforms /> : null}
            {tabValue === tabsOpts[2].value ? <AccountingPlatforms /> : null}

            <Box
              display='flex'
              justifyContent='space-between'
              alignItems='center'
              border={'2px solid'}
              borderColor='darkBlue.light'
              padding='1.5rem .8rem'
              borderRadius='.5rem'
              sx={{
                transition: 'all .2s',
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: 'darkBlue.light',
                },
              }}
              onClick={handleOnConnect}
            >
              <Typography variant='h2'>Connect platforms</Typography>
              <AddIcon sx={{ color: 'common.white', width: '2rem', height: '2rem' }} />
            </Box>
          </>
        )}
      </Box>
    </AccountInfoContainer>
  );
};

export default FinancialPlatforms;
