import { NavLink } from 'react-router-dom';
import styles from './AccountSidebar.module.scss';
import { Sidebar, Typography } from 'components';
import { useTranslate } from 'react-polyglot';
import { clsx } from 'clsx';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import SummarizeIcon from '@mui/icons-material/Summarize';
import LockIcon from '@mui/icons-material/Lock';
import { showCodatIntegration } from 'portal.config';

const routes = [
  { path: '/account', name: 'account.personalInfo', icon: <PersonOutlineOutlinedIcon /> },
  { path: '/account/security', name: 'account.security', icon: <LockIcon /> },
  { path: '/account/company', name: 'account.company', icon: <WorkOutlineOutlinedIcon /> },
  {
    path: '/account/banking-details',
    name: 'account.bankingDetails',
    icon: <AccountBalanceWalletIcon />,
  },
  { path: '/account/account-documents', name: 'account.documents', icon: <SummarizeIcon /> },
];

if (showCodatIntegration) {
  routes.push({
    name: 'account.financialPlatforms',
    path: '/account/financial-platforms',
    icon: <AccountBalanceOutlinedIcon />,
  });
}

const AccountSidebar = () => {
  const t = useTranslate();

  return (
    <Sidebar>
      <div className={styles.wrapper}>
        <Typography variant='h1'>{t('account.title')}</Typography>
        {routes.map((route) => (
          <NavLink
            end
            key={route.path}
            to={route.path}
            className={({ isActive }) => clsx(styles.link, { [styles.active]: isActive })}
          >
            {route.icon} {t(route.name)}
          </NavLink>
        ))}
      </div>
    </Sidebar>
  );
};

export default AccountSidebar;
