import { createTheme } from '@mui/material';
import { createStyled } from '@mui/system';
import palette from './palette/palette';
import components from './components';
import typography from './typography';
import { CTheme } from './theme';

const theme = createTheme({
  components,
  palette,
  typography,
  shape: {
    borderRadius: 8,
  },
});

export const styled = createStyled<CTheme>({ defaultTheme: theme });

export default theme;
