import { useEffect, useRef, useState, useMemo } from 'react';
import Box from '@mui/material/Box';
import {
  Button,
  FileUpload,
  Typography,
  DocumentsList,
  UploadedFilesList,
  PageTabs,
} from 'components';
import { useDispatch, useSelector } from 'hooks';
import { useMediaQuery, useTheme } from '@mui/material';
import { useTranslate } from 'react-polyglot';
import {
  deleteUploadedDocument,
  downloadDocument,
  retryDocumentUpload,
  updateUploadedDocument,
  uploadDocument,
} from 'store/thunks/companySliceThunk';
import {
  applicationFinancialDocumentsSelector,
  applicationFinancialRequirementsSelector,
  applicationKycDocumentsSelector,
  applicationKycRequirementsSelector,
  areAllDocumentsProvidedSelector,
  areAllFinancialRequirementsMarkedSelector,
  areAllKYCRequirementsMarkedSelector,
  detailedApplicationDataSelector,
  detailedApplicationDocumentsSelector,
  detailedApplicationIdSelector,
  detailedApplicationRequirementsSelector,
} from 'store/selectors/detailedApplicationSelector';
import {
  refetchDetailedApplication,
  submitDocuments,
  submitFinancialDocuments,
  submitKycDocuments,
} from 'store/thunks/detailedApplicationSliceThunk';
import { dropDocument } from 'store/slices/detailedApplicationSlice';
import { DocumentProvidedWithLinks } from 'types/CompanyDetails.types';
import { DocumentProvided } from 'Portal Types';
import { appDataSelfSelector } from 'store/selectors/appDataSelector';
import { Props, TabsValues } from './FinancialDocuments.types';

export const FinancialDocuments = ({ defaultTab = 'financial', applicationStatus }: Props) => {
  const dispatch = useDispatch();
  const t = useTranslate();
  const theme = useTheme();
  const financialRequirements = useSelector(applicationFinancialRequirementsSelector);
  const kycRequirements = useSelector(applicationKycRequirementsSelector);
  const documentRequirements = useSelector(detailedApplicationRequirementsSelector);
  const financialDocuments = useSelector(applicationFinancialDocumentsSelector);
  const kycDocuments = useSelector(applicationKycDocumentsSelector);
  const application = useSelector(detailedApplicationDataSelector);
  const areDocumentsProvided = useSelector(areAllDocumentsProvidedSelector);
  const areFinancialDocumentsProvided = useSelector(areAllKYCRequirementsMarkedSelector);
  const areKYCDocumentsProvided = useSelector(areAllFinancialRequirementsMarkedSelector);
  const detailedApplicationId = useSelector(detailedApplicationIdSelector);
  const didInitialRenderHappen = useRef(false);
  const [isLoading, setIsLoading] = useState(false);
  const detailedApplicationDocuments = useSelector(detailedApplicationDocumentsSelector);
  const [documentStep, setDocumentStep] = useState<TabsValues>(defaultTab);
  const { isSubmitDocumentsSingleStep } = useSelector(appDataSelfSelector);
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  const isSingleStep = useMemo(() => {
    return isSubmitDocumentsSingleStep;
  }, [isSubmitDocumentsSingleStep]);

  const documentListRequirements = useMemo(() => {
    if (isSingleStep) {
      return documentRequirements;
    } else {
      if (documentStep === 'financial') {
        return financialRequirements;
      } else {
        return kycRequirements;
      }
    }
  }, [
    isSingleStep,
    documentStep,
    detailedApplicationDocuments.length,
    financialDocuments.length,
    kycDocuments.length,
    financialRequirements,
    kycRequirements,
  ]);

  useEffect(() => {
    setDocumentStep(defaultTab);
  }, [defaultTab]);

  const hasOptionalDocuments = useMemo(() => {
    return documentRequirements.some((requirement) => requirement.isOptional);
  }, [documentListRequirements]);

  const requirements = useMemo(() => {
    if (isSingleStep) {
      return [{ name: 'Document requirements', options: documentRequirements }];
    } else {
      if (documentStep === 'financial') {
        return [{ name: 'Financial requirements', options: financialRequirements }];
      } else {
        return [{ name: 'KYC requirements', options: kycRequirements }];
      }
    }
  }, [isSingleStep, documentStep]);

  const documents = useMemo(() => {
    let documents: DocumentProvided[] = [];
    if (isSingleStep) documents = detailedApplicationDocuments;
    else {
      if (documentStep === 'financial') documents = financialDocuments;
      else documents = kycDocuments;
    }

    return documents;
  }, [
    isSingleStep,
    documentStep,
    detailedApplicationDocuments.length,
    financialDocuments.length,
    kycDocuments.length,
    financialRequirements,
    kycRequirements,
  ]);

  useEffect(() => {
    if (didInitialRenderHappen.current) {
      dispatch(refetchDetailedApplication(detailedApplicationId));
    }
    didInitialRenderHappen.current = true;
  }, [
    areDocumentsProvided,
    areFinancialDocumentsProvided,
    areKYCDocumentsProvided,
    areKYCDocumentsProvided,
  ]);

  const handleSubmitDocuments = async () => {
    setIsLoading(true);

    await dispatch(submitDocuments());
    setIsLoading(false);
  };

  const handleSubmitFinancials = async () => {
    setIsLoading(true);

    await dispatch(submitFinancialDocuments());
    setIsLoading(false);
  };

  const handleSubmitKyc = async () => {
    setIsLoading(true);

    await dispatch(submitKycDocuments());
    setIsLoading(false);
  };

  const handleUpdateDocumentType = (id: string, requirementId: string) => {
    dispatch(updateUploadedDocument({ documentId: id, requirementId }));
  };

  const handleDelete = (id: string) => {
    dispatch(deleteUploadedDocument(id));
  };

  const handleDropDocumentFromMemory = (id: string) => {
    dispatch(dropDocument(id));
  };

  const handleFiles = (files: FileList) => {
    for (let i = 0; i < files.length; i++) {
      const file = files.item(i);
      if (!file) return;

      dispatch(
        uploadDocument({
          file,
          startLoading: i === 0,
          stopLoading: i === files.length - 1,
          isSingleItem: files.length === 1,
        }),
      );
    }
  };

  const retryUpload = (document: DocumentProvidedWithLinks) => {
    dispatch(retryDocumentUpload(document));
  };

  const handleDownload = async (document: DocumentProvidedWithLinks) => {
    await dispatch(downloadDocument(document));
  };

  const renderActionButton = () => {
    if (isSingleStep)
      return (
        <Button
          variant='contained'
          disabled={!application._links?.submitDocuments}
          onClick={handleSubmitDocuments}
          isLoading={isLoading}
        >
          {t('global.continue')}
        </Button>
      );

    if (applicationStatus === 'upload_documents')
      return (
        <Button
          variant='contained'
          disabled={!application._links?.submitFinancials}
          onClick={handleSubmitFinancials}
          isLoading={isLoading}
        >
          {t('global.continue')}
        </Button>
      );

    return (
      <Button
        variant='contained'
        disabled={!application._links?.submitKyc}
        onClick={handleSubmitKyc}
        isLoading={isLoading}
      >
        {t('global.continue')}
      </Button>
    );
  };

  return (
    <Box paddingY='2rem' paddingX='2.5rem' display='flex' flexDirection='column' gap={3}>
      <Typography variant='h1'>Upload documents</Typography>
      {isSingleStep ? null : (
        <Box>
          <PageTabs
            options={[
              { label: 'Financial', value: 'financial' },
              { label: 'KYC', value: 'kyc' },
            ]}
            onChange={(value) => setDocumentStep(value as TabsValues)}
            value={documentStep}
          />
        </Box>
      )}
      <Typography variant='body1' color={theme.palette.common.white}>
        Almost there! Please upload the below company documents to verify your business entity.
      </Typography>
      <DocumentsList requirements={documentListRequirements} />
      {hasOptionalDocuments ? (
        <Typography variant='body1'>
          <span style={{ color: '#fff' }}>*</span> optional documents
        </Typography>
      ) : null}
      <Box display='flex' alignItems='center'></Box>
      <FileUpload shrink={matches} handleFiles={handleFiles} />
      <UploadedFilesList
        documents={documents}
        requirements={requirements}
        onDelete={handleDelete}
        onUpdateType={handleUpdateDocumentType}
        onRetryUpload={retryUpload}
        onDownload={handleDownload}
        onDropFile={handleDropDocumentFromMemory}
      />
      <Box maxWidth='30rem'>{renderActionButton()}</Box>
    </Box>
  );
};
