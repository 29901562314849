import { Components, Theme } from '@mui/material';

export const MuiTooltip: Components<Theme>['MuiTooltip'] = {
  styleOverrides: {
    tooltip: ({ theme }) => ({
      backgroundColor: theme.palette.darkBlue.main,
      fontSize: '14px',
      fontFamily: 'IBM Plex Sans, sans-serif',
      padding: '1rem',
      borderRadius: '.5rem',
    }),
    arrow: ({ theme }) => ({
      '&::before': {
        backgroundColor: theme.palette.darkBlue.main,
      },
    }),
  },
};
