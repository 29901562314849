import { Box } from '@mui/material';
import { Typography } from 'components';
import palette from 'theme/palette/palette';

type Props = {
  options: ProgressOptionType[];
  highlightOptions: number;
};

type ProgressOptionType = {
  name: string;
};

const ProgressOption = ({ option }: { option: ProgressOptionType & { marked: boolean } }) => {
  return (
    <Box display={'flex'} flexDirection='column'>
      <Typography
        fontSize='14px'
        sx={{ m: '0 .3rem .5rem .3rem', color: option.marked ? palette.common.white : undefined }}
      >
        {option.name}
      </Typography>
      <Box
        border='2px solid white'
        borderRadius={'8px'}
        sx={{ opacity: option.marked ? 1 : 0.5 }}
      />
    </Box>
  );
};

export const HorizontalProgress = ({ options, highlightOptions }: Props) => {
  return (
    <Box display={'flex'} gap='.5rem'>
      {options.map((option, idx) => (
        <ProgressOption
          key={option.name + `${idx}`}
          option={{ name: option.name, marked: idx < highlightOptions }}
        />
      ))}
    </Box>
  );
};
