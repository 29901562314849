import { useMemo } from 'react';
import { AccountInfoContainer, Typography, UpdateCompanyDetailsForm } from 'components';
import { useSelector } from 'hooks';
import { companyDetailsSelector } from 'store/selectors/companyDetailsSelector';

const CompanyDetailsSettingsPage = () => {
  const companyDetails = useSelector(companyDetailsSelector);

  const formData = useMemo(() => {
    return {
      companyName: companyDetails?.companyName ?? '',
      lastYearRevenue: String(companyDetails?.lastYearRevenue) ?? '',
      phoneNumber: companyDetails?.phoneNumber ?? '',
      incorporationCountry: companyDetails?.incorporationCountry ?? '',
      industry: companyDetails?.industry ?? '',
    };
  }, [companyDetails]);

  return (
    <AccountInfoContainer>
      <Typography variant='h1'>Company Details</Typography>
      <UpdateCompanyDetailsForm propsFormData={formData} />
    </AccountInfoContainer>
  );
};

export default CompanyDetailsSettingsPage;
