import React, { useState, useRef } from 'react';
import { Typography, Loader } from 'components';
import { SxProps, Theme, useTheme } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import { Wrapper, Cover } from './FileUpload.styles';
import { useSelector } from 'hooks';
import { isCompanyDocumentsUploadingSelector } from 'store/selectors/companyDetailsSelector';

type Props = {
  shrink?: boolean;
  sx?: SxProps<Theme>;
  handleFiles(items: FileList): void;
};

export const FileUpload = ({ sx, shrink = false, handleFiles }: Props) => {
  const theme = useTheme();
  const isUploading = useSelector(isCompanyDocumentsUploadingSelector);
  const inputRef = useRef<null | HTMLInputElement>(null);
  const [dragActive, setIsDragActive] = useState(false);

  const handleDrag = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if ((e.type === 'dragenter' || e.type === 'dragover') && !dragActive) {
      setIsDragActive(true);
    } else if (e.type === 'dragleave') {
      setIsDragActive(false);
    }
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    setIsDragActive(false);

    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const { files } = e.dataTransfer;
      handleFiles?.(files);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.target.files && e.target.files[0]) {
      const { files } = e.target;
      handleFiles?.(files);
    }
  };

  const handleWrapperClick = () => {
    inputRef.current?.click();
  };

  return (
    <Wrapper
      sx={sx}
      shrink={shrink}
      isDragged={dragActive}
      onDragEnter={isUploading ? undefined : handleDrag}
      onDragOver={isUploading ? undefined : handleDrag}
      onClick={isUploading ? undefined : handleWrapperClick}
    >
      {isUploading ? (
        <Loader />
      ) : (
        <>
          <input
            type='file'
            onChange={handleChange}
            className={'hidden'}
            ref={inputRef}
            multiple={true}
          />
          <LogoutIcon />
          <Typography variant='body2' color={theme.palette.common.white}>
            {!shrink ? 'Drop or click here to upload documents' : 'Click here to upload documents'}
          </Typography>
          {dragActive && (
            <Cover
              onDragOver={handleDrag}
              onDragEnter={handleDrag}
              onDragLeave={handleDrag}
              onDrop={handleDrop}
            />
          )}
        </>
      )}
    </Wrapper>
  );
};
