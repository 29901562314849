import React from 'react';
import styles from './Center.module.scss';

type Props = {
  children?: React.ReactNode;
};

const Center = ({ children }: Props) => {
  return <div className={styles.wrapper}>{children}</div>;
};

export default Center;
