export const commonInputFields = [
  { id: 'bankAddress', label: 'Bank address' },
  { id: 'bankName', label: 'Bank name' },
] as const;

// ROW = Rest of the world
export const rowFields = [
  { id: 'iban', label: 'IBAN' },
  { id: 'swift', label: 'SWIFT' },
] as const;

export const zaFields = [
  { id: 'branchCode', label: 'Branch code' },
  { id: 'accountNumber', label: 'Account number' },
] as const;

export const initialFormData = {
  iban: '',
  swift: '',
  bankAddress: '',
  bankName: '',
  branchCode: '',
  accountNumber: '',
};

export type BankingDetailsForm = typeof initialFormData;
