import { useLocation, useNavigate, useParams } from 'react-router';
import {
  AppLayout,
  ApplicationSteps,
  Button,
  HorizontalApplicationSteps,
  Loader,
} from 'components';
import { useEffect, useMemo, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'hooks';
import { fetchDetailedApplication } from 'store/thunks/detailedApplicationSliceThunk';
import {
  detailedApplicationDataSelector,
  isDetailedApplicationLoadingSelector,
  detailedApplicationErrorSelector,
} from 'store/selectors/detailedApplicationSelector';
import { getApplicationStatusIndex } from 'utils/getStatusIndex';
import {
  resetDetailedApplicationState,
  setIsDetailedApplicationLoading,
} from 'store/slices/detailedApplicationSlice';
import { ApplicationStatus } from 'Portal Types';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ChooseProduct } from './ChooseProduct';
import { SelectOffer } from './SelectOffer';
import { ContractSigning } from './ContractSigning';
import { ReceiveFunds } from './ReceiveFunds';
import { BankingDetails } from './BankingDetails';
import { FinancialDocuments } from './FinancialDocuments';
import { RejectedView } from './RejectedView';
import { ErrorPage } from 'pages';
import { updateApplications } from 'store/slices/applicationsSlice';
import { appDataSelfSelector } from 'store/selectors/appDataSelector';
import { useFilteredApplicationViews } from './Application.hooks';

export const Application = () => {
  const { applicationId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const didInitialRenderHappen = useRef(false);
  const [viewMode, setViewMode] = useState<ApplicationStatus | null>(null);
  const detailedApplication = useSelector(detailedApplicationDataSelector);
  const isDetailedApplicationLoading = useSelector(isDetailedApplicationLoadingSelector);
  const applicationError = useSelector(detailedApplicationErrorSelector);
  const matches = useMediaQuery('(max-width:750px)');
  const { isSubmitDocumentsSingleStep } = useSelector(appDataSelfSelector);
  const filteredApplicationViews = useFilteredApplicationViews(isSubmitDocumentsSingleStep);

  const isLoading = useMemo(
    () => isDetailedApplicationLoading || !viewMode,
    [isDetailedApplicationLoading, viewMode],
  );

  const applicationStatusIndex = useMemo(() => {
    if (detailedApplication.status === 'waiting_offer')
      return getApplicationStatusIndex('offer_selection');
    if (detailedApplication.status === 'requested_offer')
      return getApplicationStatusIndex('offer_selection');

    return getApplicationStatusIndex(detailedApplication.status);
  }, [detailedApplication.status]);

  useEffect(() => {
    if (applicationId) {
      dispatch(fetchDetailedApplication(applicationId)).then(() => {
        didInitialRenderHappen.current = true;
      });
    } else {
      dispatch(setIsDetailedApplicationLoading(false));
    }
    return () => {
      dispatch(resetDetailedApplicationState());
    };
  }, []);

  useEffect(() => {
    if (!applicationId && detailedApplication.id)
      navigate(`${location.pathname}/${detailedApplication.id}`);
    didInitialRenderHappen.current = true;

    if (!detailedApplication?.id || detailedApplication.id === applicationId) return;
    dispatch(fetchDetailedApplication(detailedApplication?.id));
  }, [detailedApplication?.id]);

  useEffect(() => {
    const doesViewModeDifferFromStatus = viewMode !== detailedApplication.status;
    const isViewModeNotDefault = detailedApplication.status !== 'new_application';

    if (
      (doesViewModeDifferFromStatus && !applicationId) ||
      (applicationId && isViewModeNotDefault)
    ) {
      setViewMode(detailedApplication.status);
    }
  }, [detailedApplication.status]);

  const handleViewModeChange = (newMode: ApplicationStatus) => {
    setViewMode(newMode);
  };

  useEffect(() => {
    if (!didInitialRenderHappen || !detailedApplication.id.length || !applicationId) return;

    dispatch(updateApplications(detailedApplication));
  }, [detailedApplication.status]);

  const renderApplicationView = () => {
    const { _links } = detailedApplication;

    switch (viewMode) {
      case 'new_application':
        return (
          <ChooseProduct
            viewOnly={
              !_links?.updateApplication && detailedApplication.status !== 'new_application'
            }
            product={detailedApplication.product}
            isFullWidth={matches}
          />
        );
      case 'waiting_offer':
      case 'offer_selection':
      case 'requested_offer':
        return (
          <SelectOffer
            viewOnly={!_links?.acceptOffer}
            propsSelectedOffer={detailedApplication.offerId}
            changeViewMode={handleViewModeChange}
            disableRequestOffer={viewMode === 'requested_offer' || !_links?.requestOffer}
          />
        );
      case 'contract_signing':
        return (
          <ContractSigning
            viewOnly={applicationStatusIndex > getApplicationStatusIndex('contract_signing')}
          />
        );
      case 'complete':
        return <ReceiveFunds />;
      case 'banking_details':
        return <BankingDetails />;
      case 'upload_documents':
        return (
          <FinancialDocuments
            defaultTab='financial'
            applicationStatus={detailedApplication.status}
          />
        );
      case 'kyc_documents':
        return (
          <FinancialDocuments defaultTab='kyc' applicationStatus={detailedApplication.status} />
        );
      case 'rejected':
        return <RejectedView />;
      default:
        return <h1>test</h1>;
    }
  };

  if (applicationError) {
    const handleBackToDashboard = () => {
      navigate('/');
    };

    return (
      <ErrorPage>
        <Button variant='outlined' sx={{ maxWidth: '12rem' }} onClick={handleBackToDashboard}>
          Back to dashboard
        </Button>
      </ErrorPage>
    );
  }

  return (
    <AppLayout
      sidebar={
        matches ? null : (
          <ApplicationSteps
            applicationStatusIndex={applicationStatusIndex}
            viewMode={viewMode ? viewMode : 'new_application'}
            isLoading={isLoading}
            filteredApplicationViews={filteredApplicationViews}
            changeViewMode={handleViewModeChange}
          />
        )
      }
    >
      {matches && (
        <HorizontalApplicationSteps
          applicationStatusIndex={applicationStatusIndex}
          viewMode={viewMode ? viewMode : 'new_application'}
          isLoading={isLoading}
          changeViewMode={handleViewModeChange}
        />
      )}
      {isLoading ? <Loader /> : renderApplicationView()}
    </AppLayout>
  );
};
