import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import axios from 'axiosConfig';
import {
  AppErrorState,
  Application,
  BankData,
  DocumentProvided,
  DocumentRequirement,
  WithLinks,
} from 'Portal Types';
import { detailedApplicationIdSelector } from 'store/selectors/detailedApplicationSelector';
import { addNotification } from 'store/slices/alertSlice';
import { RootState } from 'store/store';
import { ApplicationChosenProductBody, SelfLink, ServerError, ServerResponse } from 'types';
import { getRequestHeaders } from 'utils/getRequestHeaders';

type DetailedApplication = WithLinks<Application, { submitDocuments?: SelfLink }> & {
  _embedded?: {
    documents: DocumentProvided[];
    requirements: DocumentRequirement[];
    bankDetails?: BankData;
  };
};

export const fetchDetailedApplication = createAsyncThunk<
  DetailedApplication,
  number | string,
  {
    state: RootState;
    rejectValue: { status: number };
  }
>(
  'detailedApplicationSlice/getDetailedApplication',
  async (applicationId, { getState, rejectWithValue }) => {
    try {
      const store = getState();
      const headers = getRequestHeaders(store);

      const response = await axios.get(`/applications/${applicationId}`, {
        headers,
      });
      return response.data as DetailedApplication;
    } catch (error) {
      const serverError = error as AxiosError<ServerError>;
      return rejectWithValue({ status: serverError.response?.status ?? -1 });
    }
  },
);

export const refetchDetailedApplication = createAsyncThunk<
  DetailedApplication,
  number | string,
  {
    state: RootState;
    rejectValue: AppErrorState;
  }
>(
  'detailedApplicationSlice/refetchDetailedApplication',
  async (applicationId, { getState, rejectWithValue }) => {
    try {
      const store = getState();
      const headers = getRequestHeaders(store);

      const response = await axios.get(`/applications/${applicationId}`, {
        headers,
      });
      return response.data as DetailedApplication;
    } catch (error) {
      const serverError = error as AxiosError<ServerError>;

      return rejectWithValue({ status: serverError.response?.status ?? 1 });
    }
  },
);

export const postApplicationChosenProduct = createAsyncThunk<
  Application,
  ApplicationChosenProductBody,
  {
    state: RootState;
    rejectValue: null;
  }
>(
  'detailedApplicationSlice/postChosenProduct',
  async (data, { dispatch, rejectWithValue, getState }) => {
    try {
      const store = getState();
      const headers = getRequestHeaders(store);

      const response = await axios.post('/applications', data, { headers });
      return response.data as Application;
    } catch (error) {
      dispatch(
        addNotification({
          severity: 'error',
        }),
      );

      return rejectWithValue(null);
    }
  },
);

export const updateApplicationChosenProduct = createAsyncThunk<
  Application,
  ApplicationChosenProductBody,
  {
    state: RootState;
    rejectValue: null;
  }
>(
  'detailedApplicationSlice/updateChosenProduct',
  async (data, { dispatch, rejectWithValue, getState }) => {
    try {
      const store = getState();
      const applicationId = detailedApplicationIdSelector(store);
      const headers = getRequestHeaders(store);

      const response = await axios.patch(`/applications/${applicationId}`, data, { headers });
      return response.data as Application;
    } catch (error) {
      dispatch(
        addNotification({
          severity: 'error',
        }),
      );

      return rejectWithValue(null);
    }
  },
);

export const requestAdditionalOffer = createAsyncThunk<
  DetailedApplication,
  { reason: string },
  { state: RootState }
>(
  'detailedApplicationSlice/requestOffer',
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const store = getState();
      const headers = getRequestHeaders(store);
      const applicationId = detailedApplicationIdSelector(store);

      const response = (await axios.post(`/applications/${applicationId}/requestOffer`, data, {
        headers,
      })) as ServerResponse<DetailedApplication>;

      return response.data;
    } catch {
      dispatch(addNotification({ severity: 'error' }));
      return rejectWithValue(null);
    }
  },
);

export const postAcceptedOffer = createAsyncThunk<
  DetailedApplication,
  string,
  { state: RootState }
>(
  'detailedApplicationSlice/acceptOffer',
  async (offerId, { dispatch, getState, rejectWithValue }) => {
    try {
      const store = getState();
      const applicationId = detailedApplicationIdSelector(store);
      const headers = getRequestHeaders(store);

      const response = (await axios.post(
        `/applications/${applicationId}/acceptOffer`,
        { offerId },
        { headers },
      )) as ServerResponse<DetailedApplication>;

      return response.data;
    } catch (error) {
      dispatch(
        addNotification({
          severity: 'error',
        }),
      );
      return rejectWithValue(null);
    }
  },
);

export const submitDocuments = createAsyncThunk<
  DetailedApplication,
  undefined,
  { state: RootState }
>(
  'detailedApplicationSlice/submitDocuments',
  async (_, { dispatch, rejectWithValue, getState }) => {
    try {
      const store = getState();
      const headers = getRequestHeaders(store);
      const applicationId = detailedApplicationIdSelector(store);

      const response = (await axios.post(`/applications/${applicationId}/submitDocuments`, null, {
        headers,
      })) as ServerResponse<DetailedApplication>;

      return response.data;
    } catch (error) {
      dispatch(
        addNotification({
          severity: 'error',
        }),
      );
      return rejectWithValue(null);
    }
  },
);

export const submitFinancialDocuments = createAsyncThunk<
  DetailedApplication,
  undefined,
  { state: RootState }
>(
  'detailedApplicationSlice/submitFinancials',
  async (_, { dispatch, rejectWithValue, getState }) => {
    try {
      const store = getState();
      const headers = getRequestHeaders(store);
      const applicationId = detailedApplicationIdSelector(store);

      const response = (await axios.post(`/applications/${applicationId}/submitFinancials`, null, {
        headers,
      })) as ServerResponse<DetailedApplication>;

      return response.data;
    } catch (error) {
      dispatch(
        addNotification({
          severity: 'error',
        }),
      );
      return rejectWithValue(null);
    }
  },
);

export const submitKycDocuments = createAsyncThunk<
  DetailedApplication,
  undefined,
  { state: RootState }
>('detailedApplicationSlice/submitKYC', async (_, { dispatch, rejectWithValue, getState }) => {
  try {
    const store = getState();
    const headers = getRequestHeaders(store);
    const applicationId = detailedApplicationIdSelector(store);

    const response = (await axios.post(`/applications/${applicationId}/submitKYC`, null, {
      headers,
    })) as ServerResponse<DetailedApplication>;

    return response.data;
  } catch (error) {
    dispatch(
      addNotification({
        severity: 'error',
      }),
    );
    return rejectWithValue(null);
  }
});

export const submitApplicationBankingDetails = createAsyncThunk<
  DetailedApplication,
  BankData,
  { state: RootState }
>(
  'detailedApplicationSlice/submitBankingDetails',
  async (data, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      const headers = getRequestHeaders(state);
      const applicationId = detailedApplicationIdSelector(state);

      const response = (await axios.post(`/applications/${applicationId}/submitBanking`, data, {
        headers,
      })) as ServerResponse<DetailedApplication>;

      return response.data;
    } catch (error) {
      dispatch(
        addNotification({
          severity: 'error',
        }),
      );
      return rejectWithValue(null);
    }
  },
);

export const progressApplicationStep = createAsyncThunk<void, undefined, { state: RootState }>(
  'detailedApplicationSlice/progressApplicationStep',
  async (_, { getState, dispatch, rejectWithValue }) => {
    try {
      const state = getState();
      const headers = getRequestHeaders(state);
      const applicationId = detailedApplicationIdSelector(state);

      await axios.post(`/applications/${applicationId}/next`, null, {
        headers,
      });
      dispatch(
        addNotification({
          severity: 'success',
          message: 'Application step has been progressed with success!',
        }),
      );

      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (error) {
      dispatch(
        addNotification({
          severity: 'error',
        }),
      );
      return rejectWithValue(null);
    }
  },
);
