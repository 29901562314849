import React from 'react';
import { Typography } from 'components';
import { Box } from '@mui/material';
import { Wrapper } from './WaitingScreen.styles';

type Props = {
  children: React.ReactNode;
  title: string;
};

export const WaitingScreen = ({ children, title }: Props) => {
  return (
    <>
      <Box margin={2}>
        <Typography variant='h2' fontWeight={600}>
          {title}
        </Typography>
        <Wrapper marginTop={3} paddingY={12}>
          <Box
            display='flex'
            flexDirection='column'
            alignItems='center'
            gap={'1rem'}
            maxWidth='40rem'
            textAlign='center'
          >
            {children}
          </Box>
        </Wrapper>
      </Box>
    </>
  );
};
