import { Notification } from './Notification';
import styles from './NotificationContainer.module.scss';
import { useSelector } from 'hooks';
import { alertsSelector } from 'store/selectors/alertSelector';

const GlobalNotification = () => {
  const notifications = useSelector(alertsSelector);

  return (
    <div className={styles.wrapper}>
      {notifications.length > 0
        ? notifications.map((notification) => (
            <Notification key={notification.id} {...notification} />
          ))
        : null}
    </div>
  );
};

export default GlobalNotification;
