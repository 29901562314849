import { Button } from 'components/common';
import { ErrorPage } from 'pages';
import React, { Component } from 'react';

type Props = {
  children: React.ReactNode;
  fallback?: React.ReactNode;
};

type State = {
  hasError: boolean;
};

export class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch() {
    this.setState((state) => ({ ...state, hasError: true }));
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorPage>
          <Button
            variant='outlined'
            onClick={() => {
              window.location.assign('/');
            }}
            sx={{ maxWidth: '15rem' }}
          >
            Reload application
          </Button>
        </ErrorPage>
      );
    }

    return this.props.children;
  }
}
