import { useDispatch, useSelector } from 'hooks';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { ApplicationItem, Button, Typography } from 'components';
import { applicationsSelector } from 'store/selectors/applicationSelector';
import { Navigate, useNavigate } from 'react-router';
import { setDetailedApplicationId } from 'store/slices/detailedApplicationSlice';
import { appDataLinksSelector } from 'store/selectors/appDataSelector';
import { useTranslate } from 'react-polyglot';

const Dashboard = () => {
  const t = useTranslate();
  const dispatch = useDispatch();
  const applications = useSelector(applicationsSelector);
  const canApplyForApplications = Boolean(useSelector(appDataLinksSelector)?.createApplication);
  const navigate = useNavigate();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClick = () => {
    navigate('/application');
  };

  const handleApplicationClick = (id: string) => {
    dispatch(setDetailedApplicationId(id));
    navigate(`/application/${id}`);
  };

  return (
    <Box width='100%' height='100%' padding={matches ? 0 : '2rem'} position='relative'>
      <Box
        padding='1rem'
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        flexDirection={matches ? 'column' : undefined}
        gap={matches ? '1rem' : undefined}
      >
        <Typography variant='h5' sx={{ fontWeight: 600 }}>
          {t('dashboard.title')}
        </Typography>
        <Button
          variant='contained'
          onClick={handleClick}
          disabled={!canApplyForApplications}
          fullWidth={false}
          sx={{ p: '.7rem 2rem' }}
        >
          {t('dashboard.create')}
        </Button>
      </Box>
      {applications.length > 0 ? (
        <Box
          padding={matches ? 0 : '1rem'}
          gap='1.5rem'
          display={'grid'}
          gridTemplateColumns={matches ? '300px' : 'repeat(auto-fit, 19rem)'}
          flexWrap='wrap'
          flexDirection={matches ? 'column' : undefined}
          justifyContent={matches ? 'center' : undefined}
          alignItems={matches ? 'center' : undefined}
          paddingY={matches ? '1rem' : undefined}
        >
          {applications.map((application, idx) => (
            <ApplicationItem
              {...application}
              key={application.id}
              index={idx + 1}
              onClick={handleApplicationClick}
            />
          ))}
        </Box>
      ) : (
        <Navigate to='/application' />
      )}
    </Box>
  );
};

export default Dashboard;
