import { Components } from '@mui/material';
import palette from 'theme/palette/palette';

export const MuiIconButton: Components['MuiIconButton'] = {
  defaultProps: {
    disableRipple: true,
  },
  styleOverrides: {
    root: {
      backgroundColor: 'transparent',
      '& svg': {
        width: '1.5rem',
        height: '1.5rem',
        fill: palette.common.white,
        padding: 0,
      },

      '&:hover svg, &:active svg': {
        fill: '#2B3142',
      },

      '&.Mui-disabled svg': {
        fill: '#151922',
      },
    },
  },
};
