export type SignupData = {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  middleName: string;
  acceptedTerms: boolean;
};

export type AuthError = {
  code: ErrorCodeTypes;
  message: string;
};

export enum ErrorCodeTypes {
  UserNotFoundException = 'UserNotFoundException',
  UserNotConfirmedException = 'UserNotConfirmedException',
  NotAuthorizedException = 'NotAuthorizedException',
  CodeMismatchException = 'CodeMismatchException',
  NetworkError = 'NetworkError',
}
