import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { Navbar } from '../';
import styles from './AppLayout.module.scss';
import { useDispatch, useSelector } from 'hooks';
import { persistedRouteSelector } from 'store/selectors/appDataSelector';
import { defaultRoute } from 'Constants';
import { setPersistedRoute } from 'store/slices/appDataSlice';
import { getAccountDocuments } from 'store/thunks/companySliceThunk';

type Props = {
  sidebar?: React.ReactNode;
  children?: React.ReactNode;
};

const AppLayout: React.FC<Props> = ({ children, sidebar }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const persistedRoute = useSelector(persistedRouteSelector);

  useEffect(() => {
    if (persistedRoute && persistedRoute !== defaultRoute) {
      navigate(persistedRoute);
      dispatch(setPersistedRoute(null));
    }
    dispatch(getAccountDocuments());
  }, []);

  return (
    <div className={styles.wrapper}>
      <Navbar />
      <div className={styles.contentBox}>
        {sidebar}
        <div className={styles.content}>{children ? children : <Outlet />}</div>
      </div>
    </div>
  );
};

export default AppLayout;
