import { useCallback, useMemo } from 'react';
import { Auth } from 'aws-amplify';
import { CognitoUser, UserAttributes } from 'Portal Types';
import { useLocation, useNavigate } from 'react-router-dom';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth/lib/types';
import { useDispatch, useNotification, useSelector } from '.';
import { setUser } from 'store/slices/appUserSlice';
import { defaultRoute } from 'Constants';
import { setGlobalLoading, setPersistedRoute } from 'store/slices/appDataSlice';
import { AuthError, SignupData, ErrorCodeTypes } from 'types';
import { appUserSelector } from 'store/selectors/appUserSelector';
import { fetchCompanyDetails } from 'store/thunks/companySliceThunk';
import { fetchCurrentApplications } from 'store/thunks/applicationsSliceThunk';
import { getSelfData } from 'store/thunks/appDataSliceThunk';

export const useAuth = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const user = useSelector(appUserSelector);
  const { notifyError, notifySuccess } = useNotification();

  const signIn = useCallback(async (username: string, password: string) => {
    try {
      dispatch(setGlobalLoading(true));
      const user = (await Auth.signIn(username, password)) as CognitoUser;
      dispatch(setUser(user));
      const response = await dispatch(getSelfData());
      if (response.payload?._links.getAccount) {
        await dispatch(fetchCompanyDetails());
        await dispatch(fetchCurrentApplications());
      }
    } catch (error) {
      const authError = error as AuthError;
      switch (authError.code) {
        case ErrorCodeTypes.UserNotConfirmedException:
          navigate('/confirm-email', { state: { username } });
          break;
        case ErrorCodeTypes.NotAuthorizedException:
        case ErrorCodeTypes.UserNotFoundException:
          notifyError('Incorrect username or password');
          break;
        case ErrorCodeTypes.NetworkError:
          notifyError('Please check your internet connection');
          break;
        default:
          break;
      }
    } finally {
      dispatch(setGlobalLoading(false));
    }
  }, []);

  const signOut = useCallback(async () => {
    if (location.pathname !== defaultRoute) {
      dispatch(setPersistedRoute(location.pathname));
    }

    await Auth.signOut();
    dispatch({ type: 'logout/clear-state' });
    dispatch(setUser(null));
    dispatch(setGlobalLoading(false));
    navigate('/');
  }, []);

  const checkUser = useCallback(async () => {
    try {
      const user = (await Auth.currentAuthenticatedUser({ bypassCache: true })) as CognitoUser;
      dispatch(setUser(user));
      const response = await dispatch(getSelfData());
      if (response.payload?._links.getAccount) {
        await dispatch(fetchCompanyDetails());
        await dispatch(fetchCurrentApplications());
      }
      dispatch(setGlobalLoading(false));
    } catch {
      dispatch(setUser(null));
      dispatch(setGlobalLoading(false));
    }
  }, []);

  const federatedSignIn = useCallback(async () => {
    try {
      Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google });
    } catch (err) {
      notifyError();
    }
  }, []);

  const signUp = useCallback(async (data: SignupData) => {
    try {
      const authObj = {
        username: data.email,
        password: data.password,
        attributes: {
          email: data.email,
          ['given_name']: data.firstName,
          ['family_name']: data.lastName,
          ['custom:middle_name']: data.middleName ?? undefined,
          ['custom:accepted_terms']: data.acceptedTerms.toString(),
        },
        autoSignIn: {
          enabled: true,
        },
      };

      await Auth.signUp(authObj);
      navigate('/confirm-email', { state: { username: data.email } });
    } catch (err) {
      const authError = err as AuthError;
      notifyError(authError.message);
    }
  }, []);

  const confirmSignUp = useCallback(async (username: string, code: string) => {
    try {
      await Auth.confirmSignUp(username, code);
    } catch (err) {
      const authError = err as AuthError;
      notifyError(authError.message);
    }
  }, []);

  const resendSignUpConfirm = useCallback((username: string) => {
    try {
      Auth.resendSignUp(username);
      notifySuccess('Verification code resent successfully!');
    } catch (err) {
      const authError = err as AuthError;
      notifyError(authError.message);
    }
  }, []);

  const forgotPassword = useCallback(async (username: string) => {
    try {
      await Auth.forgotPassword(username);
      navigate('/new-password', { state: { username } });
    } catch (err) {
      const authError = err as AuthError;
      notifyError(authError.message);
    }
  }, []);

  const confirmForgotPassword = useCallback(
    async (username: string, code: string, password: string) => {
      try {
        await Auth.forgotPasswordSubmit(username, code, password);
        notifySuccess('Password changed successfully!');
        navigate('/');
      } catch (err) {
        const authError = err as AuthError;
        notifyError(authError.message);
      }
    },
    [],
  );

  const updateUserAttributes = useCallback(async (attributes: Partial<UserAttributes>) => {
    try {
      if (!user) return;
      await Auth.updateUserAttributes(user, attributes);
      const updatedUser = { ...user, attributes: { ...user.attributes, ...attributes } };
      dispatch(setUser(updatedUser as CognitoUser));
      notifySuccess('Personal details updated successfully!');
    } catch (err) {
      notifyError();
    }
  }, []);

  const changePassword = useCallback(async (oldPassword: string, newPassword: string) => {
    try {
      await Auth.changePassword(user, oldPassword, newPassword);
      notifySuccess('Password has been changed successfully!');
    } catch {
      notifyError('Incorrect credentials!');
    }
  }, []);

  const returnObj = useMemo(() => {
    return {
      signIn,
      signOut,
      checkUser,
      federatedSignIn,
      signUp,
      confirmSignUp,
      resendSignUpConfirm,
      forgotPassword,
      confirmForgotPassword,
      updateUserAttributes,
      changePassword,
    };
  }, [
    signIn,
    signOut,
    checkUser,
    federatedSignIn,
    signUp,
    confirmSignUp,
    resendSignUpConfirm,
    forgotPassword,
    confirmForgotPassword,
    updateUserAttributes,
    changePassword,
  ]);

  return returnObj;
};
