import { useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { TextField, Typography, Button } from 'components';
import { useAuth } from 'hooks';
import { useTranslate } from 'react-polyglot';
import { emailRegex } from 'Constants';

const FogrotPasswordStepOne = () => {
  const t = useTranslate();
  const { forgotPassword } = useAuth();
  const [email, setEmail] = useState('');
  const [emailHelper, setEmailHelper] = useState('');

  const handleClick = () => {
    forgotPassword(email);
  };

  const hasError = useMemo(() => !emailRegex.test(email), [email]);

  return (
    <div>
      <Typography variant='h1'>{t('forgotPassword.forgotPassword')}</Typography>
      <Typography variant='body2'>{t('forgotPassword.instruction')}</Typography>
      <TextField
        size='small'
        value={email}
        onChange={(e) => {
          if (emailHelper) {
            setEmailHelper('');
          }
          setEmail(e.target.value);
        }}
        label={t('global.email')}
        fullWidth
        helperText={emailHelper}
        error={Boolean(emailHelper)}
        onBlur={() => {
          if (hasError) {
            setEmailHelper('Email provided is not valid!');
          }
        }}
      />
      <Button variant='contained' onClick={handleClick} disabled={hasError}>
        {t('forgotPassword.resetPassword')}
      </Button>
      <Link to={'/'}>{t('signin.signin')}</Link>
    </div>
  );
};

export default FogrotPasswordStepOne;
