import { Typography, Select } from 'components';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  IconButton,
  useTheme,
  Tooltip,
  useMediaQuery,
  SelectChangeEvent,
  Box,
} from '@mui/material';
import fileSize from 'utils/fileSize';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  TableCell,
  TableRow,
  MobileListContainer,
  MobileListItem,
} from './UploadedFilesList.styles';
import { useMemo } from 'react';
import { DocumentProvidedWithLinks } from 'types/CompanyDetails.types';
import { DocumentRequirement } from 'Portal Types';
import ReplayIcon from '@mui/icons-material/Replay';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

type Props = {
  documents: DocumentProvidedWithLinks[];
  requirements: Requirements[];
  onUpdateType(id: string, type: string, group: string): void;
  onDelete(id: string): void;
  onRetryUpload?(document: DocumentProvidedWithLinks): void;
  onDownload(document: DocumentProvidedWithLinks): void;
  onDropFile(id: string): void;
};

type Requirements = {
  name: string;
  options: DocumentRequirement[];
};

export const UploadedFilesList = ({
  documents,
  requirements,
  onUpdateType,
  onDelete,
  onRetryUpload,
  onDownload,
  onDropFile,
}: Props) => {
  const theme = useTheme();
  const matchesLg = useMediaQuery('(max-width: 1200px)');
  const matchesMd = useMediaQuery('(max-width: 950px)');

  const options = useMemo(() => {
    const mappedRequirements = requirements.map((requirement) => {
      return {
        name: requirement.name,
        options: [
          ...requirement.options.map((option) => ({
            name: option.name,
            value: option.requirement_id,
          })),
        ],
      };
    });

    return mappedRequirements;
  }, [requirements]);

  const onChange = (evt: SelectChangeEvent<string | number>) => {
    const [group, name] = (evt.target.value as string).split(':');
    onUpdateType?.(evt.target.name, name, group);
  };

  const handleDelete = (document: DocumentProvidedWithLinks) => () => {
    if (document.bytes > 0) {
      onDelete?.(document.id);
    } else {
      onDropFile?.(document.id);
    }
  };

  const handleRetryUpload = (document: DocumentProvidedWithLinks) => () => {
    onRetryUpload?.(document);
  };

  const handleDownload = (document: DocumentProvidedWithLinks) => () => {
    onDownload(document);
  };

  return matchesMd ? (
    <MobileListContainer>
      {documents.map((document) => {
        const isBlank = document.bytes === 0;
        return (
          <MobileListItem key={document.id}>
            <Box marginBottom='1.1rem' display={'flex'} gap={'.5rem'} alignItems='center'>
              <Typography noWrap variant='body1' color={theme.palette.common.white}>
                {document.client_filename}
              </Typography>
              <Typography>({fileSize(document.bytes)})</Typography>
              <Box marginLeft='auto'>
                <IconButton
                  onClick={handleDownload(document)}
                  sx={{ mr: '1rem' }}
                  disabled={isBlank}
                >
                  <FileDownloadOutlinedIcon />
                </IconButton>
                {document.failed && !isBlank ? (
                  <IconButton onClick={handleRetryUpload(document)}>
                    <ReplayIcon />
                  </IconButton>
                ) : (
                  <IconButton
                    disabled={isBlank ? false : !document._links?.deleteDocument}
                    onClick={handleDelete(document)}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </Box>
            </Box>
            <Box>
              <Select
                grouped
                groupedOptions={options}
                onChange={onChange}
                name={document.id}
                value={document.requirementId ?? ''}
                fullWidth
                disabled={!document.editable}
              />
            </Box>
          </MobileListItem>
        );
      })}
    </MobileListContainer>
  ) : (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell width={matchesLg ? 200 : undefined}>
              <Typography variant='body2'>Name</Typography>
            </TableCell>
            <TableCell width={200}>
              <Typography variant='body2'>Size</Typography>
            </TableCell>
            <TableCell width={320}>
              <Typography variant='body2'>Type</Typography>
            </TableCell>
            <TableCell width={matchesLg ? 60 : 200} />
          </TableRow>
        </TableHead>
        <TableBody>
          {documents.map((document) => {
            const isBlank = document.bytes === 0;

            return (
              <TableRow key={document.id}>
                <TableCell>
                  <Tooltip title={document.client_filename}>
                    <Box>
                      <Typography
                        variant='body1'
                        color={theme.palette.common.white}
                        sx={{
                          maxWidth: matchesLg ? '150px' : 'calc(100% - 50px)',
                          cursor: 'pointer',
                        }}
                        noWrap
                      >
                        {document.client_filename}
                      </Typography>
                      {document.failed && (
                        <Typography variant='caption' color={theme.palette.error.main}>
                          Upload failed
                        </Typography>
                      )}
                    </Box>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <Typography variant='body1'>{fileSize(document.bytes)}</Typography>
                </TableCell>
                <TableCell>
                  <Select
                    grouped
                    groupedOptions={options}
                    onChange={onChange}
                    name={document.id}
                    value={document.requirementId ?? ''}
                    disabled={!document.editable}
                    sx={{ width: '20rem' }}
                  />
                </TableCell>
                <TableCell align='right' sx={{ minWidth: '5rem' }}>
                  <IconButton onClick={handleDownload(document)} disabled={isBlank}>
                    <FileDownloadOutlinedIcon />
                  </IconButton>
                  {document.failed && !isBlank ? (
                    <Tooltip title='Retry upload'>
                      <IconButton onClick={handleRetryUpload(document)}>
                        <ReplayIcon />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <IconButton
                      disabled={isBlank ? false : !document._links?.deleteDocument}
                      onClick={handleDelete(document)}
                      sx={{ fontSize: '1rem' }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
