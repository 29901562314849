import { showCodatIntegration } from 'portal.config';

const authSteps = [
  { name: 'Create an account', marked: false },
  { name: 'Add business info', marked: false },
];

if (showCodatIntegration) {
  authSteps.push({ name: 'Connect platforms', marked: false });
}

export { authSteps };
