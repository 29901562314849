import { Box } from '@mui/material';
import { PersonalInfoForm, Typography, AccountInfoContainer } from 'components';
import { websiteUrl } from 'portal.config';

const PersonalInfoPage = () => {
  return (
    <AccountInfoContainer>
      <Typography variant='h1'>Personal Info</Typography>
      <PersonalInfoForm />
      <Box>
        <Typography variant='h1'>Preferences</Typography>
        <Typography variant='h4' sx={{ mt: 2 }}>
          You have accepted our{' '}
          <a href={`${websiteUrl}/terms`} rel='noreferrer' target='_blank'>
            Terms and conditions
          </a>
        </Typography>
      </Box>
    </AccountInfoContainer>
  );
};

export default PersonalInfoPage;
