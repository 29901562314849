import React from 'react';
import { Wrapper } from './AccountInfoContainer.styles';
import { SxProps, Theme } from '@mui/material';

type Props = {
  children?: React.ReactNode;
  sx?: SxProps<Theme>;
};

const AccountInfoContainer = ({ children, sx }: Props) => {
  return <Wrapper sx={sx}>{children}</Wrapper>;
};

export default AccountInfoContainer;
