import { useMemo, useEffect } from 'react';
import { NotificationType } from 'Portal Types';
import { IconButton, useTheme } from '@mui/material';
import { Close, ErrorOutline, WarningAmber, CheckCircleOutline } from '@mui/icons-material';
import { clsx } from 'clsx';
import styles from './Notification.module.scss';
import { Typography } from 'components';
import { useDispatch } from 'hooks';
import { dropNotification } from 'store/slices/alertSlice';

const Notification = ({ message, severity, id }: NotificationType) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      dispatch(dropNotification(id ?? ''));
    }, 3000);

    return () => clearTimeout(timeoutId);
  }, []);

  const computedIcon = useMemo(() => {
    switch (severity) {
      case 'error':
        return <ErrorOutline color='error' />;
      case 'warning':
        return <WarningAmber color='warning' />;
      default:
        return <CheckCircleOutline color='success' />;
    }
  }, [severity]);

  const handleClose = () => {
    if (!id) return;
    dispatch(dropNotification(id));
  };

  return (
    <div
      className={clsx(styles.wrapper, {
        [styles.error]: severity === 'error',
        [styles.warning]: severity === 'warning',
        [styles.success]: severity === 'success',
      })}
    >
      {computedIcon}
      <Typography color={theme.palette.common.white} variant='body1' textAlign={'left'}>
        {message}
      </Typography>
      <IconButton onClick={handleClose}>
        <Close />
      </IconButton>
    </div>
  );
};

export default Notification;
