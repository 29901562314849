import { createAsyncThunk } from '@reduxjs/toolkit';
import { getRequestHeaders } from 'utils/getRequestHeaders';
import { companyDetailsSelector } from 'store/selectors/companyDetailsSelector';
import { RootState } from 'store/store';
import { FinancialPlatformType, LeanTechBankData, LeanTechData, ServerResponse } from 'types';
import { addNotification } from 'store/slices/alertSlice';
import axios from 'axiosConfig';
import { WithLinks } from 'Portal Types';
import { AxiosResponse } from 'axios';

type FinancialPlatformsResponse = {
  accountingConnections: {
    available: FinancialPlatformType[];
    onDemand: FinancialPlatformType[];
  };
  paymentConnections: {
    available: FinancialPlatformType[];
    onDemand: FinancialPlatformType[];
  };
};

export const getCompanyFinancialPlatforms = createAsyncThunk<
  FinancialPlatformsResponse,
  void,
  { state: RootState }
>(
  'financialPlatformsSlice/getFinancialPlatforms',
  async (_, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      const headers = getRequestHeaders(state);
      const accountId = companyDetailsSelector(state);

      const response = (await axios.get(`/accounts/${accountId?.id}/financialPlatforms`, {
        headers,
      })) as ServerResponse<WithLinks<FinancialPlatformsResponse>>;

      return response.data;
    } catch (error) {
      dispatch(
        addNotification({
          severity: 'error',
        }),
      );
      return rejectWithValue(null);
    }
  },
);

export const selectCompanyFinancialPlatform = createAsyncThunk<
  { link: string },
  string,
  { state: RootState }
>(
  'financialPlatformSlice/connectFinancialPlatform',
  async (platformName, { getState, rejectWithValue, dispatch }) => {
    try {
      const state = getState();
      const headers = getRequestHeaders(state);
      const accountId = companyDetailsSelector(state);

      const response = (await axios.post(
        `/accounts/${accountId?.id}/financialPlatforms`,
        { platformName },
        { headers },
      )) as AxiosResponse<{ link: string }>;

      return response.data;
    } catch (err) {
      dispatch(
        addNotification({
          severity: 'error',
        }),
      );
      return rejectWithValue(null);
    }
  },
);

export const deleteFinancialPlatform = createAsyncThunk<
  { status: number; connectionId: string },
  string,
  { state: RootState }
>(
  'financialPlatformSlice/deleteFinancialPlatform',
  async (connectionId, { getState, rejectWithValue, dispatch }) => {
    try {
      const state = getState();
      const headers = getRequestHeaders(state);
      const accountId = companyDetailsSelector(state);

      const response = (await axios.delete(`/accounts/${accountId?.id}/financialPlatforms`, {
        headers,
        data: { connectionId },
      })) as AxiosResponse<unknown>;

      return { status: response.status, connectionId };
    } catch (err) {
      dispatch(
        addNotification({
          severity: 'error',
        }),
      );
      return rejectWithValue(null);
    }
  },
);

export const requestFinancialPlatformConnection = createAsyncThunk<
  FinancialPlatformType,
  { platformName: string | null; connectionType: string },
  { state: RootState }
>(
  'financialPlatformSlice/requestFinancialPlatformConnection',
  async ({ platformName, connectionType }, { getState }) => {
    const state = getState();
    const headers = getRequestHeaders(state);
    const account = companyDetailsSelector(state);

    const response = (await axios.post(
      `/accounts/${account?.id}/financialPlatforms/request`,
      { platformName, connectionType },
      { headers },
    )) as AxiosResponse<FinancialPlatformType>;

    return response.data;
  },
);

export const removeRequestedFinancialPlatformConnection = createAsyncThunk<
  FinancialPlatformType,
  { platformName: string; connectionType: string },
  { state: RootState }
>(
  'financialPlatformSlice/removeRequestedFinancialPlatform',
  async ({ platformName, connectionType }, { getState }) => {
    const state = getState();
    const headers = getRequestHeaders(state);
    const account = companyDetailsSelector(state);

    const response = (await axios.delete(`/accounts/${account?.id}/financialPlatforms/request`, {
      headers,
      data: {
        platformName,
        connectionType,
      },
    })) as AxiosResponse<FinancialPlatformType>;

    return response.data;
  },
);

export const requestLeanTechConnection = createAsyncThunk<
  LeanTechData,
  undefined,
  { state: RootState }
>(
  'financialPlatformSlice/requestLeanTechConnection',
  async (_, { getState, dispatch, rejectWithValue }) => {
    try {
      const state = getState();
      const headers = getRequestHeaders(state);
      const account = companyDetailsSelector(state);

      const response = (await axios.post(`/leantech/customer/${account?.id}`, null, {
        headers,
      })) as AxiosResponse<LeanTechData>;

      return response.data;
    } catch (err) {
      dispatch(
        addNotification({
          severity: 'error',
        }),
      );
      return rejectWithValue(null);
    }
  },
);

export const getConnectedLeanTechBanks = createAsyncThunk<
  Array<LeanTechBankData>,
  undefined,
  { state: RootState }
>(
  'financialPlatformSlice/getConnectedLeanTechBanks',
  async (_, { getState, dispatch, rejectWithValue }) => {
    try {
      const state = getState();
      const headers = getRequestHeaders(state);
      const account = companyDetailsSelector(state);

      const response = (await axios.get(`/leantech/connectedBanks/${account?.id}`, {
        headers,
      })) as AxiosResponse<Array<LeanTechBankData>>;
      return response.data;
    } catch (err) {
      dispatch(
        addNotification({
          severity: 'error',
        }),
      );
      return rejectWithValue(null);
    }
  },
);
