import { envToBool } from './envToBool';

export const getIsLeanTechEnabled = () => {
  const leanTechValue = localStorage.getItem('leantech');

  if (leanTechValue) {
    return envToBool(leanTechValue);
  }

  return true;
};
