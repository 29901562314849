import React, { useState, useRef } from 'react';
import { TextField, Button, PasswordValidator, Checkbox, Typography } from 'components';
import { useAuth } from 'hooks';
import { emailRegex } from 'Constants';
import { useTranslate } from 'react-polyglot';
import { Box } from '@mui/material';
import { websiteUrl } from 'portal.config';

const initialFormData = {
  firstName: '',
  lastName: '',
  middleName: '',
  email: '',
  password: '',
  confirmPassword: '',
  acceptedTerms: false,
};

type InputNames = keyof typeof initialFormData;

const inputFields = [
  { id: 'firstName', name: 'First Name' },
  { id: 'lastName', name: 'Last Name' },
  { id: 'middleName', name: 'Middle Name (Optional)' },
  { id: 'email', name: 'Email' },
  { id: 'password', name: 'Password' },
  { id: 'confirmPassword', name: 'Confirm Password' },
] as const;

const getInputType = (id: string) => {
  switch (id) {
    case 'password':
    case 'confirmPassword':
      return 'password';
    case 'email':
      return 'email';
    default:
      return 'text';
  }
};

const SignUpForm = () => {
  const t = useTranslate();
  const { signUp } = useAuth();
  const [formData, setFormData] = useState(initialFormData);
  const [fieldHelpers, setFieldHelpers] = useState(initialFormData);
  const passwordInputRef = useRef<HTMLDivElement | null>(null);
  const [openPopper, setOpenPopper] = useState(false);

  const setFieldHelperContent = (name: InputNames, content: string) => {
    setFieldHelpers((fieldHelpers) => ({ ...fieldHelpers, [name]: content }));
  };

  const handleValidateInput = (name: InputNames, value: string) => () => {
    handleClosePopper();
    switch (name) {
      case 'firstName':
        if (value.length === 0) {
          setFieldHelperContent(name, 'First name is mandatory!');
        }
        break;
      case 'lastName':
        if (value.length === 0) {
          setFieldHelperContent(name, 'Last name is mandatory!');
        }
        break;
      case 'email':
        if (!emailRegex.test(value)) {
          setFieldHelperContent(name, 'Email is not valid!');
        }
        break;
      case 'confirmPassword':
        if (value.length > 0 && value !== formData.password) {
          setFieldHelperContent(name, 'Password and confirm password must match!');
        }
        break;
      default:
        break;
    }
  };

  const handleInputChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const inputName = evt.target.name as InputNames;
    if (fieldHelpers[inputName]) {
      setFieldHelperContent(inputName, '');
    }
    setFormData((formData) => ({ ...formData, [inputName]: evt.target.value }));
  };

  const handleFormSubmit = async (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();

    await signUp(formData);
  };

  const isFormValid = () => {
    const { confirmPassword, firstName, email, lastName, password, acceptedTerms } = formData;

    const isFirstNameValid = firstName.length > 0;
    const isLastNameValid = lastName.length > 0;
    const isPasswordValid = password.length > 7;
    const isEmailValid = emailRegex.test(email);
    const isPasswordConfirmed = password === confirmPassword;

    return (
      isFirstNameValid &&
      isLastNameValid &&
      isPasswordValid &&
      isEmailValid &&
      isPasswordConfirmed &&
      acceptedTerms
    );
  };

  const handleOpenPopper = () => {
    setOpenPopper(true);
  };

  const handleClosePopper = () => {
    if (!openPopper) return;
    setOpenPopper(false);
  };

  const handleCheck = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = evt.target;
    setFormData((formData) => ({ ...formData, [name]: value }));
  };

  return (
    <form onSubmit={handleFormSubmit} style={{ position: 'relative' }}>
      {inputFields.map((input) => {
        const isPasswordField = input.id === 'password';
        const inputValue = formData[input.id];
        const fieldHelperText = fieldHelpers[input.id];
        return (
          <TextField
            key={input.id}
            name={input.id}
            label={input.name}
            value={inputValue}
            fullWidth
            onChange={handleInputChange}
            size='small'
            type={getInputType(input.id)}
            ref={isPasswordField ? passwordInputRef : undefined}
            onFocus={isPasswordField ? handleOpenPopper : undefined}
            onBlur={handleValidateInput(input.id, inputValue)}
            error={fieldHelperText.length > 0}
            helperText={fieldHelperText}
            inputProps={{ 'data-testid': input.id }}
          />
        );
      })}
      <Box display='flex' width='100%' gap='5px' alignItems='center'>
        <Checkbox name={'acceptedTerms'} checked={formData.acceptedTerms} onChange={handleCheck} />
        <Typography variant='body2' alignSelf={'start'}>
          {t('signup.acceptMessage')}{' '}
          <a
            style={{ lineHeight: 1 }}
            href={`${websiteUrl}/terms`}
            rel='noreferrer'
            target='_blank'
          >
            {t('signup.termsAndConditions')}
          </a>
        </Typography>
      </Box>
      <Button type='submit' variant='contained' disabled={!isFormValid()}>
        {t('global.submit')}
      </Button>
      <PasswordValidator
        value={formData.password}
        open={openPopper}
        anchorEl={passwordInputRef.current}
      />
    </form>
  );
};

export default SignUpForm;
