import MuiTableCell from '@mui/material/TableCell';
import MuiTableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import { alpha, styled } from '@mui/material';

export const TableCell = styled(MuiTableCell)({
  padding: '1rem 0',
  borderBottom: 'none',
});

export const TableRow = styled(MuiTableRow)(({ theme }) => ({
  borderBottom: `1px solid ${alpha(theme.palette.common.white, 0.15)}`,
}));

export const MobileListContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '1.5rem',
}));

export const MobileListItem = styled(Box)(() => ({
  minWidth: '19rem',
  width: '90%',

  '& p': {
    maxWidth: 'calc(60% - 20px)',
  },

  '& button': {
    padding: 0,
    marginLeft: 'auto',
  },
}));
