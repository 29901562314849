import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material';
import palette from 'theme/palette/palette';

export const MuiTypography: Components<Theme>['MuiTypography'] = {
  styleOverrides: {
    root: {
      color: palette.common.white,
    },
    h1: {
      color: palette.common.white,
    },
    body1: {
      color: palette.lightGrey.main,
    },
    body2: {
      color: palette.lightGrey.main,
    },
  },
};
