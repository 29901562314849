import { useCallback, useMemo } from 'react';
import { useDispatch } from 'hooks';
import { addNotification } from 'store/slices/alertSlice';

export const useNotification = () => {
  const dispatch = useDispatch();

  const notifySuccess = useCallback(
    (message = 'Success !') => dispatch(addNotification({ message, severity: 'success' })),
    [],
  );

  const notifyError = useCallback(
    (message = 'Something went wrong, please try again!') =>
      dispatch(addNotification({ message, severity: 'error' })),
    [],
  );

  const notifyWarning = useCallback(
    (message: string) => dispatch(addNotification({ message, severity: 'warning' })),
    [],
  );

  const returnObj = useMemo(() => ({ notifySuccess, notifyWarning, notifyError }), []);

  return returnObj;
};
