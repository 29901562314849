import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store';

const appDataRootStateSelector = (state: RootState) => state.appDataSlice;

export const persistedRouteSelector = createSelector(
  appDataRootStateSelector,
  (appData) => appData.persistedRoute,
);

export const globalLoadingSelector = createSelector(
  appDataRootStateSelector,
  (appData) => appData.globalLoading,
);

export const appDataSelfSelector = createSelector(
  appDataRootStateSelector,
  (appData) => appData.self,
);

export const appDataEmbedSelector = createSelector(
  appDataSelfSelector,
  (appSelf) => appSelf?._embedded,
);

export const appDataProductAmountSelector = createSelector(appDataEmbedSelector, (selfEmbed) => {
  const productAmountObj = selfEmbed?.productAmount;

  if (!productAmountObj) return [{ name: 'No data', value: 0 }];
  const mappedProductAmount = Object.keys(productAmountObj).map((productKey) => ({
    value: parseInt(productKey, 10),
    name: productAmountObj[productKey],
  }));

  return mappedProductAmount;
});

export const appDataMrrOptionsSelector = createSelector(appDataEmbedSelector, (selfEmbed) => {
  const productAmountObj = selfEmbed?.mrr;

  if (!productAmountObj) return [{ name: 'No data', value: 0 }];
  const mappedProductAmount = Object.keys(productAmountObj).map((productKey) => ({
    value: parseInt(productKey, 10),
    name: productAmountObj[productKey],
  }));

  return mappedProductAmount;
});

export const appDataIndustryOptionsSelector = createSelector(appDataEmbedSelector, (selfEmbed) => {
  const industryArr = selfEmbed?.industry;

  if (!industryArr) {
    return [{ name: 'No data', value: 0 }];
  }

  const mappedIndustryOptions = industryArr.map((industry) => ({
    name: industry,
    value: industry,
  }));

  return mappedIndustryOptions;
});

export const appDataLinksSelector = createSelector(
  appDataSelfSelector,
  (appSelf) => appSelf?._links,
);

export const appDataErrorSelector = createSelector(
  appDataRootStateSelector,
  (appData) => appData.error,
);
