import { Routes, Route, Navigate } from 'react-router-dom';
import { AppLayout, ErrorBoundary } from 'components';
import { ThankYouPage, Dashboard, Application, AccountSettings } from 'pages';

const Content = () => {
  return (
    <ErrorBoundary>
      <Routes>
        <Route path='/' element={<AppLayout />}>
          <Route index element={<Dashboard />} />
          <Route path='/thank-you' element={<ThankYouPage />} />
          <Route path='*' element={<Navigate to='/' />} />
        </Route>
        <Route path='account/*' element={<AccountSettings />} />
        <Route path='application/:applicationId' element={<Application />} />
        <Route path='application' element={<Application />} />
      </Routes>
    </ErrorBoundary>
  );
};

export default Content;
