import { showCodatIntegration } from 'portal.config';

const navigationSelectorOpts = [
  { name: 'Personal info', value: '/account' },
  { name: 'Security settings', value: '/account/security' },
  { name: 'Company details', value: '/account/company' },
  { name: 'Banking details', value: '/account/banking-details' },
];

if (showCodatIntegration) {
  navigationSelectorOpts.push({
    name: 'Financial platforms',
    value: '/account/financial-platforms',
  });
}

export { navigationSelectorOpts };
