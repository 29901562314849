export const defaultRoute = '/';

export const emailRegex = /^\S+@\S+\.\S+$/;

export const PasswordLength = 8;

export const HeaderKeys = {
  Authorization: 'Authorization',
  Accept: 'accept',
  'Access-Control-Allow-Origin': 'Access-Control-Allow-Origin',
};

export const IBANLength = 34;

export const CapitalLetterRegex = /[A-Z]/;
export const NumberRegex = /[0-9]/;
export const SymbolRegex = /[!@#$%^&*-]/;

export const admittedFileSize = 11;

export const ApplicationStatuses = [
  'new_application',
  'financial_documents',
  'waiting_offer',
  'offer_selection',
  'kyc_documents',
  'banking_details',
  'contract_signing',
  'complete',
] as const;

export const AccountingPlatforms = [
  'Dynamics 365 Business Central',
  'QuickBooks Online',
  'Oracle NetSuite',
  'Xero',
  'Sage Intacct',
  'FreshBooks',
  'Wave',
  'Clear Books',
  'KashFlow',
];

export const CurrenciesMap = {
  USD: '$',
};

export const DefaultLeanTechPermissions = ['accounts', 'balance', 'transactions'];
