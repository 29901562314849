import React from 'react';
import { Button as MuiButton, ButtonProps, CircularProgress } from '@mui/material';

export type Props = ButtonProps & {
  isLoading?: boolean;
};

const Button = ({ onClick, isLoading, ...props }: Props) => {
  const handleClick = (evt: React.MouseEvent<HTMLButtonElement>) => {
    if (isLoading) return;
    onClick?.(evt);
  };

  return (
    <MuiButton {...props} onClick={handleClick}>
      <>
        {isLoading ? (
          <CircularProgress
            sx={{ color: 'white', width: '17.5px !important', height: '17.5px !important' }}
          />
        ) : (
          props.children
        )}
      </>
    </MuiButton>
  );
};

export default Button;
