import React from 'react';
import Box from '@mui/material/Box';
import { AppLayout, Typography } from 'components';
import { ErrorIcon } from 'assets';

type Props = {
  children?: React.ReactNode;
};

export const ErrorPage = (props: Props) => {
  return (
    <AppLayout>
      <Box
        display='flex'
        justifyContent={'center'}
        flexDirection={'column'}
        alignItems='center'
        width='100%'
        height='100%'
        gap='1rem'
      >
        <ErrorIcon />
        <Typography variant='h1' fontSize={'4rem'}>
          Oops ! Something went wrong !
        </Typography>
        <Typography variant='body1'>
          An unexpected error has happened. Please try again later.
        </Typography>
        {props.children}
      </Box>
    </AppLayout>
  );
};
