import { styled } from '@mui/material';

export const List = styled('ul')({
  listStyle: 'none',
  padding: 0,
  marginBottom: 0,
});

interface ListItemProps {
  completed?: boolean;
}

export const ListItem = styled('li', {
  shouldForwardProp: (prop) => prop !== 'completed',
})<ListItemProps>(({ theme, completed }) => ({
  color: completed ? theme.palette.common.white : theme.palette.lightGrey.main,
  fontSize: '1rem',
  fontStyle: 'IBM Plex Sans',
  display: 'flex',
  alignItems: 'center',

  '& svg': {
    fill: completed ? theme.palette.success.main : theme.palette.lightGrey.main,
    marginRight: '.5rem',
    width: '1.2rem',
    height: '1.2rem',
  },

  '&:not(:last-child)': {
    marginBottom: '.5rem',
  },
}));
