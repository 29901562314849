import { TabOption } from 'Portal Types';
import React from 'react';
import { StyledTabs, Wrapper } from './PageTabs.styles';
import { Tab } from '@mui/material';

type Props = {
  onChange(value: string): void;
  value: string;
  options: TabOption[];
};

export const PageTabs = ({ onChange, value, options }: Props) => {
  const handleTabsChange = (_: React.SyntheticEvent, value: string) => {
    if (!value) return;
    onChange(value);
  };

  return (
    <Wrapper>
      <StyledTabs indicatorColor='secondary' value={value} onChange={handleTabsChange}>
        {options.map(({ label, value }, idx) => (
          <Tab key={idx} label={label} value={value} />
        ))}
      </StyledTabs>
    </Wrapper>
  );
};
