import { useMemo } from 'react';
import { KYC_COLLECT, applicationViews } from './Application.config';

export const useFilteredApplicationViews = (isSingleStep?: boolean) => {
  const filteredApplicationViews = useMemo(() => {
    if (isSingleStep) {
      return applicationViews.filter((view) => view.id !== KYC_COLLECT);
    }
    return applicationViews;
  }, [isSingleStep]);

  return filteredApplicationViews;
};
