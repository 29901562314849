import { styled } from 'theme';
import RawTypography from 'components/common/Typography/Typography';

export const Wrapper = styled('div')`
  background-color: ${(props) => props.theme.palette.darkBlue.light};
  width: 100%;
  width: 900px;
  padding: 2rem;
  border-radius: 8px;
  height: 85vh;
  max-height: 85vh;
  overflow-y: auto;
`;

export const Typography = styled(RawTypography)`
  margin: 1rem 0;
`;

export const FlexWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 1rem;
`;
