import { forwardRef } from 'react';
import { Typography } from 'components';
import { TextField as MuiTextField, TextFieldProps, Box, styled } from '@mui/material';

type Props = TextFieldProps;

const Input = styled(MuiTextField)({
  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
    display: 'none',
  },
  '& input[type=number]': {
    MozAppearance: 'textfield',
  },
});

const TextField = forwardRef<HTMLDivElement, Props>(
  ({ label, fullWidth, ...props }: Props, ref) => {
    return (
      <Box width={fullWidth ? '100%' : '18rem'}>
        {label && (
          <Box marginBottom={'.4rem'}>
            <Typography fontSize='14px' lineHeight={1} sx={{ color: '#aaa' }}>
              {label}
            </Typography>
          </Box>
        )}
        <Input fullWidth {...props} ref={ref} />
      </Box>
    );
  },
);

TextField.displayName = 'TextField';

export default TextField;
