import { Popper, Paper, PopperProps } from '@mui/material';
import { Typography } from 'components';
import { List, ListItem } from './PasswordValidator.styles';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import { NumberRegex, SymbolRegex, CapitalLetterRegex } from 'Constants';

type Props = PopperProps & {
  value: string;
};

export const PasswordValidator = ({ value, placement = 'bottom-start', ...props }: Props) => {
  const validateInput = (regex: RegExp) => {
    return Boolean(value.length > 0 && value.match(regex));
  };

  return (
    <Popper
      {...props}
      placement={placement}
      sx={{ width: '100%' }}
      modifiers={[{ name: 'arrow', enabled: true }]}
      disablePortal
    >
      <Paper
        sx={{
          bgcolor: 'darkBlue.main',
          padding: '1.5rem',
          borderRadius: '.5rem',
          marginTop: '.75rem',
        }}
      >
        <Typography variant='body1' sx={{ color: 'common.white' }}>
          Password should have
        </Typography>
        <List>
          <ListItem completed={value.length > 7 && value.length < 21}>
            <TaskAltOutlinedIcon />
            From 8 to 20 characters
          </ListItem>
          <ListItem completed={validateInput(NumberRegex)}>
            <TaskAltOutlinedIcon />
            At least one digit
          </ListItem>
          <ListItem completed={validateInput(CapitalLetterRegex)}>
            <TaskAltOutlinedIcon />
            At least one uppercase letter
          </ListItem>
          <ListItem completed={validateInput(SymbolRegex)}>
            <TaskAltOutlinedIcon />
            At least one symbol
          </ListItem>
        </List>
      </Paper>
    </Popper>
  );
};
