import React, { useRef, useState } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { TextField, Typography, Button, PasswordValidator } from 'components';
import { useAuth } from 'hooks';
import { useTranslate } from 'react-polyglot';

const initialFormState = {
  code: '',
  password: '',
  confirmPassword: '',
};

type InputNames = keyof typeof initialFormState;

type LocationState = {
  username: string;
};

const ForgotPasswordStepTwo = () => {
  const t = useTranslate();
  const { username } = useLocation().state as LocationState;
  const { confirmForgotPassword } = useAuth();
  const [formData, setFormData] = useState(initialFormState);
  const [fieldHelpers, setFieldHelpers] = useState(initialFormState);
  const passwordInputRef = useRef<HTMLDivElement | null>(null);
  const [openPopper, setOpenPopper] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputName = e.target.name as InputNames;
    if (fieldHelpers[inputName]) {
      setFieldHelperContent(inputName, '');
    }
    setFormData((formData) => ({ ...formData, [inputName]: e.target.value }));
  };

  const handleClick = () => {
    confirmForgotPassword(username, formData.code, formData.confirmPassword);
  };

  const isFormValid = () => {
    const { code, password, confirmPassword } = formData;
    const isCodeValid = code.length > 0;
    const isPasswordValid = password.length > 7;
    const isPasswordConfirmed = password === confirmPassword;

    return isCodeValid && isPasswordValid && isPasswordConfirmed;
  };

  const setFieldHelperContent = (name: InputNames, content: string) => {
    setFieldHelpers((fieldHelpers) => ({ ...fieldHelpers, [name]: content }));
  };

  const handleValidateInput = (name: InputNames, value: string) => () => {
    switch (name) {
      case 'code':
        if (value.length === 0) {
          setFieldHelperContent(name, 'Code is mandatory!');
        }
        break;
      case 'confirmPassword':
        if (formData.password.length > 0 && value !== formData.password) {
          setFieldHelperContent(name, 'Passwords must match!');
        }
        break;

      default:
        break;
    }
  };

  const handleClosePopper = () => {
    setOpenPopper(false);
  };

  const handleOpenPopper = () => {
    setOpenPopper(true);
  };

  return (
    <div>
      <form>
        <Typography variant='h1'>{t('newPassword.title')}</Typography>
        <TextField
          label={t('newPassword.confirmationCode')}
          value={formData.code}
          onChange={handleChange}
          name='code'
          fullWidth
          size='small'
          error={Boolean(fieldHelpers.code)}
          onBlur={handleValidateInput('code', formData.code)}
          helperText={fieldHelpers.code}
          type='number'
        />
        <TextField
          label={t('global.newPassword')}
          value={formData.password}
          onChange={handleChange}
          name='password'
          fullWidth
          size='small'
          type='password'
          onFocus={handleOpenPopper}
          onBlur={handleClosePopper}
          ref={passwordInputRef}
        />
        <TextField
          label={t('newPassword.confirmPassword')}
          value={formData.confirmPassword}
          onChange={handleChange}
          name='confirmPassword'
          fullWidth
          size='small'
          type='password'
          error={Boolean(fieldHelpers.confirmPassword)}
          helperText={fieldHelpers.confirmPassword}
          onBlur={handleValidateInput('confirmPassword', formData.confirmPassword)}
        />
        <Button variant='contained' onClick={handleClick} disabled={!isFormValid()}>
          {t('global.confirm')}
        </Button>
        <Link to={'/'}>{t('signin.signin')}</Link>
        <PasswordValidator
          open={openPopper}
          value={formData.password}
          anchorEl={passwordInputRef.current}
        />
      </form>
    </div>
  );
};

export default ForgotPasswordStepTwo;
