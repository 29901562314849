import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/store';

const financialPlatformsRootSelector = (state: RootState) => state.financialPlatformsSlice;

export const financialPlatformsSelector = createSelector(
  financialPlatformsRootSelector,
  (state) => state.data,
);

export const financialPlatformsTabSelector = createSelector(
  financialPlatformsRootSelector,
  (state) => state.initialTab,
);

export const financialAccountingPlatformsSelector = createSelector(
  financialPlatformsSelector,
  (financialPlatforms) => financialPlatforms.accountingConnections,
);

export const selectedFinancialAccountingPlatformsSelector = createSelector(
  financialAccountingPlatformsSelector,
  (accountingPlatforms) => ({
    available: accountingPlatforms.available.filter(
      (platform) => platform.status === 'Linked' || platform.status === 'Requested',
    ),
    onDemand: accountingPlatforms.onDemand.filter(
      (platform) => platform.status === 'Linked' || platform.status === 'Requested',
    ),
  }),
);

export const financialPaymentPlatformsSelector = createSelector(
  financialPlatformsSelector,
  (financialPlatforms) => financialPlatforms.paymentConnections,
);

export const selectedFinancialPaymentPlatformsSelector = createSelector(
  financialPaymentPlatformsSelector,
  (paymentPlatforms) => ({
    available: paymentPlatforms.available.filter(
      (platform) => platform.status === 'Linked' || platform.status === 'Requested',
    ),
    onDemand: paymentPlatforms.onDemand.filter(
      (platform) => platform.status === 'Linked' || platform.status === 'Requested',
    ),
  }),
);

export const areFinancialPlatformsLoadingSelector = createSelector(
  financialPlatformsRootSelector,
  (state) => state.isLoading,
);

export const isFinancialPlatformModalOpenSelector = createSelector(
  financialPlatformsRootSelector,
  (state) => state.isModalOpen,
);

export const isFinancialPlatformModalLoadingSelector = createSelector(
  financialPlatformsRootSelector,
  (state) => state.isModalLoading,
);

export const connectedLeanTechBanksSelector = createSelector(
  financialPlatformsRootSelector,
  (state) => state.connectedBanks,
);
