import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import axios from 'axiosConfig';
import { HeaderKeys } from 'Constants';
import { setAppDataError } from 'store/slices/appDataSlice';
import { RootState } from 'store/store';
import { Self, ServerError } from 'types';
import { getRequestHeaders } from 'utils/getRequestHeaders';

export const getSelfData = createAsyncThunk<
  Self,
  string | undefined,
  {
    state: RootState;
    rejectValue: null;
  }
>('appDataSlice/getSelfData', async (token, { getState, dispatch, rejectWithValue }) => {
  try {
    const store = getState();
    const headers = getRequestHeaders(store);

    if (token) {
      headers[HeaderKeys.Authorization] = `Bearer ${token}`;
    }

    const response = await axios.get('/self', {
      headers,
    });

    return response.data as Self;
  } catch (error) {
    const serverError = error as AxiosError<ServerError>;
    dispatch(setAppDataError({ status: serverError?.response?.status ?? 1 }));
    return rejectWithValue(null);
  }
});
