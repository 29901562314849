import { Link } from 'react-router-dom';
import { SignUpForm, Typography, Button, DividerWithWord } from 'components';
import { HorizontalProgress } from 'components/organisms/HorizontalProgress';
import { authSteps } from '../Authentication.config';
import { useTranslate } from 'react-polyglot';
import { GoogleIcon } from 'assets';
import { Box } from '@mui/material';
import { useAuth } from 'hooks';
import { allowGoogleLogin } from 'portal.config';

type Props = {
  propsAllowGoogleLogin?: boolean;
};

const SignUpPage = ({ propsAllowGoogleLogin }: Props) => {
  const { federatedSignIn } = useAuth();
  const t = useTranslate();

  const showGoogleLogin = propsAllowGoogleLogin ?? allowGoogleLogin;

  return (
    <div>
      <Box height='50px' width='100%' />
      <Typography variant='h1'>{t('signup.signup')}</Typography>
      <HorizontalProgress options={authSteps} highlightOptions={1} />
      {showGoogleLogin && (
        <>
          <Button variant='outlined' startIcon={<GoogleIcon />} onClick={federatedSignIn}>
            {t('signup.googleSignUp')}
          </Button>
          <DividerWithWord name={t('global.or')} />
        </>
      )}
      <SignUpForm />
      <Box display='flex' gap='0.3rem'>
        <Typography fontSize={'0.875rem'}>{t('signup.alreadyRegistered')}</Typography>
        <Link to={'/'}>{t('signin.signin')}</Link>
      </Box>
    </div>
  );
};

export default SignUpPage;
