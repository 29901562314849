import { IconButton as MuiIconButton, IconButtonProps } from '@mui/material';
import { forwardRef } from 'react';

type Props = IconButtonProps;

const IconButton = forwardRef<HTMLButtonElement, Props>(({ children, ...props }: Props, ref) => {
  return (
    <MuiIconButton ref={ref} {...props}>
      {children}
    </MuiIconButton>
  );
});

IconButton.displayName = 'IconButton';

export { IconButton };
