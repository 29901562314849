import { Box as MuiBox, styled } from '@mui/material';

export const Wrapper = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '2rem',
  padding: '2rem 3rem',
  width: '100%',
  maxWidth: '600px',

  [theme.breakpoints.down('md')]: {
    maxWidth: 'none',
    padding: '1rem 1.5rem 1.5rem 1.5rem',
  },

  ['& > form']: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1.2rem',
  },
}));
