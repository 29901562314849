import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppErrorState } from 'Portal Types';
import { getSelfData } from 'store/thunks/appDataSliceThunk';
import { Self } from 'types';

interface AppDataState {
  persistedRoute: string | null;
  globalLoading: boolean;
  self: Partial<Self>;
  error: AppErrorState | null;
}

const initialState: AppDataState = {
  persistedRoute: null,
  globalLoading: true,
  self: {
    _links: {},
  },
  error: null,
};

export const appDataSlice = createSlice({
  name: 'appDataSlice',
  initialState,
  reducers: {
    setPersistedRoute(state, action: PayloadAction<string | null>) {
      state.persistedRoute = action.payload;
    },
    setGlobalLoading(state, action: PayloadAction<boolean>) {
      state.globalLoading = action.payload;
    },
    resetAppDataState(state) {
      state.error = initialState.error;
    },
    setAppDataError(state, action: PayloadAction<AppErrorState>) {
      state.error = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(getSelfData.fulfilled, (state, action) => {
      state.self = action.payload;
    });
  },
});

export const { setPersistedRoute, setGlobalLoading, setAppDataError } = appDataSlice.actions;

export default appDataSlice.reducer;
