import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NotificationType } from 'Portal Types';
import { v4 as uuid } from 'uuid';

type AlertState = {
  notifications: NotificationType[];
};

const initialState: AlertState = {
  notifications: [],
};

const alertSlice = createSlice({
  name: 'alertSlice',
  initialState,
  reducers: {
    addNotification(state, action: PayloadAction<NotificationType>) {
      const { severity, message } = action.payload;
      const uniqueId = uuid();
      const notification: NotificationType = {
        id: uniqueId,
        severity,
        message: message ?? 'Something went wrong, please try again!',
      };
      state.notifications = [...state.notifications, notification];
    },
    dropNotification(state, action: PayloadAction<string>) {
      state.notifications = state.notifications.filter(
        (notification) => notification.id !== action.payload,
      );
    },
  },
});

export const { dropNotification, addNotification } = alertSlice.actions;

export default alertSlice.reducer;
