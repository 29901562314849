import { styled } from '@mui/material';
import MuiIconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';

export const IconButton = styled(MuiIconButton)(() => ({
  padding: 0,
  '& svg': {
    width: '2rem',
    height: '2rem',
  },
}));

interface StepProps {
  disabled: boolean;
  marked: boolean;
}

export const Step = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'disabled' && prop !== 'marked',
})<StepProps>(({ theme, disabled, marked }) => ({
  cursor: 'pointer',
  height: '0.4rem',
  width: '100%',
  backgroundColor: disabled
    ? theme.palette.darkBlue.main
    : marked
    ? theme.palette.common.white
    : theme.palette.lightBlue.light,
}));
