import Box from '@mui/material/Box';
import { styled } from '@mui/material';

interface WrapperProps {
  isDragged: boolean;
  shrink: boolean;
}

export const Wrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isDragged' && prop !== 'shrink',
})<WrapperProps>(({ theme, isDragged, shrink }) => ({
  padding: `${theme.spacing(shrink ? 2 : 12)} 0`,
  minHeight: `${shrink ? 0 : '270px'}`,
  borderRadius: `${theme.shape.borderRadius}px`,
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: shrink ? 'row' : 'column',
  gap: '1rem',
  backgroundImage: `
        repeating-linear-gradient(0deg, ${theme.palette.lightGrey.main}, ${theme.palette.lightGrey.main} 15px, transparent 15px, transparent 26px, ${theme.palette.lightGrey.main} 26px),
        repeating-linear-gradient(90deg, ${theme.palette.lightGrey.main}, ${theme.palette.lightGrey.main} 15px, transparent 15px, transparent 26px, ${theme.palette.lightGrey.main} 26px),
        repeating-linear-gradient(180deg, ${theme.palette.lightGrey.main}, ${theme.palette.lightGrey.main} 15px, transparent 15px, transparent 26px, ${theme.palette.lightGrey.main} 26px), 
        repeating-linear-gradient(270deg, ${theme.palette.lightGrey.main}, ${theme.palette.lightGrey.main} 15px, transparent 15px, transparent 26px, ${theme.palette.lightGrey.main} 26px)`,
  backgroundSize:
    '1px calc(100% + 26px), calc(100% + 26px) 1px, 1px calc(100% + 26px) , calc(100% + 26px) 1px',
  backgroundPosition: '0 0, 0 0, 100% 0, 0 100%',
  backgroundRepeat: 'no-repeat',
  cursor: 'pointer',

  '@keyframes borderAnimation': {
    from: {
      backgroundPosition: '0 0, -26px 0, 100% -26px, 0 100%',
    },
    to: {
      backgroundPosition: '0 -26px, 0 0, 100% 0, -26px 100%',
    },
  },

  animation: isDragged ? 'borderAnimation 0.7s infinite linear' : undefined,

  '& .MuiCircularProgress-root': {
    width: 'unset !important',
    height: 'unset !important',
  },

  '& svg': {
    width: shrink ? '1.5rem' : '2rem',
    height: shrink ? '1.5rem' : '2rem',
    fill: theme.palette.common.white,
    transform: 'rotate(-90deg)',
  },

  '& .hidden': {
    display: 'none',
  },
}));

export const Cover = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  borderRadius: theme.shape.borderRadius + 'px',
}));
