import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material';

export const MuiTextField: Components<Theme>['MuiTextField'] = {
  defaultProps: {
    size: 'small',
    fullWidth: true,
  },
  styleOverrides: {
    root: ({ theme }) => ({
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: '#404859 !important',
      },

      '& .MuiFormHelperText-root': {
        color: '#aaa',
        fontSize: '.8rem',
        margin: '0.4rem 0 0 0',
        lineHeight: 1,
      },

      '& .MuiOutlinedInput-root': {
        padding: '0 !important',
        '&.Mui-focused fieldset': {
          borderColor: 'white !important',
          borderWidth: '1px',
        },

        '&.Mui-disabled fieldset': {
          borderColor: '#151922 !important',
        },
      },

      '& .MuiOutlinedInput-input': {
        color: theme.palette.common.white,
        backgroundColor: '#171D2C',
        borderRadius: '.5rem',
        fontSize: '.9rem',
        padding: '.625rem .75rem',

        '&.Mui-disabled': {
          color: '#4a4a4a',
          textFillColor: '#4a4a4a',
          backgroundColor: '#151922',
        },

        '&:hover': {
          backgroundColor: '#2b3142',
        },
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#404859',
        borderRadius: '.5rem',
        borderWidth: '1px',
      },
    }),
  },
};
