import React from 'react';
import { Typography } from 'components';
import { Collapse, Box, Radio } from '@mui/material';

type Props = {
  expand: boolean;
  invoicePeriod: '30' | '60' | '90';
  handlePeriodChange(e: React.MouseEvent<HTMLDivElement>): void;
};

export const Submenu = ({ expand, invoicePeriod, handlePeriodChange }: Props) => {
  return (
    <Collapse in={expand}>
      <Box
        border='1px solid #fff'
        borderTop='none'
        padding={1}
        display='flex'
        alignItems='center'
        sx={{ cursor: 'pointer' }}
        id='30'
        onClick={handlePeriodChange}
      >
        <Radio checked={invoicePeriod === '30'} />
        <Typography variant='body1' lineHeight={1}>
          30 Days
        </Typography>
      </Box>
      <Box
        border='1px solid #fff'
        borderTop='none'
        padding={1}
        display='flex'
        alignItems='center'
        sx={{ cursor: 'pointer' }}
        onClick={handlePeriodChange}
        id='60'
      >
        <Radio checked={invoicePeriod === '60'} />
        <Typography variant='body1' lineHeight={1}>
          60 Days
        </Typography>
      </Box>
      <Box
        border='1px solid #fff'
        borderTop='none'
        padding={1}
        display='flex'
        alignItems='center'
        borderRadius='0 0 .5rem .5rem'
        sx={{ cursor: 'pointer' }}
        id='90'
        onClick={handlePeriodChange}
      >
        <Radio checked={invoicePeriod === '90'} />
        <Typography variant='body1' lineHeight={1}>
          90 Days
        </Typography>
      </Box>
    </Collapse>
  );
};
