import { useEffect, useMemo, useState } from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { DocumentsList, FileUpload, PageTabs, Typography, UploadedFilesList } from 'components';
import { useDispatch, useSelector } from 'hooks';
import {
  deleteUploadedDocument,
  downloadDocument,
  getAccountDocuments,
  retryDocumentUpload,
  updateUploadedDocument,
  uploadDocument,
} from 'store/thunks/companySliceThunk';
import { DocumentProvidedWithLinks } from 'types/CompanyDetails.types';
import { dropDocument } from 'store/slices/detailedApplicationSlice';
import {
  companyDocumentsFinancialRequirementsSelector,
  companyDocumentsFinancialsSelector,
  companyDocumentsKycRequirementsSelector,
  companyDocumentsKycSelector,
  companyDocumentsRequirementsSelector,
  companyDocumetsSelector,
} from 'store/selectors/companyDetailsSelector';
import { DocumentProvided } from 'Portal Types';
import { appDataSelfSelector } from 'store/selectors/appDataSelector';

export const AccountDocuments = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  const companyFinancialDocuments = useSelector(companyDocumentsFinancialsSelector);
  const companyKycDocuments = useSelector(companyDocumentsKycSelector);
  const companyDocuments = useSelector(companyDocumetsSelector);

  const companyKycRequirements = useSelector(companyDocumentsKycRequirementsSelector);
  const companyFinancialRequirements = useSelector(companyDocumentsFinancialRequirementsSelector);
  const companyRequirements = useSelector(companyDocumentsRequirementsSelector);

  const { isSubmitDocumentsSingleStep } = useSelector(appDataSelfSelector);

  const [documentStep, setDocumentStep] = useState('financial');

  useEffect(() => {
    dispatch(getAccountDocuments());
  }, []);

  const documentListRequirements = useMemo(() => {
    if (isSubmitDocumentsSingleStep) {
      return companyRequirements;
    } else {
      if (documentStep === 'financial') {
        return companyFinancialRequirements;
      } else {
        return companyKycRequirements;
      }
    }
  }, [
    isSubmitDocumentsSingleStep,
    documentStep,
    companyDocuments.length,
    companyFinancialDocuments.length,
    companyKycDocuments.length,
  ]);

  const requirements = useMemo(() => {
    if (isSubmitDocumentsSingleStep) {
      return [{ name: 'Document requirements', options: companyRequirements }];
    } else {
      if (documentStep === 'financial') {
        return [{ name: 'Financial requirements', options: companyFinancialRequirements }];
      } else {
        return [{ name: 'KYC requirements', options: companyKycRequirements }];
      }
    }
  }, [isSubmitDocumentsSingleStep, documentStep, companyRequirements.length]);

  const documents = useMemo(() => {
    let documents: DocumentProvided[] = [];
    if (isSubmitDocumentsSingleStep) documents = companyDocuments;
    else {
      if (documentStep === 'financial') documents = companyFinancialDocuments;
      else documents = companyKycDocuments;
    }

    return documents;
  }, [
    isSubmitDocumentsSingleStep,
    documentStep,
    companyDocuments.length,
    companyFinancialDocuments.length,
    companyKycDocuments.length,
  ]);

  const handleFiles = (files: FileList) => {
    for (let i = 0; i < files.length; i++) {
      const file = files.item(i);
      if (!file) return;

      dispatch(
        uploadDocument({
          file,
          startLoading: i === 0,
          stopLoading: i === files.length - 1,
          isSingleItem: files.length === 1,
        }),
      );
    }
  };

  const retryUpload = (document: DocumentProvidedWithLinks) => {
    dispatch(retryDocumentUpload(document));
  };

  const handleDownload = async (document: DocumentProvidedWithLinks) => {
    await dispatch(downloadDocument(document));
  };

  const handleUpdateDocumentType = (id: string, requirementId: string) => {
    dispatch(updateUploadedDocument({ documentId: id, requirementId }));
  };

  const handleDelete = (id: string) => {
    dispatch(deleteUploadedDocument(id));
  };

  const handleDropDocumentFromMemory = (id: string) => {
    dispatch(dropDocument(id));
  };

  return (
    <Box paddingY='2rem' paddingX='2.5rem' display='flex' flexDirection='column' gap={3}>
      <Typography variant='h1'>Account documents</Typography>
      {isSubmitDocumentsSingleStep ? null : (
        <Box>
          <PageTabs
            options={[
              { label: 'Financial', value: 'financial' },
              { label: 'KYC', value: 'kyc' },
            ]}
            onChange={setDocumentStep}
            value={documentStep}
          />
        </Box>
      )}
      <Typography variant='body1' color={theme.palette.common.white}>
        Almost there! Please upload the below company documents to verify your business entity.
      </Typography>
      <DocumentsList requirements={documentListRequirements} />
      <Box display='flex' alignItems='center'></Box>
      <FileUpload shrink={matches} handleFiles={handleFiles} />
      <UploadedFilesList
        documents={documents}
        requirements={requirements}
        onDelete={handleDelete}
        onUpdateType={handleUpdateDocumentType}
        onRetryUpload={retryUpload}
        onDownload={handleDownload}
        onDropFile={handleDropDocumentFromMemory}
      />
    </Box>
  );
};
