import React from 'react';
import DoneIcon from '@mui/icons-material/Done';
import { SelectedButton } from './SelectableButton.styles';
import { Props as ButtonProps } from '../Button/Button';

type Props = ButtonProps & {
  selected?: boolean;
  disabled?: boolean;
  label?: string;
  selectedLabel?: string;
};

export const SelectableButton = ({
  selected = false,
  disabled,
  onClick,
  label,
  selectedLabel,
  children,
  ...restProps
}: Props) => {
  const handleSelect = (e: React.MouseEvent<HTMLButtonElement>) => {
    onClick?.(e);
  };

  return (
    <SelectedButton
      selected={selected}
      variant={'outlined'}
      endIcon={selected ? <DoneIcon /> : null}
      onClick={handleSelect}
      disableRipple
      disabled={disabled}
      {...restProps}
    >
      {selected ? selectedLabel ?? label : children ?? label}
    </SelectedButton>
  );
};
