import { CThemeColors } from 'theme/theme';

const palette: CThemeColors = {
  primary: {
    light: '#9CBDE3',
    main: '#0036B3',
    dark: '',
    contrastText: '',
  },
  secondary: {
    main: '#467EFF',
  },
  common: {
    white: '#fff',
    black: '#000',
  },
  background: {
    default: '#050d20',
  },
  grey: {},
  lightGrey: {
    main: '#AAAAAA',
    dark: '#4D4D4D',
  },
  darkBlue: {
    main: '#171D2C',
    light: '#232D43',
    dark: '#111722',
  },
  lightBlue: {
    main: '#646D85',
    light: '#424857',
    dark: '#4D4D4D',
  },
};
export default palette;
