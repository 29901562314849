import React, { useEffect, useMemo, useState } from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { Button, TextField, Typography } from 'components';
import { useDispatch, useSelector } from 'hooks';
import { useTranslate } from 'react-polyglot';
import { submitApplicationBankingDetails } from 'store/thunks/detailedApplicationSliceThunk';
import { companyDetailsSelector } from 'store/selectors/companyDetailsSelector';
import { BankData } from 'Portal Types';
import { IBANLength } from 'Constants';
import { detailedApplicationDataSelector } from 'store/selectors/detailedApplicationSelector';
import { commonInputFields, initialFormData, rowFields, zaFields } from './BankingDetails.config';

export const BankingDetails = () => {
  const dispatch = useDispatch();
  const t = useTranslate();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const companyDetails = useSelector(companyDetailsSelector);
  const application = useSelector(detailedApplicationDataSelector);
  const [formData, setFormData] = useState(initialFormData);
  const [fieldHelpers, setFieldHelpers] = useState(initialFormData);
  const [isLoading, setIsLoading] = useState(false);

  type InputNames = keyof typeof formData;

  useEffect(() => {
    if (!companyDetails) return;

    Object.keys(companyDetails.bank).forEach((bankDetail) => {
      const bankDetailsValue = companyDetails.bank[bankDetail as keyof BankData];

      if (bankDetailsValue) {
        setFormData((formData) => ({ ...formData, [bankDetail]: bankDetailsValue }));
      }
    });
  }, [companyDetails?.bank]);

  const inputFields = useMemo(() => {
    if (companyDetails?.incorporationCountry === 'ZA') {
      return [...commonInputFields, ...zaFields];
    } else {
      return [...commonInputFields, ...rowFields];
    }
  }, [companyDetails]);

  const isKycLock = Boolean(companyDetails?.kycLock);

  const setFieldHelperContent = (name: InputNames, content: string) => {
    setFieldHelpers((fieldHelpers) => ({ ...fieldHelpers, [name]: content }));
  };

  const handleValidateInput = (name: InputNames, value: string) => () => {
    switch (name) {
      case 'swift':
        if (value.length === 0) {
          setFieldHelperContent(name, 'SWIFT is mandatory!');
        }
        break;
      case 'iban':
        if (value.length === 0) {
          setFieldHelperContent(name, 'IBAN is mandatory!');
        }
        break;
      case 'bankAddress':
        if (value.length === 0) {
          setFieldHelperContent(name, 'The bank address is mandatory!');
        }
        break;
      case 'bankName':
        if (value.length === 0) {
          setFieldHelperContent(name, 'The bank name is mandatory!');
        }
        break;

      default:
        break;
    }
  };

  const handleInputChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const inputName = evt.target.name as InputNames;

    if (fieldHelpers[inputName]) {
      setFieldHelperContent(inputName, '');
    }

    setFormData((formData) => ({ ...formData, [inputName]: evt.target.value }));
  };

  const handleClick = async () => {
    setIsLoading(true);

    await dispatch(submitApplicationBankingDetails(formData));

    setIsLoading(false);
  };

  const isFormValid = () => {
    const {
      bankAddress: inputAddress,
      bankName: inputBankName,
      iban: inputIban,
      swift: inputSwift,
      accountNumber: inputAccountNumber,
      branchCode: inputBranchCode,
    } = formData;

    const hasUpdateLink = Boolean(application?._links?.submitBanking);

    const isIbanValid = inputIban.length > 0 && inputIban.length <= IBANLength;
    const isSwiftValid = inputSwift.length > 0;
    const isBankNameValid = inputBankName.length > 0;
    const isBankAddressValid = inputAddress.length > 0;
    const isAccountNumberChanged = inputAccountNumber.length > 0;
    const isBranchCodeChanged = inputBranchCode.length > 0;

    const areRequiredFieldsValid =
      companyDetails?.incorporationCountry === 'ZA'
        ? isAccountNumberChanged && isBranchCodeChanged && isBankNameValid && isBankAddressValid
        : isIbanValid && isSwiftValid && isBankNameValid && isBankAddressValid;

    return areRequiredFieldsValid && hasUpdateLink;
  };

  const getComputedPlaceholder = (inputName: keyof typeof formData) => {
    switch (inputName) {
      case 'iban':
        return 'EG380019011500000000263180002';
      case 'swift':
        return 'UNBEEGCXCAI';
      default:
        return '';
    }
  };

  return (
    <Box
      maxWidth={matches ? undefined : '30rem'}
      padding={4}
      display='flex'
      flexDirection='column'
      gap={3}
    >
      <Typography variant='h1'>Add banking details</Typography>
      <Typography variant='body1' color={theme.palette.common.white}>
        Please provide your company banking details
      </Typography>
      {inputFields.map((field) => (
        <TextField
          label={field.label}
          key={field.id}
          name={field.id}
          fullWidth
          onChange={handleInputChange}
          placeholder={getComputedPlaceholder(field.id)}
          value={formData[field.id as keyof typeof formData]}
          error={Boolean(fieldHelpers[field.id])}
          helperText={fieldHelpers[field.id]}
          onBlur={handleValidateInput(field.id, formData[field.id])}
          disabled={isKycLock}
        />
      ))}
      <Button
        variant='contained'
        onClick={handleClick}
        disabled={!isFormValid()}
        isLoading={isLoading}
      >
        {t('global.continue')}
      </Button>
    </Box>
  );
};
