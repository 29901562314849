import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { I18n } from 'react-polyglot';
import { Amplify } from 'aws-amplify';
import { ThemeProvider, CssBaseline } from '@mui/material';
import App from './App';
import awsconfig from 'aws-exports';
import { store } from 'store';
import theme from 'theme';
import en from 'utils/localization/en';
import { IntercomProvider } from 'react-use-intercom';
import { intercomAppId, intercomHost, authDomain } from 'portal.config';

if(authDomain) {
  // This is a workaround for the issue with the Cognito domain not being set in the aws-exports.js file
  // The effect of this is that the domain shown in the consent screen is changed to the one set in the .env file
  awsconfig.oauth.domain = authDomain;
}
Amplify.configure(awsconfig);

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <IntercomProvider appId={intercomAppId} apiBase={intercomHost}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Provider store={store}>
        <I18n locale='en' messages={en}>
          <Router>
            <App />
          </Router>
        </I18n>
      </Provider>
    </ThemeProvider>
  </IntercomProvider>,
);
