import { Application } from 'Portal Types';
import { Typography, ApplicationStatusIndicator } from 'components';
import { Box } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useTranslate } from 'react-polyglot';
import { formatNumber } from 'utils/formatNumber';
import { Wrapper } from './ApplicationItem.styles';
import { currencySignOrIsoCode } from 'utils/currencySignOrIsoCode';

type Props = Application & {
  index: number;
  onClick?(id: string): void;
};

const ApplicationItem = ({ product, status, name, index, id, currency, onClick }: Props) => {
  const t = useTranslate();

  const handleClick = () => {
    onClick?.(id);
  };

  return (
    <Wrapper
      onClick={handleClick}
      display='flex'
      flexDirection='column'
      gap={'1rem'}
      padding='2rem'
    >
      <Typography variant='h6'>{name ?? `application ${index}`}</Typography>
      <Box>
        <Typography variant='body2'>You have requested</Typography>
        <Typography variant='h2'>
          {currencySignOrIsoCode(currency)} {formatNumber(product.amount as number)}
        </Typography>
      </Box>
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <ApplicationStatusIndicator status={t(`application.${status}`)} />
        <ChevronRightIcon />
      </Box>
    </Wrapper>
  );
};

export default ApplicationItem;
