import { Typography } from 'components';
import { Box, SxProps } from '@mui/material';
import { CTheme } from 'theme/theme';

type Props = {
  name: string;
  sx?: SxProps<CTheme>;
};

export const DividerWithWord = ({ name, sx }: Props) => {
  return (
    <Box width='100%' display='flex' alignItems='center' gap='1rem' sx={sx}>
      <Box flexGrow={1} border='.5px solid #242936' />
      <Typography>{name}</Typography>
      <Box flexGrow={1} border='.5px solid #242936' />
    </Box>
  );
};
