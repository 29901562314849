import { HeaderKeys } from 'Constants';
import { RootState } from 'store';
import { appUserAccessTokenSelector } from 'store/selectors/appUserSelector';

export const getRequestHeaders = (store: RootState) => {
  const accessToken = appUserAccessTokenSelector(store);

  return {
    [HeaderKeys.Authorization]: `Bearer ${accessToken}`,
    [HeaderKeys.Accept]: 'application/json',
  };
};
