import React, { useState, useMemo } from 'react';
import { UserAttributes } from 'Portal Types';
import { TextField, Button } from 'components';
import { useAuth, useSelector } from 'hooks';
import { fieldDifference } from 'utils/formValidation';
import { appUserAttributesSelector } from 'store/selectors/appUserSelector';
import { companyDetailsSelector } from 'store/selectors/companyDetailsSelector';

const inputFields = [
  { id: 'given_name', name: 'First Name' },
  { id: 'family_name', name: 'Last Name' },
  { id: 'custom:middle_name', name: 'Middle Name (Optional)' },
] as const;

const PersonalInfoForm = () => {
  const { updateUserAttributes } = useAuth();
  const userAttributes = useSelector(appUserAttributesSelector);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    ['given_name']: userAttributes?.given_name,
    ['family_name']: userAttributes?.family_name,
    ['custom:middle_name']: userAttributes?.['custom:middle_name'],
  });
  const [fieldHelpers, setFieldHelpers] = useState({
    ['given_name']: '',
    ['family_name']: '',
    ['custom:middle_name']: '',
  });

  const companyDetails = useSelector(companyDetailsSelector);

  const formReadOnly = useMemo(() => {
    return Boolean(companyDetails?.kycLock);
  }, [companyDetails?.kycLock]);

  const handleInputChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = evt.target;
    if (fieldHelpers[name as keyof typeof fieldHelpers]) {
      setFieldHelperContent(name as keyof typeof fieldHelpers, '');
    }
    setFormData((formData) => ({ ...formData, [name]: value }));
  };

  const isFormValid = () => {
    const isFirstNameValid = fieldDifference(
      formData['given_name'] ?? '',
      userAttributes?.given_name ?? '',
    );
    const isLastNameValid = fieldDifference(
      formData['family_name'] ?? '',
      userAttributes?.family_name ?? '',
    );
    const isMiddleNameValid = fieldDifference(
      formData['custom:middle_name'] ?? '',
      userAttributes?.['custom:middle_name'] ?? '',
    );

    return isFirstNameValid || isLastNameValid || isMiddleNameValid;
  };

  const handleSubmit = async (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    setIsLoading(true);
    const attributes: Partial<UserAttributes> = {};

    const isFirstNameChanged = fieldDifference(
      formData['given_name'] ?? '',
      userAttributes?.given_name ?? '',
    );
    const isLastNameChanged = fieldDifference(
      formData['family_name'] ?? '',
      userAttributes?.family_name ?? '',
    );
    const isMiddleNameChanged = fieldDifference(
      formData['custom:middle_name'] ?? '',
      userAttributes?.['custom:middle_name'] ?? '',
    );

    if (isFirstNameChanged) {
      attributes['given_name'] = formData['given_name'];
    }
    if (isLastNameChanged) {
      attributes['family_name'] = formData['family_name'];
    }

    if (isMiddleNameChanged) {
      attributes['custom:middle_name'] = formData['custom:middle_name'];
    }

    await updateUserAttributes(attributes);

    setIsLoading(false);
  };

  const setFieldHelperContent = (name: keyof typeof fieldHelpers, content: string) => {
    setFieldHelpers((fieldHelpers) => ({ ...fieldHelpers, [name]: content }));
  };

  const handleValidateInput = (name: keyof typeof fieldHelpers, value: string) => () => {
    switch (name) {
      case 'given_name':
        if (value.length === 0) {
          setFieldHelperContent(name, 'First name is mandatory!');
        }
        break;
      case 'family_name':
        if (value.length === 0) {
          setFieldHelperContent(name, 'Last name is mandatory!');
        }
        break;

      default:
        break;
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {inputFields.map((input) => (
        <TextField
          key={input.id}
          value={formData[input.id] ?? ''}
          name={input.id}
          label={input.name}
          size='small'
          onChange={handleInputChange}
          fullWidth
          disabled={formReadOnly}
          error={Boolean(fieldHelpers[input.id])}
          helperText={fieldHelpers[input.id]}
          onBlur={handleValidateInput(input.id, formData[input.id] ?? '')}
        />
      ))}
      {formReadOnly ? null : (
        <Button variant='contained' type='submit' disabled={!isFormValid()} isLoading={isLoading}>
          Save
        </Button>
      )}
    </form>
  );
};

export default PersonalInfoForm;
