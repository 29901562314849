import { Sandbox } from 'assets';

import {
  ClearBooks,
  Dynamics365BusinessCentral,
  Exact,
  FreeAgent,
  FreshBooks,
  KashFlow,
  MYOBBusiness,
  OracleNetSuite,
  Pandle,
  QuickBooks,
  Sage,
  Wave,
  Xero,
  ZohoBooks,
  Stripe,
  PayFast,
  PeachPayments,
  Yoco,
  Paypal,
} from 'assets/images';

export const mappedIcons = {
  Stripe: Stripe,
  Sandbox: Sandbox,
  'Clear Books': ClearBooks,
  KashFlow: KashFlow,
  'Oracle NetSuite': OracleNetSuite,
  'QuickBooks Desktop': QuickBooks,
  'Sage 50 (UK)': Sage,
  'Dynamics 365 Business Central': Dynamics365BusinessCentral,
  'Exact (Netherlands)': Exact,
  'Exact (UK)': Exact,
  FreeAgent: FreeAgent,
  FreshBooks: FreshBooks,
  'MYOB Business': MYOBBusiness,
  Pandle: Pandle,
  'QuickBooks Online': QuickBooks,
  'QuickBooks Online Sandbox': QuickBooks,
  'Sage Business Cloud Accounting': Sage,
  'Sage Intacct': Sage,
  'Sage 200cloud': Sage,
  Wave: Wave,
  Xero: Xero,
  'Zoho Books': ZohoBooks,
  Yoco: Yoco,
  'Peach Payments': PeachPayments,
  Payfast: PayFast,
  Paypal: Paypal,
};

export const largeImages = ['Peach Payments', 'Payfast', 'Yoco'];
