import React from 'react';
import { Typography, Button } from 'components';
import { WaitingScreen } from '../WaitingScreen';
import { CanceledIcon } from 'assets';
import { useNavigate } from 'react-router';
import { useDispatch } from 'hooks';
import {
  resetDetailedApplicationState,
  setIsDetailedApplicationLoading,
} from 'store/slices/detailedApplicationSlice';

export const RejectedView = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(resetDetailedApplicationState());
    navigate('/application');
    dispatch(setIsDetailedApplicationLoading(false));
  };

  return (
    <WaitingScreen title='Application was declined'>
      <CanceledIcon />
      <Typography variant='h2'>Unfortonately, your application has been declined!</Typography>
      <Button variant='outlined' onClick={handleClick}>
        Submit another application
      </Button>
    </WaitingScreen>
  );
};
