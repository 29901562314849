import { Box, styled } from '@mui/material';

export const Wrapper = styled(Box)(({ theme }) => ({
  transition: 'all 0.2s',
  cursor: 'pointer',
  border: `1px solid ${theme.palette.grey[600]}`,
  borderRadius: '0.5rem',
  maxWidth: '19rem',

  '& svg': {
    fill: theme.palette.common.white,
  },

  '&:hover': {
    borderColor: '#bebebe',
  },
}));
